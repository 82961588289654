<template>
  <span :name='name' class="cm-switch-button">
    <div @click="onClick" class="switch-button-control">
      <div :class="enabled" class="switch-button">
        <div class="button"></div>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      required: false,
      default: () => false
    }
  },

  destroyed() {
    this.$emit('destroyed', { name: this.name })
  },

  computed: {
    enabled() {
      return {
        enabled: this.value
      }
    }
  },

  methods: {
    onClick() {
      const value = !this.value
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
.cm-switch-button {
  .switch-button-control {
    display: flex;
    flex-direction: row;
    align-items: center;

    .switch-button {
      $switch-button-height: 1.6em !default;
      $switch-button-color-enabled: #4a61e3 !default;
      $switch-button-color: #eaebef !default;

      $switch-button-border-thickness: 2px !default;
      $switch-transition: all 0.3s ease-in-out !default;

      height: $switch-button-height;
      width: calc(#{$switch-button-height} * 2.15);
      // border: $switch-button-border-thickness solid $switch-button-color;
      box-shadow: inset 0px 0px $switch-button-border-thickness 0px
        rgba(0, 0, 0, 0.33);
      border-radius: $switch-button-height;
      background-color: $switch-button-color;

      transition: $switch-transition;

      $switch-button-toggle-size: calc(
        #{$switch-button-height} - (0.5 * #{$switch-button-border-thickness})
      );

      cursor: pointer;

      .button {
        height: $switch-button-toggle-size;
        width: $switch-button-toggle-size;
        border: $switch-button-border-thickness solid $switch-button-color;
        border-radius: 100%;
        background: white;

        transition: $switch-transition;
      }

      &.enabled {
        background-color: $switch-button-color-enabled;

        box-shadow: none;

        .button {
          background: white;
          transform: translateX(
            calc(#{$switch-button-toggle-size} + (2 *#{$switch-button-border-thickness}))
          );
        }
      }
    }

    .switch-button-label {
      margin-left: 10px;
    }
  }
}
</style>
