<template>
  <div v-if="show" class="confirm-modal main d-flex container-fluid">
    <section class="col-sm-8 container">
      <slot>
        <div class="bloc row col-sm-10 center danger-header">
          <div class="text-center">
            <p class="danger-title">{{ title }}</p>
            <p class="offset-sm-1 col-sm-10 danger-subtitle">{{ subtitle }}</p>
          </div>
          <div class="col-sm-12 d-flex justify-content-center">
            <div class="buttons">
              <b-button class="danger-button" @click="closeAndEmit('confirm-ok')">
                {{ okButtonLabel }}
              </b-button>
            </div>
            <div class="buttons">
              <b-button
                class="transparent-button"
                @click="closeAndEmit('confirm-cancel')"
              >
                {{ cancelButtonLabel }}
              </b-button>
            </div>
          </div>
        </div>
      </slot>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    data: {
      required: false,
      default: () => {}
    },
    title: {
      type: String,
      required: false,
      default: () => 'Confirmez-vous ?'
    },
    subtitle: {
      type: String,
      required: false,
      default: () => ''
    },
    okButtonLabel: {
      type: String,
      required: false,
      default: () => 'Oui'
    },

    cancelButtonLabel: {
      type: String,
      required: false,
      default: () => 'Non'
    }
  },

  methods: {
    closeAndEmit(eventType) {
      this.$emit('update:show', false)
      this.$emit(eventType, this.data)
    }

  }
};
</script>

<style lang="scss">
.confirm-modal {
  &.main {
    background: rgba(33, 33, 33, 0.85);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1031; // The navbar is 1030

    & > section {
      margin: auto;
    }
  }
  .center {
    height: 15%;
    margin: auto;
  }

  .success-nav {
    color: white;
  }
  .buttons {
    padding: 10px;
  }

  .danger-subtitle {
    font-weight: 500;
    font-size: 1.1rem;
  }
}
</style>
