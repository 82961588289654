import i18n from '@/plugins/i18n.js'

export default {
  state() {
    return {
      modalOpen: false,
      title: '',
      reset_ppc: false,
      reset_o2: false,
      reset_vni: false
    }
  },
  mutations: {
    update_modal_open(state, value) {
      state.modalOpen = value
    },
    update_title(state, value) {
      state.title = value
    },
    update_reset_ppc(state, value) {
      state.reset_ppc = value
    },
    update_reset_o2(state, value) {
      state.reset_o2 = value
    },
    update_reset_vni(state, value) {
      state.reset_vni = value
    }
  },
}
