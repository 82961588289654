<template>
  <div class="vue-ask-for-care-patho" >
    <div class="divider col-sm-12">
      <div class="row col-sm-12 d-flex">
        <div class="col-sm-4">
          <b-button class="pathology-badge" v-b-toggle.vni-content @click="toggleHead">
            <div class="d-flex">
              <i :class="['fas', '2x', collapseIcon]" id="toggle-vni-icon"></i>
              <span class="text-is-semibold">{{ $t('askForCare.vni.vni') }}</span>
            </div>
          </b-button>
        </div>
        <div v-if="isDeleted" class="col-sm-8">
          <span class="text-is-semibold deleted-date" v-if="isDeleted">Demande d'arrêt du traitement le {{ $store.state.vniRenewal.deletedAt }}</span>
        </div>
        <template v-if="(!expanded || !isEditable) && !isDeleted">
          <div class="col-sm-8 d-flex justify-content-between" >
            <section>
              <b-badge class="tag" v-for="item in dataBadges" :key="item">
                <span class="d-flex">
                  {{item}}
                </span>
              </b-badge>
            </section>
          </div>
        </template>
      </div>
    </div>

    <b-collapse id="vni-content" :visible="isEditable" class="container">
      <template v-if="isEditable" v-for="group of fields">
        <cm-conditional-form-group
          :root="group.root"
          :conditions="group.group"
          :meta="group.meta"
          store-namespace="vniRenewal"
        >
          <div class="col-sm-12">
            <div class="col-sm-12">
              <template v-for="rows of group.fields">
                <div class="row">
                  <template v-for="field of rows">
                    <article
                      :class="rowClasses(field)"
                    >
                      <component
                        :is="field.type"
                        :values="field.values || []"
                        :value="data[field.name]"
                        :valid="validations[field.name] && validations[field.name].isValid"
                        :feedback="validations[field.name] && validations[field.name].feedback"
                        :feedback-data="data"
                        :name="field.name"
                        :label="field.label"
                        :options="field.options"
                        @input="onInput"
                      >
                      </component>
                    </article>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </cm-conditional-form-group>
      </template>
    </b-collapse>
  </div>
</template>
<script>

import CmApiSelect from '@/components/UI/CmApiSelect'
import CmConditionalFormGroup from '@/components/UI/CmConditionalFormGroup'
import CmCheckbox from '@/components/UI/CmCheckbox'
import CmDateInput from '@/components/UI/CmDateInput'
import CmNumberInput from '@/components/UI/CmNumberInput'
import CmRadioButton from '@/components/UI/CmRadioButton'
import CmSelect from '@/components/UI/CmSelect'
import CmTextarea from '@/components/UI/CmTextarea'
import CmTextInput from '@/components/UI/CmTextInput'
import CmUpload from '@/components/UI/CmUpload'

export default {
  components: {
    CmApiSelect,
    CmConditionalFormGroup,
    CmCheckbox,
    CmDateInput,
    CmNumberInput,
    CmRadioButton,
    CmSelect,
    CmTextarea,
    CmTextInput,
    CmUpload,
  },

  props: {
    prescription: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    patientId: {
      type: Number,
      required: true
    },
    validated: {
      type: Boolean,
      default: false,
      required: false
    },
    renewing: {
      type: Boolean,
      required: false,
      default: false
    },
    editable: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      expanded: this.editable,
      hasPrescription: this.prescription.id !== undefined,
      fields: [],
      treatment: {}
    }
  },

  computed: {
    data() {
      return this.$store.state.vniRenewal.data
    },
    validations() {
      return this.$store.state.vniRenewal.validations
    },
    collapseIcon() {
      return this.expanded ? 'fa-angle-double-up' : 'fa-angle-double-down'
    },
    isEditable() {
      return this.editable && !this.isDeleted
    },
    isDeleted() {
      return this.$store.state.vniRenewal.deleted
    },
    dataBadges() {
      let filterObject = {}
      const state = this.$store.state.vniRenewal.data
      const validations = this.$store.state.vniRenewal.validations

			if(validations.with_ald && validations.with_ald.isValid) filterObject['with_ald'] = 'ALD : ' + this.$t(`common.${state.with_ald}`)
      if(validations.ald_on_list && validations.ald_on_list.isValid && state.with_ald === true) filterObject['ald_on_list'] = 'Sur liste : ' + this.$t(`common.${state.ald_on_list}`)
      if(state.interface !== null && validations.interface && validations.interface.isValid) filterObject['interface'] = 'Interface : ' + this.$t(`askForCare.vni.${state.interface}`)
      if(validations.mask_type && validations.mask_type.isValid && state.interface === 'mask') filterObject['mask_type'] = 'Type de masque : ' + this.$t(`askForCare.vni.${state.mask_type}`)
      if(validations.mode_vni && validations.mode_vni.isValid) filterObject['mode_vni'] = 'Mode : ' + this.$t(`askForCare.vni.${state.mode_vni || 'not_set'}`)
      if(state.volume !== '' && validations.volume && validations.volume.isValid) filterObject['volume'] = `Volume : ${state.volume} ml`
      if(state.pep !== '' && validations.pep && validations.pep.isValid) filterObject['pep'] = `PEP : ${state.pep}`
      if(state.pep_min !== '' && validations.pep_min && validations.pep_min.isValid) filterObject['pep_min'] = `PEP min : ${state.pep_min}`
      if(state.pep_max !== '' && validations.pep_max && validations.pep_max.isValid) filterObject['pep_max'] = `PEP max : ${state.pep_max}`
      if(state.pep_auto_min !== '' && validations.pep_auto_min && validations.pep_auto_min.isValid) filterObject['pep_auto_min'] = `PEP Auto min : ${state.pep_auto_min}`
			if(state.pep_auto_max !== '' && validations.pep_auto_max && validations.pep_auto_max.isValid) filterObject['pep_auto_max'] = `PEP Auto max : ${state.pep_auto_max}`
      if(state.pip !== '' && validations.pip && validations.pip.isValid) filterObject['pip'] = `PIP : ${state.pip}`
      if(state.pip_min !== '' && validations.pip_min && validations.pip_min.isValid) filterObject['pip_min'] = `PIP min : ${state.pip_min}`
      if(state.pip_max !== '' && validations.pip_max && validations.pip_max.isValid) filterObject['pip_max'] = `PIP max : ${state.pip_max}`
      if(state.ai_min !== '' && validations.ai_min && validations.ai_min.isValid) filterObject['ai_min'] = `AI min : ${state.ai_min}`
      if(state.ai_max !== '' && validations.ai_max && validations.ai_max.isValid) filterObject['ai_max'] = `AI max : ${state.ai_max}`
      if(state.frequency !== '' && validations.frequency && validations.frequency.isValid) filterObject['frequency'] = `Fréquence : ${state.frequency}`
			if(state.ti_fixed !== '' && validations.ti_fixed && validations.ti_fixed.isValid) filterObject['ti'] = `TI : ${state.ti_fixed}`
      if(state.ti_min !== '' && validations.ti_min && validations.ti_min.isValid) filterObject['ti_min'] = `TI min : ${state.ti_min}`
      if(state.ti_max !== '' && validations.ti_max && validations.ti_max.isValid) filterObject['ti_max'] = `TI max : ${state.ti_max}`
      if(state.trigger_insp !== '' && validations.trigger_insp && validations.trigger_insp.isValid) filterObject['trigger_insp'] = `Trigger insp : ${state.trigger_insp}`
      if(state.trigger_exp !== '' && validations.trigger_exp && validations.trigger_exp.isValid) filterObject['trigger_exp'] = `Trigger exp : ${state.trigger_exp}`
      if(state.slope !== '' && validations.slope && validations.slope.isValid) filterObject['slope'] = `Pente : ${state.slope}`
      if(state.vni_time_of_use !== '') filterObject['vni_time_of_use'] = `Temps d'utilisation : ${state.vni_time_of_use}`
      if(validations.humidifier && validations.humidifier.isValid) filterObject['humidifier'] = 'Humidificateur : '+ this.$t(`common.${state.humidifier}`)

      return filterObject
    }
  },

  async mounted() {
    const url = this.hasPrescription ? `/api/v1/prescriptions/${this.prescription.id}/fields` : '/api/v1/prescriptions/fields'

    const response = await this.$axios.get(url, {
      params : {
        patient_id: this.patientId,
        renewing: this.renewing,
        only: ['vni']
      }
    })

    const data = response.data

    this.fields = data.vni.fields
    this.treatment = data.vni.treatment_data

    if (this.renewing && !this.$store.state.vniRenewal.deleted) {
      this.$emit('dirty')
    }

    this.$store.dispatch('vniRenewal/loadFromTreatment', this.treatment)
  },

  watch: {
    editable() {
      return this.expanded = this.editable
    },
    validated() {
      if(this.validated) {
        this.$store.commit('vniRenewal/setDataBadges', this.dataBadges)
      }
    },
  },

  methods: {
    toggleHead() {
      this.expanded = !this.expanded
    },

    onInput(data) {
      this.$store.commit('vniRenewal/update', { key: data.name, value: data.value })
      this.$emit('dirty')
      this.$emit('input', { form: 'vni', ...data })
    },

    rowClasses(field) {
      return [
        { [`offset-sm-${field.options.offset}`]: field.options.offset !== undefined }, `col-sm-${field.col}`
      ]
    }
  }
}
</script>
