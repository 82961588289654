import i18n from '@/plugins/i18n.js'

export default {
  state() {
    return {
       // o2
      additional_info_o2           : '',
      ald_on_list_o2               : null,
      arterial_blood_gas_date_test : '',
      arterial_blood_gas_pa_co2    : '',
      arterial_blood_gas_pa_o2     : '',
      arterial_blood_gas_ph        : '',
      arterial_blood_gas_sa_o2     : '',
      arterial_blood_gas_test      : '',
      care_type                    : 'transient_respiratory_failure',
      consumables                  : ['cannula'],
      devices_packages             : '',
      equipments                   : ['concentrator'],
      flow_day                     : '',
      flow_discontinuous           : '',
      flow_effort                  : '',
      flow_night                   : '',
      humidifier_o2                : false,
      o2_type                      : 'short_term',
      once_renewal                 : '',
      preferred_device             : '',
      prescription_duration_o2     : 3,
      prescription_start_date_o2   : new Date().toISOString().substr(0, 10),
      time_of_use_o2               : '',
      walker                       : '',
      with_ald_o2                  : false,
      duration_unit                : 'months',

      // tools key
      dirty         : false
    }
  },
  getters:{
    validO2Data(state) {
      let filterObject = {}
      const translateArray = (arr) => arr.map( item => i18n.t(`askForCare.o2.${item}`))

      if(state.o2_type !== '')                                                                                                            filterObject['o2_type']                      = 'Type O2 : '  + i18n.t(`askForCare.o2.${state.o2_type}`)
      if(state.with_ald_o2 !== '')                                                                                                        filterObject['with_ald_o2']                  = 'ALD : '+ i18n.t(`common.${state.with_ald_o2}`)
      if(state.with_ald_o2 === true && state.o2_type === 'long_term')                                                                     filterObject['ald_on_list_o2']               = 'Sur liste : '+ i18n.t(`common.${state.ald_on_list_o2}`)
      if(state.prescription_start_date_o2 !== '' && state.o2_type !== 'long_term')                                                        filterObject['prescription_start_date_o2']   = `Date de début du traitement : ${new Date(state.prescription_start_date_o2).toLocaleDateString('fr')}`
      if(state.care_type !== '' && state.o2_type === 'short_term')                                                                        filterObject['care_type']                    = 'Type de prise en charge : '+  i18n.t(`askForCare.o2.${state.care_type}`)
      if(state.walker !== '' && state.o2_type === 'long_term')                                                                            filterObject['walker']                       = 'Déambulation : ' + i18n.t(`askForCare.o2.walker.${state.walker}`)
      if(state.equipments.length > 0 && state.o2_type !== 'avf')                                                                          filterObject['equipments']                    = `Oxygénothérapie par : ${translateArray(state.equipments).join(', ')}`
      if(state.consumables.length > 0 && state.o2_type === 'short_term')                                                                  filterObject['consumables']                   = `Consommable(s) : ${translateArray(state.consumables).join(', ')}`
      if(state.time_of_use_o2 !== '')                                                                                                     filterObject['time_of_use_o2']               = `Durée d'utilisation : ${state.time_of_use_o2} ${state.o2_type === 'avf' ? 'min' : 'h/jour'}`
      if(state.flow_day !== '' && state.o2_type !== 'avf')                                                                                filterObject['flow_day']                     = `Débit jour :  ${state.flow_day} l/min`
      if(state.flow_day !== '' && state.o2_type === 'avf')                                                                                filterObject['flow_day']                     = `Débit :  ${state.flow_day} l/min`
      if(state.flow_night !== '' && state.o2_type === 'long_term')                                                                        filterObject['flow_night']                   = `Débit nuit : ${state.flow_night} l/min`
      if(state.flow_discontinuous !== '' && state.walker === true)                                                                        filterObject['flow_discontinuous']           = `Débit d'effort : ` + i18n.t(`askForCare.o2.flow_discontinuous.${state.flow_discontinuous}`)
      if(state.flow_effort !== '' && state.o2_type === 'long_term')                                                                       filterObject['flow_effort']                  = `Valeur du débit : ${state.flow_effort} l/min`
      if(state.preferred_device !== '' && state.walker === true && state.o2_type === 'long_term')                                         filterObject['preferred_device']             = `Préférence matériel : ${state.preferred_device}`
      if(state.arterial_blood_gas_date_test !== '' && state.o2_type === 'long_term' && state.arterial_blood_gas_test !== '')              filterObject['arterial_blood_gas_date_test'] = `Date du test GSA : ${new Date(state.arterial_blood_gas_date_test).toLocaleDateString('fr')}`
      if(state.arterial_blood_gas_test !== '' && state.o2_type === 'long_term' && (state.arterial_blood_gas_date_test !== '' || state.arterial_blood_gas_ph !== '' || state.arterial_blood_gas_pa_co2 !== '' || state.arterial_blood_gas_pa_o2 !== '' || state.arterial_blood_gas_sa_o2 !== ''))  filterObject['arterial_blood_gas_test'] = 'Nature du test GSA : ' + i18n.t(`askForCare.o2.${state.arterial_blood_gas_test || 'not_set'}`)
      if(state.arterial_blood_gas_ph !== '' && state.o2_type === 'long_term' && state.arterial_blood_gas_test !== '')                     filterObject['arterial_blood_gas_ph']        = `GSA pH : ${state.arterial_blood_gas_ph}`
      if(state.arterial_blood_gas_pa_co2 !== '' && state.o2_type === 'long_term' && state.arterial_blood_gas_test !== '')                 filterObject['arterial_blood_gas_pa_co2']    = `GSA PaCO2 : ${state.arterial_blood_gas_pa_co2}`
      if(state.arterial_blood_gas_pa_o2 !== '' && state.o2_type === 'long_term' && state.arterial_blood_gas_test !== '')                  filterObject['arterial_blood_gas_pa_o2']     = `GSA PaO2 : ${state.arterial_blood_gas_pa_o2}`
      if(state.arterial_blood_gas_sa_o2 !== '' && state.o2_type === 'long_term' && state.arterial_blood_gas_test !== '')                  filterObject['arterial_blood_gas_sa_o2']     = `GSA SaO2 : ${state.arterial_blood_gas_sa_o2}`
      if(state.prescription_duration_o2 !== '' && state.o2_type !== 'long_term')                filterObject['prescription_duration_o2']     = `Durée de l'ordonnance : ${state.prescription_duration_o2} ${i18n.t('common.' + state.duration_unit)}`
      if(state.once_renewal !== '' && ((state.o2_type === 'short_term' && state.care_type == 'dyspnea_palliative_care') || (state.o2_type === 'avf')))  filterObject['once_renewal']                 = 'Renouvelable 1 fois :  ' + i18n.t(`common.${state.once_renewal}`)

      return filterObject
    },
    validO2Form(state) {
      let validness = false
      const naturalNumber = (value) => typeof value === 'number' && value > 0
      const yesterday = new Date(new Date - 84700000)
      yesterday.setHours(0)
      yesterday.setMinutes(0)
      yesterday.setSeconds(0)
      yesterday.setMilliseconds(0)

      if (state.o2_type === 'short_term') {
          validness = state.prescription_start_date_o2 &&
          new Date(state.prescription_start_date_o2) >= yesterday &&
          state.consumables.length > 0 &&
          state.equipments.length > 0 && [
              state.time_of_use_o2,
              state.flow_day
          ].every(naturalNumber)
      } else if (state.o2_type === 'long_term') {
          validness = state.walker !== '' &&
          state.equipments.length > 0 && [
              state.time_of_use_o2,
              state.flow_effort
          ].every(naturalNumber)
      } else if (state.o2_type === 'avf') {
          validness = state.prescription_start_date_o2 &&
          new Date(state.prescription_start_date_o2) >= yesterday && [
              state.time_of_use_o2,
              state.flow_day
          ].every(naturalNumber)
      }

      return validness
    }
  },
  mutations: {
    update_o2_additional_info_o2(state, value)           {state.additional_info_o2           = value},
    update_o2_ald_on_list_o2(state, value)               {state.ald_on_list_o2               = value},
    update_o2_arterial_blood_gas_date_test(state, value) {state.arterial_blood_gas_date_test = value},
    update_o2_arterial_blood_gas_pa_co2(state, value)    {state.arterial_blood_gas_pa_co2    = value},
    update_o2_arterial_blood_gas_pa_o2(state, value)     {state.arterial_blood_gas_pa_o2     = value},
    update_o2_arterial_blood_gas_ph(state, value)        {state.arterial_blood_gas_ph        = value},
    update_o2_arterial_blood_gas_sa_o2(state, value)     {state.arterial_blood_gas_sa_o2     = value},
    update_o2_arterial_blood_gas_test(state, value)      {state.arterial_blood_gas_test      = value},
    update_o2_care_type(state, value)                    {state.care_type                    = value},
    update_o2_consumables(state, value)                  {state.consumables                  = value},
    update_o2_devices_packages(state, value)             {state.devices_packages             = value},
    update_o2_equipments(state, value)                   {state.equipments                   = value},
    update_o2_flow_day(state, value)                     {state.flow_day                     = parseFloat(value) || ''},
    update_o2_flow_discontinuous(state, value)           {state.flow_discontinuous           = value},
    update_o2_flow_effort(state, value)                  {state.flow_effort                  = parseFloat(value) || ''},
    update_o2_flow_night(state, value)                   {state.flow_night                   = parseFloat(value) || ''},
    update_o2_humidifier_o2(state, value)                {state.humidifier_o2                = value},
    update_o2_o2_type(state, value)                      {state.o2_type                      = value},
    update_o2_once_renewal(state, value)                 {state.once_renewal                 = value},
    update_o2_preferred_device(state, value)             {state.preferred_device             = value},
    update_o2_prescription_duration_o2(state, value)     {state.prescription_duration_o2     = parseInt(value) || ''},
    update_o2_prescription_start_date_o2(state, value)   {state.prescription_start_date_o2   = value},
    update_o2_time_of_use_o2(state, value)               {state.time_of_use_o2               = value},
    update_o2_walker(state, value)                       {state.walker                       = value},
    update_o2_with_ald_o2(state, value)                  {state.with_ald_o2                  = value},
    update_o2_duration_unit(state, value)                {state.duration_unit                = value},

    update_o2_dirty(state, value) {state.dirty = value},
  },
  actions: {
    resetAllO2({commit}){
      commit('update_o2_additional_info_o2'          , '' ),
      commit('update_o2_ald_on_list_o2'              , false ),
      commit('update_o2_arterial_blood_gas_date_test', '' ),
      commit('update_o2_arterial_blood_gas_pa_co2'   , '' ),
      commit('update_o2_arterial_blood_gas_pa_o2'    , '' ),
      commit('update_o2_arterial_blood_gas_ph'       , '' ),
      commit('update_o2_arterial_blood_gas_sa_o2'    , '' ),
      commit('update_o2_arterial_blood_gas_test'     , '' ),
      commit('update_o2_care_type'                   , 'transient_respiratory_failure' )      ,
      commit('update_o2_consumables'                 , ['cannula'] ),
      commit('update_o2_devices_packages'            , '' ),
      commit('update_o2_equipments'                  , ['concentrator'] ),
      commit('update_o2_flow_day'                    , '' ),
      commit('update_o2_flow_discontinuous'          , '' ),
      commit('update_o2_flow_effort'                 , '' ),
      commit('update_o2_flow_night'                  , '' ),
      commit('update_o2_humidifier_o2'               , false ),
      commit('update_o2_o2_type'                     , 'short_term' ),
      commit('update_o2_once_renewal'                , '' ),
      commit('update_o2_preferred_device'            , '' ),
      commit('update_o2_prescription_duration_o2'    , 3 ),
      commit('update_o2_prescription_start_date_o2'  , new Date().toISOString().substr(0, 10) ),
      commit('update_o2_time_of_use_o2'              , '' ),
      commit('update_o2_walker'                      , '' ),
      commit('update_o2_with_ald_o2'                 , false ),
      commit('update_o2_dirty'                       , false )
    }
  }

}
