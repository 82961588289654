<template>
  <div id="iah-input">
    <cm-number-input
      :values="values"
      :value="value"
      :valid="valid"
      :feedback="feedback"
      :feedback-data="feedbackData"
      :name="name"
      :label="label"
      :options="options"
      @input="onInput"
    ></cm-number-input>

    <b-tooltip
      id="iah-tooltip"
      :show="true"
      target="iah-input"
      container="iah-input"
      placement="below"
      custom-class="text-is-medium is-tooltip no-arrow"
      triggers="manual"
      v-if="showTooltip"
    >
      Êtes-vous sûr de vouloir prescrire pour une valeur inférieure à 15 ?
    </b-tooltip>
  </div>
</template>

<script>
import ValidationsMixin from '@/mixins/ValidationsMixin'
import CmNumberInput from '@/components/UI/CmNumberInput'

export default {
  mixins: [ValidationsMixin],
  components: {
    CmNumberInput,
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: function () {
        return {};
      }
    },
    values: {
      type: Array,
      default: () => []
    },
    value: {
      default: () => null
    }
  },

  computed: {
    showTooltip() {
      return (
        this.$store.state.ppcRenewal.data.age >= 16 &&
        !!this.$store.state.ppcRenewal.data.iah &&
        this.$store.state.ppcRenewal.data.iah < 15
      )
    }
  },

  methods: {
    onInput(v) {
      this.$emit('input', v)
    }
  }
}
</script>

<style lang="scss">
#iah-input {
  position: relative;
  margin-bottom: 3rem;

  #iah-tooltip {
    /* Because style is on element ... */
    transform: translate3d(-50px, 110px, 0px) !important;

    .tooltip-inner {
      max-width: 250px
    }

    &.no-arrow {
      .arrow, .arrow::after,
      .arrow::before {
        content: none
      }
    }
  }
}
</style>
