<template>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          {{ $t("philipsPdf.generate") }}
        </h3>
      </div>

      <form @submit.prevent="submit">
        <div class="modal-body form-group">
          <div>
            <b-form-group class="col-sm-12">
              <label class="row">{{ $t("philipsPdf.selectPeriod") }}</label>
              <cm-radio-button
                name="period-selector"
                :values="periodsForSelect"
                v-model="selectedPeriod"
                @input="selectPeriod"
              ></cm-radio-button>
            </b-form-group>
          </div>

          <div>
            <b-form-group>
              <label>{{ $t("philipsPdf.startDate") }}</label>
              <b-form-input
                type="date"
                name="psDateDebut"
                id="ps-start-date"
                @input="deselectPeriod"
                v-model="formData.psDateDebut"
              ></b-form-input>
            </b-form-group>
          </div>
          <div>
            <b-form-group>
              <label for="ps-end-date">{{ $t("philipsPdf.endDate") }}</label>
              <b-form-input
                type="date"
                name="psDateFin"
                id="ps-end-date"
                @input="deselectPeriod"
                v-model="formData.psDateFin"
              ></b-form-input>
            </b-form-group>
          </div>
          <div>
            <b-form-group>
              <label for="ps-report-type">{{
                $t("philipsPdf.reportType")
              }}</label>
              <b-form-select
                name="psTypeTraitement"
                id="ps-report-type"
                :options="reportTypeOptions"
                v-model="formData.psTypeTraitement"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-6 col-sm-offset-2">
            <b-button type="submit" class="btn btn-primary">
              {{ $t("philipsPdf.generate") }}
            </b-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CmRadioButton from "@/components/UI/Inputs/CmRadioButton";

export default {
  components: {
    CmRadioButton,
  },

  props: {
    patientCode: {
      type: String,
      required: true,
    },
  },

  data() {
    // Today -1 day
    const defaultPeriod = 3;
    const oneDay = 24 * 60 * 60 * 1000;
    const endDate = new Date(Date.now() - oneDay);
    const startDate = this.calculateStartDate(endDate, defaultPeriod);

    return {
      baseDate: endDate,
      selectedPeriod: { value: defaultPeriod },
      periodsForSelect: [
        {
          value: 1,
          label: `1 ${this.$t("common.months")}`,
        },
        {
          value: 3,
          label: `3 ${this.$t("common.months")}`,
        },
        {
          value: 6,
          label: `6 ${this.$t("common.months")}`,
        },
      ],
      reportTypeOptions: [
        {
          text: this.$t("philipsPdf.summary"),
          value: "Summary",
        },
        {
          text: this.$t("philipsPdf.detail"),
          value: "Detail",
        },
        {
          text: this.$t("philipsPdf.trend"),
          value: "Trend",
        },
      ],

      formData: {
        psUUIDPatient: this.patientCode,
        psDateDebut: this.toHTMLDate(startDate),
        psDateFin: this.toHTMLDate(endDate),
        psTypeTraitement: "Trend",
      },
    };
  },

  methods: {
    selectPeriod(data) {
      const newStartDate = this.calculateStartDate(this.baseDate, data.value);
      this.formData.psDateDebut = this.toHTMLDate(newStartDate);
      this.formData.psDateFin = this.toHTMLDate(this.baseDate);
    },

    toHTMLDate(date) {
      const day = date.getDate().toString().padStart(2, "0");
      // months are 0-indexed
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    },

    calculateStartDate(endDate, monthOffset) {
      return new Date(
        endDate.getFullYear(),
        // 3 months ago by default
        endDate.getMonth() - monthOffset,
        endDate.getDate()
      );
    },

    deselectPeriod() {
      this.selectedPeriod = { value: null };
    },

    async submit() {
      try {
        const response = await this.$axios({
          url: "/api/v1/files/philips",
          method: "get",
          responseType: "blob",
          params: this.formData,
        });

        const data = response.data;

        let blob = new Blob([data], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }

        const url = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = url;
        link.download = "philips.pdf";
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(url);
        }, 100);
      } catch (e) {
        if (e.isAxiosError) {
          const error = JSON.parse(await e.response.data.text());
          this.$toasted.error(error.message, { duration: 3000 });
        } else {
          this.$toasted.error("Une erreur est survenue.", { duration: 3000 });
        }
      }
    },
  },
};
</script>
