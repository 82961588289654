<template>
  <div>
    <cm-radio-button
      :values="values"
      :value="$store.state.o2Renewal.equipments"
      :valid="valid"
      :feedback="feedback"
      :feedback-data="feedbackData"
      :name="name"
      :label="label"
      :options="options"
      @input="onInput"
    ></cm-radio-button>
  </div>
</template>

<script>
import ValidationsMixin from '@/mixins/ValidationsMixin'
import CmRadioButton from '@/components/UI/CmRadioButton'

export default {
  mixins: [ValidationsMixin],
  components: {
    CmRadioButton,
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: function () {
        return {};
      }
    },
    values: {
      type: Array,
      default: () => []
    },
    value: {
      default: () => null
    }
  },

  methods: {
    onInput(v) {
      v.value = [v.value]
      this.$emit('input', v)
    }
  }
}
</script>
