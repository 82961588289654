import Vue from "vue";

// Import global styles
import "@/assets/main.scss";

// Import plugins
import "@/plugins/axios";
import "@/plugins/toasted";
import "@/plugins/bootstrap-vue";
import "@/plugins/directives";

import store from "@/plugins/vuex";
import i18n from "@/plugins/i18n";

import AskForCare from "@/pages/contact/AskForCare";
import PrescriptionRenewal from "@/pages/contact/PrescriptionRenewal";
import PhilipsPdf from "@/components/Modals/PhilipsPdf";
import ResmedPdf from "@/components/Modals/ResmedPdf";
import ConstructorPdf from "@/components/Modals/ConstructorPdf";

new Vue({
  el: "#app",
  components: {
    // Only load components that must be used in Rails template
    AskForCare,
    PrescriptionRenewal,
    PhilipsPdf,
    ResmedPdf,
    ConstructorPdf,
  },
  store,
  i18n,
});
