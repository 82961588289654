export function isBoolean(v) {
  return typeof v === "boolean"
}

export function isInteger(v) {
  const pattern = /^\d+$/
  return pattern.test(v)
}

export function onlyTwoDigitsOneDecimal(v) {
  const pattern = /^\d{1,2}(?:[.,]\d?)?$/
  return pattern.test(v)
}

export function onlyThreeDigitsOneDecimal(v) {
  const pattern = /^\d{1,3}(?:[.,]\d?)?$/
  return pattern.test(v)
}

export function isDate(v) {
  try {
    Date.parse(v)
    return true
  } catch(e) {
    return false
  }
}

export function isNotEmptyString(v) {
  return v !== ''
}

export function isNotEmptyArray(v) {
  return v.length > 0
}

export function emptyOrValid(v) {
  return v === '' || onlyTwoDigitsOneDecimal(v)
}

export function isAcceptedFiletype(v) {
  const validFiletype = ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']

  return v.length > 0  && validFiletype.includes(v[0]?.type)

  // v[0] could be null when remove a file from view. So v length > 0
  // but v[0] return null. Then we use the safe operator
}
