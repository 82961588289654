<template>
  <div>
    <b-form-file
        :placeholder="label"
        drop-placeholder="Placer un fichier ici..."
        browse-text="Parcourir"
        :accept="options.accept.join()"
        :state="shouldValidate"
        :invalid-feedback="invalidFeedback"
        class="is-truncated"
    ></b-form-file>
    <div class="mt-3 d-flex justify-content-between align-items-center" v-if="attachedExams">
      <span class="is-truncated">Fichier à envoyer : {{ attachedExams.name }}</span>
      <i @click="deleteAttachedFile" class="delete-button far fa-trash-alt 2x icon"></i>
    </div>
  </div>
</template>

<script>
import ValidationsMixin from "@/mixins/ValidationsMixin";

export default {
  mixins: [ValidationsMixin],
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    }
  }
}
</script>
