<template>
  <div class="vue-ask-for-care-patho vue-renewal-patho" >
    <div class="divider col-sm-12">
      <div class="row col-sm-12 d-flex">
        <div class="col-sm-4">
          <b-button class="pathology-badge" v-b-toggle.o2-content @click="toggleHead">
            <div class="d-flex">
              <i :class="['fas', '2x', collapseIcon]" id="toggle-o2-icon"></i>
              <span class="text-is-semibold">{{ $t('askForCare.o2.o2') }}</span>
            </div>
          </b-button>
        </div>
        <div v-if="isDeleted" class="col-sm-8">
          <span class="text-is-semibold deleted-date">Demande d'arrêt du traitement le {{ $store.state.o2Renewal.deletedAt }}</span>
        </div>
        <template v-if="expanded === false && !isDeleted">
          <div class="col-sm-8 d-flex justify-content-between" >
            <section>
              <b-badge class="tag" v-for="item in dataBadges" :key="item">
                <span class="d-flex">
                  {{item}}
                </span>
              </b-badge>
            </section>
          </div>
        </template>
      </div>
    </div>

    <b-collapse id="o2-content" :visible="isEditable" class="container">
      <template v-if="isEditable" v-for="group of fields">
        <cm-conditional-form-group
          :root="group.root"
          :conditions="group.group"
          :meta="group.meta"
          store-namespace="o2Renewal"
        >
          <div class="col-sm-12">
            <div :id="group.uid" class="col-sm-12">
              <template v-for="rows of group.fields">
                <div class="row">
                  <template v-for="field of rows">
                    <article
                      :class="rowClasses(field)"
                    >
                      <component
                        :is="field.type"
                        :values="field.values || []"
                        :value="data[field.name]"
                        :valid="validations[field.name] && validations[field.name].isValid"
                        :feedback="validations[field.name] && validations[field.name].feedback"
                        :feedback-data="data"
                        :name="field.name"
                        :label="field.label"
                        :options="field.options"
                        @input="onInput"
                      >
                      </component>
                    </article>
                  </template>
                </div>
              </template>

              <b-tooltip
                v-if="group.tooltip"
                :noninteractive="true"
                :show="true"
                :target="group.uid"
                placement="right"
                :custom-class="`text-is-medium is-tooltip is-${group.tooltipSize} no-max-width is-renew`"
                :container="group.uid"
                triggers="manual"
              >
                <span @click="onTooltipClick(group.onTooltipClick)">
                  {{ group.tooltip }}
                </span>
              </b-tooltip>
            </div>
          </div>
        </cm-conditional-form-group>
      </template>
    </b-collapse>
  </div>
</template>
<script>
import EquipmentsRadioInput from '@/components/prescriptions/EquipmentsRadioInput'
import EquipmentsCheckboxInput from '@/components/prescriptions/EquipmentsCheckboxInput'

import CmApiSelect from '@/components/UI/CmApiSelect'
import CmConditionalFormGroup from '@/components/UI/CmConditionalFormGroup'
import CmCheckbox from '@/components/UI/CmCheckbox'
import CmDateInput from '@/components/UI/CmDateInput'
import CmNumberInput from '@/components/UI/CmNumberInput'
import CmRadioButton from '@/components/UI/CmRadioButton'
import CmSelect from '@/components/UI/CmSelect'
import CmTextarea from '@/components/UI/CmTextarea'
import CmUpload from '@/components/UI/CmUpload'

export default {
  components: {
    EquipmentsRadioInput,
    EquipmentsCheckboxInput,
    CmApiSelect,
    CmConditionalFormGroup,
    CmCheckbox,
    CmDateInput,
    CmNumberInput,
    CmRadioButton,
    CmSelect,
    CmTextarea,
    CmUpload,
  },

  props: {
    prescription: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    patientId: {
      type: Number,
      required: true
    },
    validated: {
      type: Boolean,
      default: false,
      required: false
    },
    renewing: {
      type: Boolean,
      required: false,
      default: false
    },
    editable: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      expanded: true,
      hasPrescription: this.prescription.id !== undefined,
      fields: [],
      treatment: {},
      groupIds: {}
    }
  },

  computed: {
    data() {
      return this.$store.state.o2Renewal.data
    },
    validations() {
      return this.$store.state.o2Renewal.validations
    },
    collapseIcon() {
      return this.expanded ? 'fa-angle-double-up' : 'fa-angle-double-down'
    },
    isEditable() {
      return this.editable && !this.isDeleted
    },
    isDeleted() {
      return this.$store.state.o2Renewal.deleted
    },
    dataBadges() {
      let filterObject = {}
      const state = this.$store.state.o2Renewal.data
      const validations = this.$store.state.o2Renewal.validations
      const translateArray = (arr) => arr.map( item => this.$t(`askForCare.o2.${item}`))

      if(validations.o2_type && validations.o2_type.isValid && state.o2_type !== '') filterObject['o2_type']= 'Type O2 : '  + this.$t(`askForCare.o2.${state.o2_type}`)
      if(validations.with_ald && validations.with_ald.isValid && state.with_ald !== '') filterObject['with_ald'] = 'ALD : '+ this.$t(`common.${state.with_ald}`)
      if(validations.with_ald && validations.with_ald.isValid && state.with_ald === true && state.o2_type === 'long_term') filterObject['ald_on_list'] = 'Sur liste : '+ this.$t(`common.${state.ald_on_list}`)
      if(validations.start_date && validations.start_date.isValid && state.start_date !== '' && state.o2_type !== 'long_term') filterObject['start_date'] = `Date de début du traitement : ${new Date(state.start_date).toLocaleDateString('fr')}`
      if(validations.care_type && validations.care_type.isValid && state.care_type !== '' && state.o2_type === 'short_term') filterObject['care_type'] = 'Type de prise en charge : '+  this.$t(`askForCare.o2.${state.care_type}`)
      if(validations.walker && validations.walker.isValid && state.walker !== '' && state.o2_type === 'long_term') filterObject['walker'] = 'Déambulation : ' + this.$t(`askForCare.o2.walker.${state.walker}`)
      if(validations.equipments && validations.equipments.isValid && state.equipments.length > 0 && state.o2_type !== 'avf') filterObject['equipment'] = `Oxygénothérapie par : ${translateArray(state.equipments).join(', ')}`
      if(validations.consumables && validations.consumables.isValid && state.consumables.length > 0 && state.o2_type === 'short_term') filterObject['consumables'] = `Consommable(s) : ${translateArray(state.consumables).join(', ')}`
      if(validations.time_of_use && validations.time_of_use.isValid && state.time_of_use !== '') filterObject['time_of_use'] = `Durée d'utilisation : ${state.time_of_use} ${state.o2_type === 'avf' ? 'min' : 'h/jour'}`
      if(validations.flow_day && validations.flow_day.isValid && state.flow_day !== '' && state.o2_type !== 'avf') filterObject['flow_day'] = `Débit jour :  ${state.flow_day} l/min`
      if(validations.avf_flow && validations.avf_flow.isValid && state.avf_flow !== '' && state.o2_type === 'avf') filterObject['avf_flow'] = `Débit :  ${state.avf_flow} l/min`
      if(validations.flow_night && validations.flow_night.isValid && state.flow_night !== '' && state.o2_type === 'long_term') filterObject['flow_night'] = `Débit nuit : ${state.flow_night} l/min`
      if(validations.flow_discontinuous && validations.flow_discontinuous.isValid && state.flow_discontinuous !== '' && state.walker === true) filterObject['flow_discontinuous'] = `Débit d'effort : ` + this.$t(`askForCare.o2.flow_discontinuous.${state.flow_discontinuous}`)
      if(validations.flow_effort && validations.flow_effort.isValid && state.flow_effort !== '' && state.o2_type === 'long_term') filterObject['flow_effort'] = `Valeur du débit : ${state.flow_effort} l/min`
      if(validations.preferred_device && validations.preferred_device.isValid && state.preferred_device !== '' && state.walker === true && state.o2_type === 'long_term') filterObject['preferred_device'] = `Préférence matériel : ${state.preferred_device}`
      if(validations.arterial_blood_gas_date_test && validations.arterial_blood_gas_date_test.isValid && state.arterial_blood_gas_date_test !== '' && state.o2_type === 'long_term' && state.arterial_blood_gas_test !== '') filterObject['arterial_blood_gas_date_test'] = `Date du test GSA : ${new Date(state.arterial_blood_gas_date_test).toLocaleDateString('fr')}`
      if(validations.arterial_blood_gas_test && validations.arterial_blood_gas_test.isValid && state.arterial_blood_gas_test !== '' && state.o2_type === 'long_term' && (state.arterial_blood_gas_date_test !== '' || state.arterial_blood_gas_ph !== '' || state.arterial_blood_gas_pa_co2 !== '' || state.arterial_blood_gas_pa_o2 !== '' || state.arterial_blood_gas_sa_o2 !== ''))  filterObject['arterial_blood_gas_test'] = 'Nature du test GSA : ' + this.$t(`askForCare.o2.${state.arterial_blood_gas_test || 'not_set'}`)
      if(validations.arterial_blood_gas_ph && validations.arterial_blood_gas_ph.isValid && state.arterial_blood_gas_ph !== '' && state.o2_type === 'long_term' && state.arterial_blood_gas_test !== '') filterObject['arterial_blood_gas_ph'] = `GSA pH : ${state.arterial_blood_gas_ph}`
      if(validations.arterial_blood_gas_pa_co2 && validations.arterial_blood_gas_pa_co2.isValid && state.arterial_blood_gas_pa_co2 !== '' && state.o2_type === 'long_term' && state.arterial_blood_gas_test !== '') filterObject['arterial_blood_gas_pa_co2'] = `GSA PaCO2 : ${state.arterial_blood_gas_pa_co2}`
      if(validations.arterial_blood_gas_pa_o2 && validations.arterial_blood_gas_pa_o2.isValid && state.arterial_blood_gas_pa_o2 !== '' && state.o2_type === 'long_term' && state.arterial_blood_gas_test !== '') filterObject['arterial_blood_gas_pa_o2'] = `GSA PaO2 : ${state.arterial_blood_gas_pa_o2}`
      if(validations.arterial_blood_gas_sa_o2 && validations.arterial_blood_gas_sa_o2.isValid && state.arterial_blood_gas_sa_o2 !== '' && state.o2_type === 'long_term' && state.arterial_blood_gas_test !== '') filterObject['arterial_blood_gas_sa_o2'] = `GSA SaO2 : ${state.arterial_blood_gas_sa_o2}`
      if(validations.duration && validations.duration.isValid && state.duration !== '' && state.o2_type !== 'long_term') filterObject['duration'] = `Durée de l'ordonnance : ${state.duration} ${this.$t('common.' + state.duration_unit)}`
      if(validations.once_renewal.isValid  && ((state.o2_type === 'short_term' && state.care_type == 'dyspnea_palliative_care') || (state.o2_type === 'avf'))) filterObject['once_renewal'] = 'Renouvelable 1 fois :  ' + this.$t(`common.${state.once_renewal}`)

      if(this.validated) {
        this.$store.commit('o2Renewal/setDataBadges', filterObject)
      }

      return filterObject
    }
  },

  async mounted() {
    const url = this.hasPrescription ? `/api/v1/prescriptions/${this.prescription.id}/fields` : '/api/v1/prescriptions/fields'

    const response = await this.$axios.get(url, {
      params : {
        patient_id: this.patientId,
        renewing: this.renewing,
        only: ['o2']
      }
    })

    const data = response.data
    this.$store.dispatch('o2Renewal/loadFromTreatment', data.o2.treatment_data)

    this.treatment = this.$store.state.o2Renewal.data
    this.fields = data.o2.fields
    this.fields.map(group => group.uid = `row-${Math.random().toString(36).slice(4)}`)

    if (this.renewing && !this.$store.state.o2Renewal.deleted) {
      this.$emit('dirty')
    }
  },

  watch: {
    editable() {
      return this.expanded = this.editable
    },
    validated() {
      if(this.validated) {
        this.$store.commit('o2Renewal/setDataBadges', this.dataBadges)
      }
    },
  },

  methods: {
    toggleHead() {
      this.expanded = !this.expanded
    },

    onTooltipClick(data) {
      const keys = Object.keys(data)

      for (const key of keys) {
        const value = data[key]
        this.$store.commit('o2Renewal/update', { key, value })
      }
    },

    onInput(data) {
      this.$store.commit('o2Renewal/update', { key: data.name, value: data.value })
      this.$emit('dirty')
      this.$emit('input', { form: 'o2', ...data })
    },

    rowClasses(field) {
      return [
        { [`offset-sm-${field.options.offset}`]: field.options.offset !== undefined }, `col-sm-${field.col}`
      ]
    }
  }
}
</script>

<style lang="scss">
.vue-renewal-patho {
  #o2-content {
    .is-renew {
      &.is-30 {
        width: 30% !important;

        .tooltip-inner {
          max-width: 100%;
        }
      }

      &.is-50 {
        width: 50% !important;

        .tooltip-inner {
          max-width: 100%;
        }
      }

      &.is-tooltip {
        z-index: 1000;

        &.tooltip {
          opacity: 100 !important;
          right: 0 !important;
          left: initial !important;
          top: 40% !important;
          position: absolute !important;
          transform: none !important;
        }

        .tooltip-inner {
          &.no-max-width {
            max-width: initial;
          }

        }

        .arrow {
          display: inline;
          position: relative;
          left: -26px;
          top: 13px !important;

          &::before {
            top: 0.9rem;
          }

          &::after {
            border-width: 12px;
            top: 0.9rem;
            left: 4px;
          }
        }
      }
    }
  }
}
</style>
