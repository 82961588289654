// Import I18n files here

export default {
  fr: {
    philipsPdf: {
      generate: "Générer un PDF",
      summary: "Sommaire",
      detail: "Détaillé",
      trend: "Tendance",
      selectPeriod: "Sélectionner une période prédéfinie",
      startDate: "Date de début",
      endDate: "Date de fin",
      reportType: "Type de rapport",
    },

    resmedPdf: {
      generate: "Générer un PDF",
      caution: "Attention, la période doit être comprise en 1 et {nbDays} jours maximum.",
      reportTypes: {
        compliance:          "Rapport d'observance",
        therapy:             "Rapport thérapeutique",
        complianceTherapy:   "Rapport combiné",
        detailed:            "Rapport détaillé"
      },
      startDate: "Date de début",
      endDate: "Date de fin",
    },

    common: {
      weeks: "{count} semaine(s)",
      months: "{count} mois",
      true: "Oui",
      false: "Non",
    },
    askForCare: {
      main: {
        gender: {
          man: "Homme",
          woman: "Femme",
          1: "M.",
          2: "Mme",
          not_set: "",
        },
        name: "Prénom",
        lastname: "Nom",
        phone: "Téléphone",
        date_of_birth: "Date de naissance",
        securitynumber: "Numéro de sécurité sociale",
        zipcode: "Code postal",
        address: "Adresse",
        city: "Ville",
      },
      ppc: {
        auto: "Auto",
        fixed: "Fixe",
        ppc_auto: "PPC auto asservie",
        ppc: "PPC",

        other: "Autre",
        // Adult and IAH >= 30
        daytime_drowsiness: "Somnolence diurne",
        severe_snoring: "Ronflements sévères et quotidiens",
        sleep_apnea:
          "Sensations d'étouffement ou de suffocation pendant le sommeil",
        daytime_fatigue: "Fatigue diurne",
        nycturia: "Nycturie",
        morning_headaches: "Céphalées matinales",
        // Adult and IAH < 30
        daytime_drowsiness_hazards: "Somnolence diurne ou risque accidentel",
        comorbidity: "Comorbidité respi. ou cardio",
        // Child => old < 16y
        snoring: "Un ronflement quotidien",
        labored_breathing:
          "Une respiration laborieuse ou des efforts respiratoires pendant le sommeil",
        periodic_limb_movements: "Des mouvements avec réaction d'éveil",
        hyperhidrosis: "Une transpiration anormale",
        neck_hyperextension: "Une hyper-extension du cou pendant le sommeil",
        hyperactivity:
          "Une somnolence diurne excessive et/ou une hyperactivité, un comportement agressif",
        attention_deficit:
          "Des troubles de l'attention, de la concentration ou de la mémoire",
        secondary_enuresis: "Une énurésie secondaire",
        weight_stagnation: "Une stagnation pondérale",
      },
      o2: {
        avf: "AVF",
        cannula: "Lunettes O2",
        c: "Concentrateur",
        concentrator: "Concentrateur",
        dyspnea_palliative_care: "Dyspnée en soins palliatifs",
        endurance_natural_breathing_test: "A l'effort en Air",
        first_rest_natural_breathing_test: "Repos en air (1ère mesure)",
        l: "Liquide",
        liquid: "Liquide",
        long_term: "Long terme",
        mask: "Masque O2",
        o2: "Oxygénothérapie",
        g: "Bouteille d'O2 pour déambulation/secours",
        oxygen_tank: "Bouteille d'O2 pour déambulation/secours",
        rest_assisted_breathing_test: "Repos sous O2 ET/OU ventilation",
        second_rest_natural_breathing_test: "Repos en air (2nd mesure)",
        short_term: "Court terme",
        walker: {
          true: "Avec déambulation",
          false: "Sans déambulation",
        },
        transient_respiratory_failure: "Insuffisance respiratoire transitoire",
        flow_discontinuous: {
          false: "Continu",
          true: "Pulsé",
        },
        not_set: "Non défini",
        months: "Mois",
        weeks: "Semaines",
      },
      vni: {
        ai_and_vs: "AI+VS",
        ai_no_vs: "AI-VS",
        avaps_ae: "AVAPS AE",
        avaps: "AVAPS",
        facial: "Facial",
        mask: "Masque",
        nasal: "Nasal",
        st: "S/T",
        tracheotomy: "Interface/trachéotomie",
        vac: "VAC",
        vni: "VNI",
        not_set: "Non défini",
        other: "Autre",
      },
      aerosol: {
        aerosol: "Aérosol",
      },
      pressure_cycled_respirator: {
        pressure_cycled_respirator: "Relaxateur de pression",
      },
      tracheal_aspirator: {
        tracheal_aspirator: "Aspirateur trachéal",
      },
      unventilated_tracheostomy: {
        unventilated_tracheostomy: "Trachéotomie sans ventilation",
      },
    },
  },
};
