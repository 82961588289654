<template>
  <b-form-group>
    <label class="row">
      {{ label }}
      <sup v-if="options['required']" class="required"> requis</sup>
    </label>
    <b-form-input
      class="row"
      id="o2-avf-prescription-start-date"
      type="date"
      :value="inputValue"
      :valid="shouldValidate"
      :invalid-feedback="invalidFeedback"
      @input="onInput"
    ></b-form-input>
  </b-form-group>
</template>

<script>
import ValidationsMixin from '@/mixins/ValidationsMixin'

export default {
  mixins: [ValidationsMixin],

  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: function () { return {} },
    },
    name: {
      type: String,
      required: true
    },
    values: {
      type: Array,
      default: function () {
        return [];
      },
    }
  },

  destroyed() {
    this.$emit('destroyed', { name: this.name })
  },

  data() {
    return {
      inputValue: this.value || this.options.default
    }
  },

  methods: {
    onInput(data) {
      this.inputValue = data
      this.dirty = true
      this.$emit('input', { name: this.name, value: data })
    }
  }
}
</script>
