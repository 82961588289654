export default {
  data() {
    return {
      modalOpen: false
    }
  },
  methods: {
    toggleModal(status){
      this.modalOpen = status
    },

    deselectPathology({ pathology, fromArray }) {
      if (fromArray === undefined) {
        fromArray = this.$store.state.mainCareForm.pathologies
      }
      this.isSelected(pathology, false)
      this.$store.commit('update_main_pathologies', this.removePathology(pathology, fromArray))
      this.modalOpen = false
    },

    removePathology(pathology, pathologyArray) {
      const pathologyIndex = pathologyArray.indexOf(pathology)

      pathologyArray.splice(pathologyIndex, 1)
      return pathologyArray
    },

    emitDestroyForm() {
      this.$emit('form-destroy')
    },

    onlyTwoDigitsOneDecimal(str) {
      const pattern = /^\d{1,2}(?:[.,]\d?)?$/
      return pattern.test(str)
    },

    onlyThreeDigitsOneDecimal(str) {
      const pattern = /^\d{1,3}(?:[.,]\d?)?$/
      return pattern.test(str)
    },

    emptyOrValid(input) {
      return input === '' || this.onlyTwoDigitsOneDecimal(input)
    },

    selectFeedback(type = 'une valeur'){
      return `Sélectionnez ${type}`
    },

    numberFeedback(type = 'une valeur valide'){
      return `Entrez ${type}`
    },

    stringFeedback(type = 'une valeur valide') {
      return `Entrez ${type}`
    },

    textToFloat(value) {
      return value === '' ? '' : parseFloat(value.replace(',', '.'))
    }
  }
}
