<template>
  <div class="renew-modal main container-fluid">
    <div class="vue-ask-for-care-form overflow">
      <section class="col-sm-12 d-flex justify-content-between ">
        <div class="elivie-logo-renewal"></div>
        <a class="btn back-button-renewal btn-dark text-is-semibold" :href="patientPageLink">
          Annuler
        </a>
      </section>
      <section v-if="patient" class="col-sm-12 form-headers-renewal flex-row mb-0">
        <div class="ml-4">
          <p class="col-sm-8 text-is-semibold title-renewal">Ajouter ou modifier le traitement de {{ formatCivility(false) }}</p>
        </div>
      </section>

      <div class="m-0 p-0 row col-sm-12">
        <section class="col-sm-3 align-items-start pl-0">
          <div class="bloc bloc-row col-sm-12">
            <section class="col-sm-4">
              <cm-switch-button v-model="eordoFields" name="eordoFields"></cm-switch-button>
            </section>

            <section class="col-sm-8">
              Afficher les champs requis pour la prescription
            </section>
          </div>
          <cm-follow-scroll :offset="10" class="" id='form-aside'>


          <cm-stepper class="pr-4 pl-4 bloc" v-model="progress">
            <cm-stepper-step label="Identité du patient"></cm-stepper-step>

            <cm-stepper-step label="Prescription">
              <b-form-group
                id="general-pathology"
                name="general-pathology-options"
                class="stepper-checkbox"
              >
                <div
                  v-for="pathology of pathologies"
                  :key="pathology.value"
                  class="cursor-pointer"
                  @click.stop="changePathologyState(pathology)"
                >
                  <div
                    class="custom-controler-input custom-checkbox custom-control"
                    :class="{ 'is-selected': pathology.checked, 'is-disabled': pathology.disabled }"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input cursor-pointer"
                      :disabled="pathology.disabled"
                      :checked="pathology.checked"
                    />
                    <label class="cursor-pointer custom-control-label">
                      {{ pathology.text }}
                    </label>
                  </div>
                </div>
              </b-form-group>
            </cm-stepper-step>

            <cm-stepper-step label="Récapitulatif"></cm-stepper-step>
            </cm-stepper>
          </cm-follow-scroll>
        </section>

        <section class="col-sm-9 form-content">
          <form enctype="multipart/form-data">
            <section class="bloc">
              <section class="row col-sm-12 main-title d-flex">
                <div class="col-sm-4">
                  <h5 class="text-is-semibold"><span class="rounded-number">1</span>Identité du patient</h5>
                </div>
                <div class="col-sm-8 d-flex justify-content-between">
                  <section v-if="patient">
                    <b-badge class="tag" v-for="item in displayPatientBadges()" :key="item">
                      <span class="d-flex">
                        {{item}}
                      </span>
                    </b-badge>
                  </section>
                </div>
              </section>
            </section>

            <section class="bloc" v-show="progress > 0">
              <div class="row col-sm-12 d-flex">
                <div class="col-sm-4">
                  <h5 class="text-is-semibold"><span class="rounded-number">2</span>Prescription</h5>
                </div>
                <div v-if="fullTreatmentName" class="offset-sm-2 col-sm-6">
                  <span>Traitement actuel : </span>
                  <span
                    id="delete-current-treatment-button"
                    @click="confirmDeletion()"
                    class="current-treatment"
                  >
                    {{ fullTreatmentName.toUpperCase() }}
                    <b-tooltip target="delete-current-treatment-button" placement="top" triggers="hover" container="delete-current-treatment-button">
                      Arrêter le traitement
                    </b-tooltip>
                  </span>
                </div>
              </div>

              <div id="prescription-content" class="p-0 w-100" v-if="anyPathologySelected">
                <template v-for="pathology in pathologies">
                  <article :key="pathology.value" v-if="pathology.visible">
                    <component
                      :is="`${pathology.value}-form`"
                      :ald="$store.state.mainCareForm.globalAld"
                      :prescription="prescription"
                      :validated="validated"
                      :editable="pathology.editable"
                      :renewing="!modify || isCurrentTreatment(pathology.value)"
                      :patient-id="patientId"
                      :patient-age="getPatientAge()"
                      @input="onFormInput"
                      @form-destroy="onSectionDestroy(pathology.value)"
                      @dirty="setDirty(pathology.value)"
                    ></component>
                  </article>
                </template>

                <article>
                  <b-button
                    class="ml-4 mb-4 submit-button"
                    @click="submitForm (url())"
                  >
                    Valider l’ajout/la modification du traitement
                  </b-button>
                </article>
              </div>

              <div class="is-grey mt-4 divider col-sm-12" v-else>
                <i class="fas fa-long-arrow-alt-left"></i> Veuillez sélectionner un type de prescription ci-contre pour
                continuer le formulaire
              </div>

            </section>
          </form>
          <success-screen
            v-if="notConfirmed === false && this.$store.state.successScreenForm.prescriptionSent === false"
            :renewal="true"
            :back-link="patientPageLink"
            :prescription-ids="createdPrescriptionIds"
            :pathologies="checkedPathologies"
            :patient-badges="displayPatientBadges()"
          ></success-screen>
          <prescription-sent
            v-if="this.$store.state.successScreenForm.prescriptionSent"
            :back-link="patientPageLink"
            back-text="Retour au patient"
            ></prescription-sent>
          <cm-confirm
            :show.sync="modalOpen"
            :title="titleForModal"
            :data="dataForModal"
            ok-button-label="Supprimer le traitement"
            cancel-button-label="Annuler"
            @confirm-ok="okHandlerForModal"
            @confirm-cancel="cancelHandlerForModal"
          ></cm-confirm>

          <cm-confirm
            v-if="patient"
            :show.sync="stopTreatmentModalOpen"
            :title="`Confirmer l’arrêt du traitement ${(fullTreatmentName).toUpperCase()}`"
            :subtitle="`En cliquant sur ”Arrêter” vous indiquez l’arrêt du traitement ${(fullTreatmentName).toUpperCase()} du patient : ${patient.lastname.toUpperCase()} ${patient.firstname}.`"
            :ok-button-label="`Arrêter le traitement ${(fullTreatmentName).toUpperCase()}`"
            cancel-button-label="Annuler"
            @confirm-ok="setTreatmentsForDeletion()"
            @confirm-cancel="cancelHandlerForModal"
          ></cm-confirm>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import PpcForm from '@/components/prescriptions/PpcForm'
import O2Form from '@/components/prescriptions/O2Form'
import VniForm from '@/components/prescriptions/VniForm'
import SuccessScreen from '@/components/prescriptions/SuccessScreen'
import PrescriptionSent from '@/components/askForCare/PrescriptionSent'
import CmConfirm from '@/components/UI/Modal/CmConfirm'
import CmStepper from '@/components/UI/Stepper/CmStepper'
import CmStepperStep from '@/components/UI/Stepper/CmStepperStep'
import CmFollowScroll from '@/components/UI/CmFollowScroll'
import CmSwitchButton from '@/components/UI/CmSwitchButton'
import CmRadioButton from '@/components/UI/CmRadioButton'

import AskForCareMixin from '@/mixins/AskForCareMixin'

export default {
  mixins: [AskForCareMixin],
  props: {
    prescriptionIds: {
      type: Array,
      required: true
    },
    patientId: {
      type: Number,
      required: true
    },
    userId: {
      type: Number,
      required: true
    },
    modify: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  components: {
    CmFollowScroll,
    CmStepper,
    CmStepperStep,
    CmSwitchButton,
    CmConfirm,
    CmRadioButton,
    SuccessScreen,
    PrescriptionSent,
    PpcForm,
    O2Form,
    VniForm,
  },
  filters:{
    strSecurityNumber: function(value){
      return `${value.substring(0,1)} ${value.substring(1,3)} ${value.substring(3,5)} ${value.substring(5,7)} ${value.substring(7,10)} ${value.substring(10,13)} ${value.substring(13,15)}`
    }
  },

  data() {
    return {
      userSettings: [],
      setting: {},
      prescriptionId: null,
      prescription: {},
      dirtyForms: {
        o2: false,
        ppc: false,
        vni: false
      },
      treatments: {},
      createdPrescriptionIds: [],
      patientPageLink: `/respiratory/patients/${this.patientId}`,
      patient: null,
      progress: 1,
      mustBeDeleted: false,
      titleForModal: null,
      dataForModal: '',
      stopTreatmentModalOpen: false,
      okHandlerForModal: () => {},
      cancelHandlerForModal: () => {},
      notConfirmed: true,
      sended: false,
      checkedPathologies: [],
      link: '',
      checkedCivility: [],
      genderOptions: [
        { text: this.$t('askForCare.main.gender.man'), value: 0, selected: true },
        { text: this.$t('askForCare.main.gender.woman')  , value: 1, selected: false }
      ],
      old_pathologies_array : [],
      specialityOptions: [
        { text: 'Respiratoire', value: 'ard' }
      ],
      // checked is used for the checkboxes, while visible is used to show or hide form sections
      // needs to be separate as the checkboxes are applied before any checks
      pathologies: [
        {
          text: this.$t('askForCare.ppc.ppc'),
          value: 'ppc',
          editable: true,
          checked: false,
          visible: false
        },
        {
          text: this.$t('askForCare.o2.o2'),
          value: 'o2',
          editable: true,
          checked: false,
          visible: false
        },
        {
          text: this.$t('askForCare.vni.vni'),
          value: 'vni',
          editable: true,
          checked: false,
          visible: false
        }
      ],
      incompatiblePathologies: {
        ppc: 'vni',
        vni: 'ppc'
      },
      payload: {
        address: this.$store.state.mainCareForm.address,
        city: this.$store.state.mainCareForm.city,
        date_of_birth: this.$store.state.mainCareForm.date_of_birth,
        firstname: this.$store.state.mainCareForm.firstname,
        gender: this.$store.state.mainCareForm.gender,
        lastname: this.$store.state.mainCareForm.lastname,
        phone: this.$store.state.mainCareForm.phone,
        securitynumbe: this.$store.state.mainCareForm.securitynumber,
        zipcode: this.$store.state.mainCareForm.zipcode,
        email_test: this.$store.state.mainCareForm.email_test,
      },
      sendData: {},
      validated: false
    }
  },

  async mounted() {
    await this.loadPrescriptions()
    await this.loadPatient()
    await this.loadSettings()
  },

  computed: {
    mainVisible() {
      false
    },

    prescriptionVisible() {
      return this.progress === 1
    },

    anyPathologySelected() {
      return this.checkedPathologies.length !== 0
    },
    gender: {
      get() {
        return this.$store.state.mainCareForm.gender
      },
      set(value) {
        this.$store.commit('update_main_gender', value)
      }
    },

    eordoFields: {
      get() {
        return this.$store.state.mainCareForm.settings.eordo_mandatory_fields
      },
      set(value) {
        if (value) {
          this.$axios.post(`/api/v1/users/${this.userId}/user_custom_setting_links`, {
            user_id: this.userId,
            custom_setting_id: this.setting.id
          })
        } else {
          this.$axios.delete(`/api/v1/users/${this.userId}/user_custom_setting_links/${this.setting.id}`)
        }

        this.$store.commit('update_main_eordoFields', value)
        this.$store.commit('ppcRenewal/update', { key: 'eordo_mandatory_fields',  value })
      }
    },

    // Validation
    zipcodeVal(){
      return this.payload.zipcode.length === 5 || (!this.$store.state.mainCareForm.ajaxSent && this.payload.zipcode === '')
    },
    lastnameVal(){
      return this.payload.lastname.length > 0 || (!this.$store.state.mainCareForm.ajaxSent && this.payload.lastname === '')
    },
    phoneVal(){
      return this.payload.phone.replaceAll(' ', '').length === 10 || (!this.$store.state.mainCareForm.ajaxSent && this.payload.phone === '')
    },

    // Invalid Feedback
    phoneFeedback() {
      return 'Merci de rentrer un numéro de téléphone à 10 chiffres'
    },
    lastNameFeedback() {
      return 'Entrez un nom'
    },
    zipcodeFeedback() {
      return 'Entrez un code postal à 5 chiffres'
    },
    validForms() {
      let validness = [this.$store.getters.validMainForm]

      if (this.checkedPathologies.includes('ppc')) {
        validness.push(this.$store.getters.validPpcForm)
      }
      if (this.checkedPathologies.includes('o2')) {
        validness.push(this.$store.getters.validO2Form)
      }
      if (this.checkedPathologies.includes('vni')) {
        validness.push(this.$store.getters.validVniForm)
      }

      return this.checkedPathologies.length > 0 && (validness.every((el) => el === true));
    },

    treatmentNames() {
      return Object.keys(this.treatments)
    },

    fullTreatmentName() {
      return this.treatmentNames.join(' + ')
    }
  },
  methods: {
    isCurrentTreatment(pathology) {
      return this.treatmentNames.includes(pathology)
    },

    async loadPatient() {
      const { data } = await this.$axios.get(`/api/v1/patients/${this.patientId}`)
      this.patient = data.patient
    },

    async loadSettings() {
      const userSettingsUrl   = `/api/v1/users/${this.userId}/user_custom_setting_links`
      const customSettingsUrl = '/api/v1/custom_settings'

      const params = {
        label: ['eordo_mandatory_fields']
      }

      try {
        const { data } = await this.$axios.get(userSettingsUrl, { params })
        this.userSettings = data.settings
        this.$store.commit('update_main_eordoFields', this.userSettings.length > 0)
      } catch(error) {
        switch (error.response.status) {
          case 404:
            this.$toasted.error("L'utilisateur n'a pas été trouvé.", { duration: 5000 })
            break;
          default:
            console.error(error)
            this.$toasted.error("Un problème est survenue lors de la récupération de ce paramètre.", { duration: 5000 })
            break;
        }
      }

      try {
        const { data } = await this.$axios.get(customSettingsUrl, { params })
        this.setting = data.settings[0]
      } catch(error) {
        console.error(error)
        this.$toasted.error("Un problème est survenue lors de la récupération de des paramètres.", { duration: 5000 })
      }
    },
    async loadPrescriptions() {
      if (this.prescriptionIds.length === 0) return;

      try {
        for (const prescriptionId of this.prescriptionIds) {
          const {data} = await this.$axios.get(`/api/v1/prescriptions/${prescriptionId}`)
          this.prescription = data.prescription
          this.treatments = data.treatments

          if (this.treatments !== {}) {
            this.prescriptionId = prescriptionId
            this.pathologies.filter(pathology => this.treatmentNames.includes(pathology.value)).forEach(pathology => {
              if (!this.modify) {
                this.selectPathology(pathology)
                this.disablePathology(pathology)
              }
            })
          }

          if (data.unsupported_treatment) {
            this.$toasted.info(
              `Les traitements ${data.unsupported_treatment} ne sont pas encore supportés au renouvellement.`,
              {duration: 5000}
            )
          }
        }
      } catch(e) {
        if (e.response.status === 404) {
          location = this.patientPageLink + "?alert=La prescription n'a pas été trouvée"
        } else {
          this.$toasted.error('Une erreur est survenue lors du chargement de la prescription.')
        }
      }
    },

    /**
     *  Sets the current treatment as editable depending on o2_type, only when currently modifying / adding a treatment.
     */
    onFormInput(data) {
      if(!this.modify) false

      if (data.form === 'o2' && data.name === 'o2_type') {
        const currentTreatmentType = this.treatmentNames.find(treatment => treatment !== 'o2' && (treatment === 'ppc' || treatment === 'vni'))

        if (this.checkedPathologies.includes(this.incompatiblePathologies[currentTreatmentType])) return;

        this.pathologies.forEach(patho => this.enablePathology(patho))

        if (!currentTreatmentType) return;

        const dateLimit = new Date()
        dateLimit.setHours(0, 0, 0)
        dateLimit.setMonth(dateLimit.getMonth() + 3)
        const currentTreatment = this.treatments[currentTreatmentType] || {}
        const pathology = this.pathologies.find(pathology => pathology.value === currentTreatmentType)

        switch(data.value) {
          // When short_term, then if currentTreatment expires within the next 3 months, allow edition
          case 'short_term':
            this.selectPathology(pathology)
            this.disablePathology(pathology)
            if (currentTreatment.end_date < dateLimit){
              pathology.editable = true
            } else {
              pathology.editable = false
            }

            break;
          // When long_term, then allow edition
          case 'long_term':
            this.selectPathology(pathology)
            this.disablePathology(pathology)
            pathology.editable = true
            break
          // When avf, then entirely disable it
          case 'avf':
            this.deselectPathology(pathology)
            pathology.editable = false
            break;
        }
      }
    },

    confirmDeletion() {
      this.stopTreatmentModalOpen = true
    },

    setTreatmentsForDeletion() {
      for (const treatment of this.treatmentNames) {
        this.deleteTreatment(treatment)
      }
      this.mustBeDeleted = true
      this.stopTreatmentModalOpen = false
    },

    deleteTreatment(treatment) {
      const pathology = this.pathologies.find(pathology => pathology.value === treatment)
      this.$store.commit(`${treatment}Renewal/delete`)
      this.selectPathology(pathology)
      this.disablePathology(pathology)
      this.mustBeDeleted = true
      this.dirtyForms[pathology.value] = false
    },

    formatCivility(upperCased = true) {
      const lastname = upperCased ? this.patient.lastname.toUpperCase() : this.patient.lastname
      return [this.$t(`askForCare.main.gender.${this.patient.gender || 'not_set'}`), lastname, this.patient.firstname].join(' ');
    },

    displayPatientBadges() {
      let filterObject = {}

      if(this.patient.lastname) {
        filterObject['civility'] = this.formatCivility()
      }
      if(this.patient.phone) {
        filterObject['phone'] = [this.$t('askForCare.main.phone'), this.patient.phone].join(' ')
      }
      if(this.patient.date_of_birth) {
        filterObject['date_of_birth'] = [this.$t('askForCare.main.date_of_birth'), new Date(this.patient.date_of_birth).toLocaleDateString('fr')].join(' ')
      }
      if(this.patient.securitynumber) {
        filterObject['securitynumber'] = [this.$t('askForCare.main.securitynumber'), this.patient.securitynumber].join(' ')
      }
      if(this.patient.zipcode) {
        filterObject['zipcode'] = [this.$t('askForCare.main.zipcode'), this.patient.zipcode].join(' ')
      }
      if(this.patient.address) {
        filterObject['address'] = [this.$t('askForCare.main.address'), this.patient.address].join(' ')
      }
      if(this.patient.city) {
        filterObject['city'] = [this.$t('askForCare.main.city'), this.patient.city].join(' ')
      }
      return filterObject
    },

    modalTitle(pathology) {
      return `Un traitement par ${pathology.toUpperCase()} est déjà sélectionné pour ce patient. La modification du traitement entraînera la suppression des données renseignées dans le formulaire.`
    },

    modalTitleSingle(pathology) {
      return `Confirmer la suppression de ce traitement ${pathology.toUpperCase()}`
    },

    onSectionDestroy(pathologyName) {
      const pathology = this.pathologies.find(pathology => pathology.value === pathologyName)
      this.changePathologyState(pathology)
    },

    getPatientAge() {
      if(!this.patient || this.patient.date_of_birth === '' ) {
        return null
      }

      let today = new Date();
      let birthDate = new Date(this.patient.date_of_birth);
      let age = today.getFullYear() - birthDate.getFullYear();
      let month = today.getMonth() - birthDate.getMonth();
      if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
      return age;
    },

    changePathologyState(pathology) {
      if (pathology.disabled) return;

      pathology.checked = !pathology.checked
      this.pathologies.forEach(patho => this.enablePathology(patho))

      // if it was deselected
      if (this.checkedPathologies.includes(pathology.value)) {
        if (this.dirtyForms[pathology.value]) {
          this.dataForModal = { pathology }
          this.titleForModal = this.modalTitleSingle(pathology.value)
          this.okHandlerForModal = (data) => {
            this.deselectPathology(data.pathology)
            if (this.modify && (this.checkedPathologies & this.treatmentNames).length > 0) {
              this.pathologies.forEach(patho => this.deselectPathology(patho))
            }
          }
          this.cancelHandlerForModal = async (data) => {
            this.selectPathology(data.pathology)
          }
          this.toggleModal(true)
        } else {
          this.deselectPathology(pathology)

          if (this.modify && (this.checkedPathologies & this.treatmentNames).length > 0) {
            this.pathologies.forEach(patho => this.deselectPathology(patho))
          }
        }
        // if it was selected
      } else {
        // if it's o2 and we're adding a new treatment, also select current ppc or vni if any
        if (pathology.value === 'o2') {
          this.selectPathology(pathology);

          if (this.modify) {
            this.selectAllCurrentTreatments()
          }

          this.disableSelectedCurrentTreatments()
          return
        }

        // if vni, check if current treatment is ppc
        if (pathology.value === 'vni' && this.treatmentNames.includes('ppc')) {
          const ppc = this.pathologies.find(pathology => pathology.value === 'ppc')
          this.dataForModal = { incompatiblePathology: ppc, pathology }
          this.titleForModal = this.modalTitle('ppc')
          this.okHandlerForModal = (data) => {
            this.selectPathology(data.pathology)
            if (this.modify) {
              this.selectAllCurrentTreatments()
            }
            this.deselectPathology(data.incompatiblePathology)
            this.deleteTreatment(data.incompatiblePathology.value)
            this.disableSelectedCurrentTreatments()
          }
          this.cancelHandlerForModal = async (data) => {
            this.deselectPathology(data.pathology)
            this.selectPathology(data.incompatiblePathology)
          }
          return this.toggleModal(true)
        }

        if (pathology.value === 'ppc' && this.treatmentNames.includes('vni')) {
          const vni = this.pathologies.find(pathology => pathology.value === 'vni')
          this.dataForModal = { incompatiblePathology: vni, pathology }
          this.titleForModal = this.modalTitle('vni')
          this.okHandlerForModal = (data) => {
            this.selectPathology(data.pathology)
            if (this.modify) {
              this.selectAllCurrentTreatments()
            }
            this.deselectPathology(data.incompatiblePathology)
            this.deleteTreatment(data.incompatiblePathology.value)
            this.disableSelectedCurrentTreatments()
          }
          this.cancelHandlerForModal = async (data) => {
            this.deselectPathology(data.pathology)
            this.selectPathology(data.incompatiblePathology)
          }
          return this.toggleModal(true)
        }

        // if vni or ppc is already selected
        if (this.checkedPathologies.includes('vni') || this.checkedPathologies.includes('ppc')) {
          const incompatiblePathology = this.pathologies.find(_pathology => _pathology.value === this.incompatiblePathologies[pathology.value])

          if (this.dirtyForms[pathology.value]) {
            this.dataForModal = { incompatiblePathology, pathology }
            this.titleForModal = this.modalTitle(incompatiblePathology.value)
            this.okHandlerForModal = (data) => {
              this.deselectPathology(data.incompatiblePathology)
              this.selectPathology(data.pathology)
              this.disableSelectedCurrentTreatments()
            }
            this.cancelHandlerForModal = async (data) => {
              this.deselectPathology(data.pathology)
              this.selectPathology(data.incompatiblePathology)
            }
            this.toggleModal(true)
          } else {
            // remove incompatible pathology from the new array and set the result
            this.deselectPathology(incompatiblePathology)
            this.selectPathology(pathology)
            this.disableSelectedCurrentTreatments()
          }

        } else {
          if (this.modify) {
            this.selectAllCurrentTreatments()
          }
          this.selectPathology(pathology)
          this.disableSelectedCurrentTreatments()
        }
      }
    },

    selectAllCurrentTreatments() {
      this.pathologies.filter(pathology => this.treatmentNames.includes(pathology.value)).forEach(pathology => {
        this.selectPathology(pathology)
      })
    },

    disableSelectedCurrentTreatments() {
      this.pathologies.filter(pathology => this.treatmentNames.includes(pathology.value)).forEach(pathology => {
        if (this.checkedPathologies.includes(pathology.value)) {
          this.disablePathology(pathology)
        }
      })
    },

    deselectPathology(pathology) {
      this.togglePathology(pathology, false)
      delete this.sendData[pathology.value]
      this.dirtyForms[pathology.value] = false
    },

    selectPathology(pathology) {
      this.togglePathology(pathology, true)
    },

    disablePathology(pathology) {
      pathology.disabled = true

      const index = this.pathologies.findIndex(_pathology => _pathology.value === pathology.value)
      this.$set(this.pathologies, index, pathology)
    },

    enablePathology(pathology) {
      pathology.disabled = false

      const index = this.pathologies.findIndex(_pathology => _pathology.value === pathology.value)
      this.$set(this.pathologies, index, pathology)
    },

    togglePathology(pathology, status) {
      pathology.checked = status
      pathology.visible = status
      const index = this.pathologies.findIndex(_pathology => _pathology.value === pathology.value)
      this.$set(this.pathologies, index, pathology)
      this.checkedPathologies = this.pathologies.filter(pathology => pathology.checked).map(pathology => pathology.value)
    },

    url(){
      return `/api/v1/patients/${this.patientId}/prescriptions`
    },
    send(){
      this.sended = true
      this.$toasted.success("La demande de création d'une prescription est effectuée", { duration: 5000 })
    },

    getMainData(){
      return {
        address        : this.$store.state.mainCareForm.address,
        city           : this.$store.state.mainCareForm.city,
        date_of_birth  : this.$store.state.mainCareForm.date_of_birth,
        firstname      : this.$store.state.mainCareForm.firstname,
        gender         : this.$store.state.mainCareForm.gender,
        lastname       : this.$store.state.mainCareForm.lastname,
        phone          : this.$store.state.mainCareForm.phone,
        securitynumber : this.$store.state.mainCareForm.securitynumber,
        zipcode        : this.$store.state.mainCareForm.zipcode,
        email_test     : this.$store.state.mainCareForm.email_test,
        pathology      : this.checkedPathologies,
        custom_settings : this.$store.getters.currentSettings
      }
    },
    getPpcData(){
      if(this.checkedPathologies.includes('ppc')) {
        return {
          additional_info_ppc : this.$store.state.ppcCareForm.additional_info_ppc,
          ai_max_ppc          : this.$store.state.ppcCareForm.ai_max_ppc,
          ai_min_ppc          : this.$store.state.ppcCareForm.ai_min_ppc,
          ald_on_list_ppc     : this.$store.state.ppcCareForm.ald_on_list_ppc,
          attached_exams      : this.$store.state.ppcCareForm.attachedExams,
          frequency_ppc       : this.$store.state.ppcCareForm.frequency_ppc,
          humidifier_ppc      : this.$store.state.ppcCareForm.humidifier_ppc,
          iah                 : this.$store.state.ppcCareForm.iah,
          p_auto_max          : this.$store.state.ppcCareForm.p_auto_max,
          p_auto_min          : this.$store.state.ppcCareForm.p_auto_min,
          p_fixed             : this.$store.state.ppcCareForm.p_fixed,
          pep_auto_max_ppc    : this.$store.state.ppcCareForm.pep_auto_max_ppc,
          pep_auto_min_ppc    : this.$store.state.ppcCareForm.pep_auto_min_ppc,
          pep_ppc             : this.$store.state.ppcCareForm.pep_ppc,
          ppc_type            : this.$store.state.ppcCareForm.ppc_type,
          pressure_type       : this.$store.state.ppcCareForm.pressure_type,
          with_ald_ppc        : this.$store.state.mainCareForm.globalAld
        }
      }
      else{
        return {}
      }
    },
    getO2Data(){
      if(this.checkedPathologies.includes('o2')) {
        return {
          additional_info_o2           : this.$store.state.o2CareForm.additional_info_o2,
          ald_on_list_o2               : this.$store.state.mainCareForm.globalAld === true ? this.$store.state.mainCareForm.globalAldList : null,
          arterial_blood_gas_date_test : this.$store.state.o2CareForm.arterial_blood_gas_date_test,
          arterial_blood_gas_pa_co2    : this.$store.state.o2CareForm.arterial_blood_gas_pa_co2,
          arterial_blood_gas_pa_o2     : this.$store.state.o2CareForm.arterial_blood_gas_pa_o2,
          arterial_blood_gas_ph        : this.$store.state.o2CareForm.arterial_blood_gas_ph,
          arterial_blood_gas_sa_o2     : this.$store.state.o2CareForm.arterial_blood_gas_sa_o2,
          arterial_blood_gas_test      : this.$store.state.o2CareForm.arterial_blood_gas_test,
          care_type                    : this.$store.state.o2CareForm.care_type,
          consumable                   : this.$store.state.o2CareForm.consumable,
          devices_packages             : this.$store.state.o2CareForm.devices_packages,
          equipment                    : this.$store.state.o2CareForm.equipment,
          flow_day                     : this.$store.state.o2CareForm.flow_day,
          avf_flow                     : this.$store.state.o2CareForm.avf_flow,
          flow_discontinuous           : this.$store.state.o2CareForm.flow_discontinuous,
          flow_effort                  : this.$store.state.o2CareForm.flow_effort,
          flow_night                   : this.$store.state.o2CareForm.flow_night,
          humidifier_o2                : null,
          o2_type                      : this.$store.state.o2CareForm.o2_type,
          once_renewal                 : isRenewal(this.$store.state.o2CareForm.once_renewal),
          preferred_device             : this.$store.state.o2CareForm.preferred_device,
          duration                     : this.$store.state.o2CareForm.duration,
          start_date                   : this.$store.state.o2CareForm.start_date,
          time_of_use_o2               : this.$store.state.o2CareForm.time_of_use_o2,
          walker                       : this.$store.state.o2CareForm.walker,
          with_ald_o2                  : this.$store.state.mainCareForm.globalAld
        }
      }
      else{
        return {}
      }
    },
    getVniData(){
      if(this.checkedPathologies.includes('vni')) {
        return {
          additional_info_vni : this.$store.state.vniCareForm.additional_info_vni,
          ai_max_vni          : this.$store.state.vniCareForm.ai_max_vni,
          ai_min_vni          : this.$store.state.vniCareForm.ai_min_vni,
          ald_on_list_vni     : this.$store.state.mainCareForm.globalAld === true ? this.$store.state.mainCareForm.globalAldList : null,
          frequency_vni       : this.$store.state.vniCareForm.frequency_vni,
          humidifier_vni      : this.$store.state.vniCareForm.humidifier_vni,
          interface           : this.$store.state.vniCareForm.interface,
          mask_type           : this.$store.state.vniCareForm.mask_type,
          mode_vni            : this.$store.state.vniCareForm.mode_vni,
          pep_auto_max_vni    : this.$store.state.vniCareForm.pep_auto_max_vni,
          pep_auto_min_vni    : this.$store.state.vniCareForm.pep_auto_min_vni,
          pep_vni             : this.$store.state.vniCareForm.pep_vni,
          pip                 : this.$store.state.vniCareForm.pip,
          pip_max             : this.$store.state.vniCareForm.pip_max,
          pip_min             : this.$store.state.vniCareForm.pip_min,
          slope               : this.$store.state.vniCareForm.slope,
          ti_fixed            : this.$store.state.vniCareForm.ti_fixed,
          ti_max              : this.$store.state.vniCareForm.ti_max,
          ti_min              : this.$store.state.vniCareForm.ti_min,
          time_of_use_vni     : this.$store.state.vniCareForm.time_of_use_vni,
          trigger_exp         : this.$store.state.vniCareForm.trigger_exp,
          trigger_insp        : this.$store.state.vniCareForm.trigger_insp,
          volume              : this.$store.state.vniCareForm.volume,
          with_ald_vni        : this.$store.state.mainCareForm.globalAld,
        }
      }
      else{
        return {}
      }
    },
    async submitForm(url){
      let deletedTreatments = []
      let anyErrors = false

      for (const pathology of this.checkedPathologies) {
        const storeName = `${pathology}Renewal`
        this.$store.dispatch(`${storeName}/validate`)

        if (this.$store.state[storeName].isValid) {
          if (this.$store.state[storeName].deleted) {
            deletedTreatments.push(pathology)
          } else {
            this.sendData[pathology] = pathology === 'o2' ? this.buildO2(storeName) : this.$store.getters[`${storeName}/formData`]
          }
        } else {
          anyErrors = true
        }
      }

      if (anyErrors) {
        return this.$toasted.error("Certains champs sont invalides.", { duration: 5000 });
      }

      this.sendData['previous_prescription_id'] = this.prescription.id
      this.validated = true

      let response = {}

      if (Object.values(this.dirtyForms).some((e) => e === true)) {
        try {
          response = await this.$axios.post(url, {
            prescriptions: [this.sendData]
          })
        } catch (error) {
          console.error(error);
          return this.onSubmitError("Certains champs sont invalides.")
        } finally {
          this.onSubmitFinally()
        }
      }

      if (this.mustBeDeleted) {
        try {
          await this.deleteTreatments()
        } catch(error) {
          console.error(error);
          return this.onSubmitError('Impossible de supprimer les traitements.')
        } finally {
          this.onSubmitFinally()
        }
      }

      this.onSubmitSuccess(response)
    },
    isRenewal(key) {
      key.length > 0 ? key[0] : false
    },
    onSubmitSuccess(response) {
      this.setProgress(2)
      this.notConfirmed = false
      this.createdPrescriptionIds = response.data.prescription_ids || []
    },
    onSubmitError(message) {
      this.$toasted.error(message, { duration: 5000 })
    },

    onSubmitFinally() {
      this.$store.commit('update', { key: 'ajaxSent', value: true })
    },

    buildO2(storeName) {
      const copy = Object.assign({}, this.$store.state[storeName].data)
      const type = copy.o2_type
      delete copy.o2_type

      const duration = copy.duration
      copy.duration = copy.duration_unit === 'months' ? duration * 4 : duration
      delete copy.duration_unit

      return {
        [type]: copy
      }
    },

    deleteTreatments() {
      return this.$axios.delete('/api/v1/prescriptions/' + this.prescriptionId)
    },

    setAttr(e, attr){
      return this.$store.commit(`update_main_${attr}`, e.target.value)
    },
    setProgress(toNumber){
      this.progress = toNumber
    },
    isActive(value, stateValue) {
      return (value === stateValue)
    },

    setDirty(pathology) {
      this.dirtyForms[pathology] = true
    }
  }
}
</script>

<style lang="scss">
/* LOAD FONT */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.renew-modal {
  display: flex;

  .overflow {
    overflow: auto;
  }
  &.main {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1031; // The navbar is 1030
    justify-content: center;
    background: rgba(33, 33, 33, 0.85);
    padding: 30px;

    .form-headers-renewal {
      display: flex;
      align-items: center;
      background-color: transparent;
      background-image: url('../../assets/images/Photo.png');
      background-repeat: no-repeat;
      background-position: right;
      background-size: cover;
      border: none;
      padding-left: 20px;
      margin: 30px;
      height: 180px;
      right: 30px;
      top: -30px
    }
  }

  .vue-ask-for-care-form{
    width: 75vw;
  }
}

.is-grey {
  color: #9fa5cd;
}

.stepper-checkbox {
  .custom-checkbox {
    width: 100%;
    padding: 11px 145px 11px 8px;

    border-radius: 4px;
    border: solid 1px #cdcdcd;

    margin: 8px 0;

    &.is-selected {
      background-color: #ebedf9;
    }

    &.is-disabled {
      background-color: #ececec;
      cursor: not-allowed;

      label {
        color: #9d9d9d;

        &:before {
          background-color: #b1b1b1 !important;
          border-color: #b1b1b1 !important;
        }
      }
    }

    label {
      color: #9d9d9d;
      font-size: 12px;
      margin-left: 24px;
      line-height: 24px;

      &:before,
      &:after {
        cursor: pointer;
      }
    }
  }

}

.update-btn {
  color: #4a4f62;
  padding: 8px 12px;
  border-radius: 6px;
  background: #e4e6ed;
}

.update-btn {
  color: #4a4f62;
  padding: 8px 12px;
  border-radius: 6px;
  background: #e4e6ed;
}

.text-is-medium {
  font-weight: 500;
}

.rounded-number {
  display: inline-block;
  width: 34px;
  height: 34px;
  color: #4a61e3;
  text-align: center;
  border: 2px solid #4a61e3;
  border-radius: 100%;
  line-height: initial;
  margin-right: 14px;
  padding: 3px;
  font-size: 18px;
}

.vue-ask-for-care-form {
  background-color: #f9f7f5;

  .custom-select {
    background: #fff url('../../assets/images/icons/Icons_Dropdown.svg') no-repeat right 0.75rem center/24px 24px !important;
    height: calc(2em + 0.75rem + 6px);
  }

  .form-control {
    /* Boostrap add useless padding  */
    padding-right: calc(0.75rem);

    &.like-label {
      padding-top: 0px;
      padding-bottom: 0px;
      background-color: white;
      color: black;

      /* Border around Autre text field  PPC component*/
      padding-left: 5px;
      margin-left: 2%;
      border-radius: 6px;
      border: solid 1px gainsboro
    }

    &.is-valid {
      border: solid 1px gainsboro
    }

    &.no-logotype {
      background-image: none;
    }
  }

  .is-tooltip {
    &.tooltip {
      opacity: 100 !important;
      left: 30% !important;
      top: -30% !important; // Top and left are set on element
    }

    .tooltip-inner {
      color: black;
      text-align: justify;
      background-color: #fff8e6;
      border-radius: 12px;
      border: 1px solid #ffb800;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    }

    .arrow {
      position: absolute;
      width: 36px;
      height: 36px;
      left: -18px; // half of this.width
      top: 29px !important; // half of his parent height - height/2; important because bootstrap adds top on element
    }

    .arrow::after,
    .arrow::before {
      content: '';
      position: absolute;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
    }

    .arrow::before {
      border-width: 13px;
      border-color: transparent;
      border-right-color: transparent;
      border-right-color: #ffb800;
      left: 1%;
    }

    .arrow::after {
      top: .2rem;
      border-color: transparent #fff8e6 transparent transparent;
      border-width: 10px;
      left: 18%;
    }
  }


  label {
    font-weight: 500;
  }

  #form-aside label {
    margin-bottom: 0;
  }

  article {
    margin-top: 15px;
  }

  .divider {
    border-top: 1px solid #e2e4f1;
    padding-top: 30px;
    margin-bottom: 15px;
  }

  .bloc {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 12px;
    border: solid 1px #e2e4f1;
    margin-top: 24px;
    margin-bottom: 16px;
  ;
    padding: 24px 0;
    background-color: #ffffff;
  }

  .form-content {
    padding-right: 0px;
  }

  .title-renewal {
    color: #4a61e3;
    font-size: 30px;

    height: 58.8px;
    margin: 0 0 24px;
    font-family: Inter;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .danger-title {
    color: #eb5757;
    font-size: 30px;
    font-weight: bold;
  }

  .sub-title {
    color: #6b75b2;
    font-size: 18px;
    width: 354px;
    margin-bottom: 0px;
  }
}

.christmas-tree {
  background-color: grey;
  border: 1px solid black;
  color: wheat
}

.form-headers {
  padding: 0 49.8px 0 64px;
  border-radius: 12px;
}

.success-header {
  padding: 0 49.8px 0 64px;
  border-radius: 12px;
  background-color: #ebedf9;
  background-image: url('../../assets/images/illustration-validation.svg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.danger-header {
  padding: 0 49.8px 0 64px;
  border-radius: 12px;
  background-color: #ebedf9;
  background-image: url('../../assets/images/illustration-delete.svg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.elivie-logo-white {
  background-image: url('../../assets/images/LOGO_ELIVIEW_RVB_3.png');
  padding: 0 49.8px 0 64px;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-position: bottom;
}

.elivie-logo-renewal {
  background-image: url('../../assets/images/LOGO_ELIVIEW_RVB.png');
  padding: 0 49.8px 0 64px;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
  top: -20px;
}

sup.required {
  color: red
}

.is-invalid {
  input {
    color: #eb5757;
    border: 3px solid #eb5757;
    background-image: none !important;
  }

  // CSS rules for Bootstrap feedback
  .invalid-feedback {
    color: #eb5757 !important;
    font-weight: bold !important;
  }
}

// Radio button become button
div[role=radiogroup] {
  :hover {
    background-color: transparent !important;
    color: black;
  }

  label {
    &.active {
      background-color: #4a61e3 !important;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-select {
  background: #fff url('../../assets/images/icons/Icons_Dropdown.svg') no-repeat right 0.75rem center/24px 24px !important;
  height: calc(2em + 0.75rem + 6px);
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.pathology-badge {
  border-color: #e4e6ed;
  border-radius: 6px;
  background-color: #e4e6ed;
  color: black;
  padding: 8px 10px;

  i {
    line-height: 1.5;
  }

  span {
    margin-left: 15px;
    font-size: 18px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.tag {
  border-radius: 4px;
  background-color: #ebedf9;
  color: #4a61e3;
  object-fit: contain;
  margin: 5px;
  padding: 7px 12px;

  span {
    padding: 5px;
  }
}

.vue-ask-for-care-patho {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  border: 1px solid #e4e6ed;
  border-radius: 50%;
  padding: 10px;
  background: #e4e6ed;
  width: 36px;
  height: 36px;
}

.hidden {
  visibility: hidden;
}

.submit-button {
  background: #4a61e3;
  color: #fafafa;
  border-radius: 6px;
  margin-top: 20px;
}

.danger-button {
  background: #eb5757;
  color: #fafafa;
  border-radius: 6px;
  border-color: #eb5757;
  margin-top: 20px;
}

.transparent-button {
  background: transparent;
  color: #4a4f62;
  border: none;
  margin-top: 20px;
}

.back-button-renewal {
  border-radius: 6px;
  margin-top: 20px;
  position: relative;
  top: -10px;
}

.page-nav {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1030; // The navbar is 1030
}

.radio-buttons {
  .custom-radio {
    &.inactive {
      color: #9d9d9d;
      border: solid 1px #cdcdcd;
      background-color: white;
    }

    color: #4a61e3;
    border-color: #4a61e3;
    border: 1px solid;
    border-radius: 4px;
    margin: 0 15px 9px 0;
    padding: 11px 16px;
    background-color: #ebedf9;

    input {
      position: initial;
    }
  }
}

.input-placeholders-right {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  align-content: center;
  justify-content: center;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
  border-radius: 6px;
  border: solid 1px;
  padding: 0.75rem;
  height: initial;
}

.placeholder {
  align-self: center;
  position: absolute;
  right: 15px;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.42px;
  color: #777777;
  z-index: 3;
}

.deleted-date {
  color: rgb(235, 92, 80);
}

.current-treatment {
  cursor: pointer;
  padding: 10px;
  background-color: #e4e6ed;
  border-color: #e4e6ed;
  border-radius: 6px;

  &:hover {
    background-color: rgb(183, 189, 209);
  }
}
</style>
