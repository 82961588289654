<template>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          {{ $t("philipsPdf.generate") }}
        </h3>
      </div>

      <form @submit.prevent="submit">
        <div class="modal-body form-group">
          <div>
            <b-form-group>
              <label for="ps-report-type">{{
                $t("philipsPdf.reportType")
              }}</label>
              <b-form-select
                name="psTypeTraitement"
                id="ps-report-type"
                :options="reportTypeOptions"
                v-model="formData.psTypeTraitement"
                @change="filterPeriods"
              ></b-form-select>
            </b-form-group>
          </div>

          <div>
            <b-form-group class="col-sm-12">
              <label class="row">{{ $t("philipsPdf.selectPeriod") }}</label>
              <cm-radio-button
                name="period-selector"
                :values=fetchPeriodsForSelect
                v-model="selectedPeriod"
                @input="selectPeriod"
              ></cm-radio-button>
            </b-form-group>
          </div>

          <div>
            <b-form-group>
              <label>{{ $t("philipsPdf.startDate") }}</label>
              <b-form-input
                type="date"
                name="psDateDebut"
                id="ps-start-date"
                @input="deselectPeriod"
                v-model="formData.psDateDebut"
              ></b-form-input>
            </b-form-group>
          </div>
          <div>
            <b-form-group>
              <label for="ps-end-date">{{ $t("philipsPdf.endDate") }}</label>
              <b-form-input
                type="date"
                name="psDateFin"
                id="ps-end-date"
                @input="deselectPeriod"
                v-model="formData.psDateFin"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div v-if="!this.validPeriod" :class="{ error: !validPeriod }"> 
          <p>{{ this.rangeErrorMsg }}</p>
        </div>
        <div class="form-group">
          <div class="col-sm-6 col-sm-offset-2">
            <b-button type="submit" class="btn btn-primary" :disabled="!this.validPeriod">
              {{ $t("philipsPdf.generate") }}
            </b-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CmRadioButton from "@/components/UI/Inputs/CmRadioButton";

export default {
  components: {
    CmRadioButton,
  },

  props: {
    patientCode: {
      type: String,
      required: true,
    },
    constructorUrl: {
      type: String,
      required: true
    },
    reportTypes: {
      type: String,
      required: true,
    },
    periods: {
      type: String,
      required: true,
    },
    options: {
      type: String,
      required: false
    }
    
  },

  data() {
    // Today -1 day
    const defaultStep = JSON.parse(this.options)?.periods?.default_step || 'month';
    const defaultPeriod = JSON.parse(this.options)?.periods?.default_value[defaultStep] || 3;
    const oneDay = 24 * 60 * 60 * 1000;
    const endDate = new Date(Date.now() - oneDay);
    const startDate = this.calculateStartDate(endDate, defaultPeriod);
    
    // const _options =  JSON.parse(this.options);

    return {
      _options: JSON.parse(this.options),
      baseDate: endDate,
      selectedPeriod: { value: defaultPeriod },
      selectedStep: defaultStep,
      periodsForSelect: this.generateLabeledOptions(this.periods, defaultStep),
      reportTypeOptions: this.generateOptions(this.reportTypes),
      periodOptions: this.generateLabeledOptions(this.periods),
      rangeErrorMsg: '',
      
      formData: {
        psUUIDPatient: this.patientCode,
        psDateDebut: this.toHTMLDate(startDate),
        psDateFin: this.toHTMLDate(endDate),
        psTypeTraitement: JSON.parse(this.options).report_types.default_value,
      },
    };
  },

  computed: {
    fetchPeriodsForSelect() {
      const scope = this.selectedStep
      this.periodsForSelect = this.generateLabeledOptions(this.periods, scope)
      const a = this.periodsForSelect

      return a
    },
    validPeriod() {
      // // type
      // this.formData.psTypeTraitement
      // limites
      let reportRange = JSON.parse(this.options)?.report_types[this.formData.psTypeTraitement]?.max_value
      if (this.formData.psDateDebut && this.formData.psDateFin && reportRange) {
        const difference = this.dateDifference(new Date(this.formData.psDateDebut), new Date(this.formData.psDateFin));
        let max = null || reportRange

        this.rangeErrorMsg = this.$t("resmedPdf.caution", {nbDays: max})
        return difference >= 0 && difference <= max;

      } else {
        return true;
      }
    },
  },

  methods: {
    dateDifference(firstDate, secondDate) {
      return Math.round((secondDate - firstDate)/(1000 * 60 * 60 * 24));
    },
    selectPeriod(data) {
      // When user changes start date then changes the report type. The start date computation turn into wrong date because date.value is null
      if(!data.value) {
        return true
      }
         
      const newStartDate = this.calculateStartDate(this.baseDate, data.value);
      this.formData.psDateDebut = this.toHTMLDate(newStartDate);
      this.formData.psDateFin = this.toHTMLDate(this.baseDate);
    },

    toHTMLDate(date) {
      const day = date.getDate().toString().padStart(2, "0");
      // months are 0-indexed
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    },

    calculateStartDate(endDate, timeOffset) {
       if(this.selectedStep == 'week') {
                    return new Date(
                                      endDate.getFullYear(),
                                      endDate.getMonth() ,
                                      endDate.getDate() - (timeOffset*7)
                                    );
                    }
      
          return new Date(
                            endDate.getFullYear(),
                            // 3 months ago by default
                            endDate.getMonth() - timeOffset,
                            endDate.getDate() + 1
                            // +1 day because resmed mont is 30d and 1y = 365d
                          );
    },

    deselectPeriod() {
      this.selectedPeriod = { value: null };
    },

    async submit() {
      try {
        const response = await this.$axios({
          url: this.constructorUrl,
          method: "get",
          responseType: "blob",
          params: this.payload(this.formData),
        });

        const data = response.data;
        const contentDisposition = response.headers["content-disposition"];
        const match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
        const filename = match[1];

        let blob = new Blob([data], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }

        const url = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(url);
        }, 100);
      } catch (e) {
        if (e.isAxiosError) {
          const error = JSON.parse(await e.response.data.text());
          this.$toasted.error(error.message, { duration: 3000 });
        } else {
          this.$toasted.error("Une erreur est survenue.", { duration: 3000 });
        }
      }
    },

    // The options are sent by parent compo with the i18n key so we create a vue js options array.
    generateOptions(options) {
      let a = JSON.parse(options)
      a.forEach(option=> option.text= this.$t(option.i18nKey) )
      return a
    },
    generateLabeledOptions(options, step = 'month') {
      let a = JSON.parse(options)
      if(a[step]){
        a[step].forEach(option=> option.label= this.$t(option.i18nKey, {count: option.value}) )
        return a[step]
      }

      a.forEach(option=> option.label= this.$t(option.i18nKey, {count: option.value}) )
        return a

    },
    payload() {

    const opt = JSON.parse(this.options)
    const constructorBasedPayload = {
      [opt.constructor_payload.patient_id]:   this.formData.psUUIDPatient,
      [opt.constructor_payload.start_date]:   this.formData.psDateDebut,
      [opt.constructor_payload.end_date]:     this.formData.psDateFin,
      [opt.constructor_payload.report_type]:  this.formData.psTypeTraitement
    }

    // TO débug :) 
      return constructorBasedPayload
    },

    filterPeriods(value) {
      let selectedReportType = JSON.parse(this.reportTypes).filter( type => type.value == value)
      this.selectedStep = selectedReportType[0].scope
      let p = JSON.parse(this.options)?.periods?.default_value[this.selectedStep] || this.selectedPeriod.value
      this.selectedPeriod = {value: p}
      this.selectPeriod(this.selectedPeriod)
      return this.generateLabeledOptions(this.periods, this.selectedStep)
    }
  },
};
</script>

<style scoped>
  button.disabled {
    cursor: not-allowed;
    filter: invert(100%);
  }

  .error {
    color: red;
  }
  .error p {
      padding: 0px 20px 0px 20px;
    }
</style>