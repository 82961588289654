import {
  isBoolean,
  isInteger,
  isNotEmptyString,
  emptyOrValid
} from '@/utils/validate.js'
import { selectFeedback, numberFeedback } from '@/utils/feedbacks.js'

export default {
  namespaced: true,
  state() {
      const defaults = {
        additional_info: '',
        ai_max: '',
        ai_min: '',
        ald_on_list: false,
        frequency: '',
        humidifier: false,
        interface: null,
        mask_type: 'nasal',
        mode_vni: 'st',
        pep_auto_max: '',
        pep_auto_min: '',
        pep: '',
        pip: '',
        pip_max: '',
        pip_min: '',
        slope: '',
        ti_fixed: '',
        ti_max: '',
        ti_min: '',
        vni_time_of_use: '< 12H',
        trigger_exp: '',
        trigger_insp: '',
        volume: '',
        with_ald: true
    }

    return {
      defaults,
      initial: Object.assign({}, defaults),
      data: Object.assign({}, defaults),
      activeFields: [],
      deleted: false,
      deletedAt: null,
      isValid: true,
      dataBadges: {},
      validations: {
        ald_on_list: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return isBoolean(val)
          }
        },
        ai_max: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return emptyOrValid(val)
          }
        },
        ai_min: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return emptyOrValid(val)
          }
        },
        frequency: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return emptyOrValid(val)
          }
        },
        humidifier: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return val.length > 0 ? isNotEmptyString(val) : true
          }
        },
        interface: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return ['mask', 'tracheotomy'].includes(val)
          }
        },
        mask_type: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return val.length > 0 ? isNotEmptyString(val) : true
          }
        },
        mode_vni: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return val && val.length > 0 ? isNotEmptyString(val) : true
          }
        },
        pep_auto_max: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return emptyOrValid(val)
          }
        },
        pep_auto_min: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return emptyOrValid(val)
          }
        },
        pep: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return emptyOrValid(val)
          }
        },
        pep_min: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return emptyOrValid(val)
          }
        },
        pep_max: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return emptyOrValid(val)
          }
        },
        pip: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return emptyOrValid(val)
          }
        },
        pip_max: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return emptyOrValid(val)
          }
        },
        pip_min: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return emptyOrValid(val)
          }
        },
        slope: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return val.length > 0 ? isInteger(val) : true
          }
        },
        ti_fixed: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return emptyOrValid(val)
          }
        },
        ti_max: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return emptyOrValid(val)
          }
        },
        ti_min: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return emptyOrValid(val)
          }
        },
        trigger_exp: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return true
          }
        },
        trigger_insp: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return true
          }
        },
        volume: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return val.length > 0 ? isInteger(val) : true
          }
        },
        with_ald: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return val.length > 0 ? isNotEmptyString(val) : true
          }
        },
      }
    }
  },

  getters: {
    formData(state) {
      return Object.assign({}, state.data)
    }
  },

  mutations: {
    update(state, { key, value, validate = true }) {
      state.data[key] = value

      if (validate && state.validations[key]) {
        // If validating this field should also fire validations on another field, run these validations also.
        const validations = [
          [state.validations[key], value], ...(
          (state.validations[key].dependsOn || []).map(dependency => [state.validations[dependency], state.data[dependency]])
          )
        ]

        validations.forEach(validation => {
          validation[0].isValid = validation[0].validate(state.data, validation[1])
        });
      }
    },

    delete(state) {
      state.deleted = true
      state.deletedAt = new Date().toLocaleString('fr-Fr').substr(0, 10)
    },

    setInitial(state, data) {
      const validationsKeys = Object.keys(state.validations)
      for (const key of validationsKeys) {
        state.validations[key].isValid = true
      }
      state.initial = Object.assign({}, data)
    },

    reset(state, { key }) {
      state.data[key] = state.initial[key]
    },

    updateActiveFields(state, { fieldNames, active }) {
      if (active) {
        state.activeFields.push(...fieldNames)
      } else {
        for (const fieldName of fieldNames) {
          const index = state.activeFields.indexOf(fieldName)

          if (index !== -1) {
            state.activeFields.splice(index, 1)
          }
        }
      }
    },

    setValidStateFor(state, { key, value }) {
      state.validations[key].isValid = value
    },

    setValidState(state, value) {
      state.isValid = value
    },

    setDataBadges(state, value) {
      state.dataBadges = value
    }
  },

  actions: {

    validate({ state, commit }) {
      let globalValidationStateHasChanged = false
      for (const key in state.validations) {
        // Skip fields that are not currently active
        if (state.activeFields.indexOf(key) === -1) continue;

        const newState = state.validations[key].validate(state.data, state.data[key])

        // No need to update if it did not change
        if (newState !== state.validations[key].isValid) {
          commit('setValidStateFor', { key, value: newState })
        }

        // If we are in the middle of a validation process and current state is already invalid,
        // then we shouldn't update it.
        if (globalValidationStateHasChanged && !state.isValid) continue;

        globalValidationStateHasChanged = true
        // No need to update if it did not change
        if (state.isValid !== state.validations[key].isValid) {
          commit('setValidState', state.validations[key].isValid)
        }
      }
    },

    loadFromTreatment({ state, commit }, treatment) {
      commit('setInitial', treatment)
      const keys = Object.keys(state.defaults);

      for (const key of keys) {
        const value = (treatment[key] === undefined || treatment[key] === null) ? state.defaults[key] : treatment[key]
        commit('update', { key, value, validate: false })
      }
    },

  }
}
