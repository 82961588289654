<template>
  <cm-select
    :values="computedValues"
    :value="value"
    :valid="valid"
    :feedback="feedback"
    :name="name"
    :label="label"
    :options="options"
    @input="onInput"
  ></cm-select>
</template>

<script>
import CmSelect from '@/components/UI/CmSelect'
import ValidationsMixin from '@/mixins/ValidationsMixin'
import DebounceMixin from '@/mixins/DebounceMixin'

export default {
  mixins: [
    ValidationsMixin,
    DebounceMixin
  ],
  components: {
    CmSelect
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: function() { return {} },
    },
    values: {
      type: Array,
      default: function () {
        return [];
      },
    },
    value: {
      default: () => null
    }
  },

  data() {
    return {
      url: this.options.url,
      watchedFields: this.options.watch_fields,
      watchedStore: this.options.watch_source,
      subscribers: [],
      fetchedValues: []
    }
  },

  destroyed() {
    // Unsubscribe all subscribers.
    this.subscribers.forEach(subscriber => subscriber())
  },

  mounted() {
    // subscribe returns a callback used to unsubscribe.
    // Store the references to unsubscribe when needed (see destroyed() hook)
    this.subscribers.push(
      this.$store.subscribe((mutation, state) => {
        if (
          mutation.type === `${this.watchedStore}/update`
          && this.watchedFields.includes(mutation.payload.key)
        ) {
          this.debounce(this.callApi, 500)
        }
      })
    )

    this.callApi()
  },

  computed: {
    computedValues() {
      return this.values.concat(this.fetchedValues)
    },
  },

  methods: {
    async callApi() {
      try {
        const { data } = await this.$axios.get(this.url, { params: this.params() })

        this.fetchedValues = data.device_packages.map(
          (device) => ({
            label: device.device_label, value: device.device_label
          })
        )

        if (this.fetchedValues.length === 0) {
          this.$toasted.error("Les caractéristiques demandées ne correspondent à aucun matériel", { duration: 5000 })
        }
      } catch(e) {
        switch(e.response.status) {
          case 400:
            // Ignore bad requests
            break;
          default:
            this.$toasted.error(e.response.message)
        }
      }
    },

    params() {
      let params = {}

      for (const field of this.watchedFields) {
        params[field] = this.$store.state[this.watchedStore].data[field]
      }

      return params
    },

    // Forward input event
    onInput(data) {
      this.$emit('input', data)
    }
  },
}
</script>
