<template>
  <div class="success-screen main">
    <div class="wrapper container-fluid d-flex flex-column align-items-center">
      <nav class="success-nav col-sm-12 d-flex p-0 justify-content-between">
        <div class="elivie-logo-white"></div>
        <b-button href="/" class="back-button">
          Retour à l'accueil Eliview
        </b-button>
      </nav>

      <section class="col-sm-11 d-flex flex-column align-items-center">
        <div
          class="bloc flex-row justify-content-center row col-sm-10 success-header"
        >
          <div class="col-sm-8 title text-center">
            <p>Merci, votre demande a bien été transmise à votre agence.</p>
          </div>
          <div class="col-sm-12 sub-title text-center">
            <p>Gagnez du temps, générez la prescription au format électronique</p>
          </div>
          <div class="col-sm-12 d-flex justify-content-center">
            <b-button
              :href="link"
              target="_blank"
              class="submit-button"
              @click="sendPrescription()"
              >Générer la prescription électronique</b-button>
          </div>
        </div>
        <div class="bloc flex-row row col-sm-10 center">
          <div class="col-sm-4">
            <h5 class="text-is-semibold">
              <span class="rounded-number">1</span> Identité du patient
            </h5>
          </div>
          <div class="col-sm-8">
            <b-badge
              class="tag"
              v-for="item in this.$store.getters.validMainData"
              :key="item"
            >
              {{ item }}
            </b-badge>
          </div>
        </div>
        <div class="bloc flex-row row col-sm-10">
          <div class="col-sm-12">
            <h5 class="text-is-semibold">
              <span class="rounded-number">2</span> Prescription
            </h5>
          </div>

          <section
            class="align-items-start ml-0 divider col-sm-12 vue-ask-for-care-patho flex-row row"
            v-if="pathologies.includes('ppc')"
          >
            <div class="col-sm-4">
              <div class="d-flex">
                <span class="text-is-semibold pathology-badge">
                  {{ $t("askForCare.ppc.ppc") }}
                </span>
              </div>
            </div>
            <div class="col-sm-8">
              <b-badge
                class="tag"
                v-for="item in this.$store.getters.validPpcData"
                :key="item"
              >
                {{ item }}
              </b-badge>
            </div>
          </section>

          <section
            class="align-items-start ml-0 divider col-sm-12 vue-ask-for-care-patho flex-row row"
            v-if="pathologies.includes('o2')"
          >
            <div class="col-sm-4">
              <div class="d-flex">
                <span class="text-is-semibold pathology-badge">
                  {{ $t("askForCare.o2.o2") }}
                </span>
              </div>
            </div>
            <div class="col-sm-8">
              <b-badge
                class="tag"
                v-for="item in this.$store.getters.validO2Data"
                :key="item"
              >
                {{ item }}
              </b-badge>
            </div>
          </section>

          <section
            class="align-items-start ml-0 divider col-sm-12 vue-ask-for-care-patho flex-row row"
            v-if="pathologies.includes('vni')"
          >
            <div class="col-sm-4">
              <div class="d-flex">
                <span class="text-is-semibold pathology-badge">
                  {{ $t("askForCare.vni.vni") }}
                </span>
              </div>
            </div>
            <div class="col-sm-8">
              <b-badge
                class="tag"
                v-for="item in this.$store.getters.validVniData"
                :key="item"
              >
                {{ item }}
              </b-badge>
              <b-form-checkbox v-if="renewal" v-model="checked" name="check-buttonn" switch>
                Renouveler la prescription chez le même prestataire de service
              </b-form-checkbox>
            </div>
          </section>
          <section class="col-sm-12">
            <b-button
              :href="link"
              target="_blank"
              class="bottom-page-button submit-button"
              @click="sendPrescription()"
            >
              Générer la prescription électronique
            </b-button>
            <b-button
              href="/"
              variant="outline"
              class="bottom-page-button back-button"
            >
              Retour à l'accueil Eliview
            </b-button>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true
    },
    pathologies: {
      type: Array,
      required: true
    },
    renewal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: false,
    }
  },
  created() {
    $("#ask-for-care-nav").addClass("hidden");
  },
  methods: {
    sendPrescription() {
      this.$store.commit("update_prescription_sent", true);
    },
  },
  mounted() {
    $("html, body").addClass("disable-scroll");
  },

  destroyed() {
    $("html, body").removeClass("disable-scroll");
  },
};
</script>

<style lang="scss">
.success-screen {
  &.main {
    overflow-y: auto;
    background: rgba(33, 33, 33, 0.85);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1031; // The navbar is 1030
  }

  .back-button {
    background-color: #2c2f40;
    border-color: #2c2f40;
    color: #fafafa;

    &.bottom-page-button {
      color: #4a4f62;
      font-weight: 600;
      background-color: white;
      border-color: white;
    }
  }

  .bottom-page-button {
    padding: 12px 16px;
  }

  .success-nav {
    color: white;
  }

  .success-header {
    .title {
      color: #4a61e3;
      font-weight: 600;
      font-size: 28px;
      width: initial;
    }

    .sub-title {
      color: #6b75b2;
      font-size: 18px;
      font-weight: 500;
      width: initial;
    }
  }
}
</style>
