<template>
  <div class="success-screen main">
    <div class="wrapper container-fluid d-flex flex-column align-items-center">
      <nav class="success-nav col-sm-12 d-flex p-0 justify-content-between">
        <div class="elivie-logo-white"></div>
        <b-button :href="backLink" class="back-button">
          Retour à la fiche patient
        </b-button>
      </nav>

      <section class="col-sm-11 d-flex flex-column align-items-center">
        <div
          class="bloc flex-row justify-content-center row col-sm-10 success-header"
        >
          <div class="col-sm-8 title text-center">
            <p>Merci, votre demande a bien été transmise à votre agence.</p>
          </div>
          <template v-if="!onlyDeleted">
            <div class="col-sm-12 sub-title text-center">
              <p>Gagnez du temps, générez la prescription au format électronique</p>
            </div>
            <div class="col-sm-12 d-flex justify-content-center">
              <b-button
                :href="link"
                v-if="!errored"
                target="_blank"
                class="submit-button-renewal-success-screen"
                @click="sendPrescription()"
              >
                Générer la prescription électronique
              </b-button>
            </div>
          </template>
        </div>
        <div class="bloc flex-row row col-sm-10 center">
          <div class="col-sm-4">
            <h5 class="text-is-semibold">
              <span class="rounded-number">1</span> Identité du patient
            </h5>
          </div>
          <div class="col-sm-8">
            <b-badge
              class="tag"
              v-for="item in patientBadges"
              :key="item"
            >
              {{ item }}
            </b-badge>
          </div>
        </div>
        <div class="bloc flex-row row col-sm-10">
          <div class="col-sm-12">
            <h5 class="text-is-semibold">
              <span class="rounded-number">2</span> Prescription
            </h5>
          </div>

          <section
              class="align-items-start ml-0 divider col-sm-12 vue-ask-for-care-patho flex-row row"
              v-for="pathology of pathologiesCheckedNotDeleted"
          >
            <div class="col-sm-4">
              <div class="d-flex">
                <span class="text-is-semibold pathology-badge">
                  {{ $t(`askForCare.${pathology}.${pathology}`) }}
                </span>
              </div>
            </div>
            <div class="col-sm-8">
              <b-badge
                class="tag"
                v-for="item in $store.state[`${pathology}Renewal`].dataBadges"
                :key="item"
              >
                {{ item }}
              </b-badge>
            </div>
          </section>

          <section
              class="align-items-start ml-0 divider col-sm-12 vue-ask-for-care-patho flex-row row"
              v-for="pathology of pathologiesCheckedAndDeleted"
          >
            <div class="col-sm-4">
              <div class="d-flex" :id="`deleted-${pathology}-badge`">
                  <span class="text-is-semibold pathology-badge" >
                    {{ $t(`askForCare.${pathology}.${pathology}`) }}

                    <cm-tooltip :text="`Demande d’arrêt du traitement le ${$store.state[`${pathology}Renewal`].deletedAt}`"></cm-tooltip>
                  </span>
              </div>
            </div>
          </section>
          <section class="align-items-start ml-0 divider col-sm-12 vue-ask-for-care-patho flex-row row">
            <div class="col-sm-6">
              <template v-if="!onlyDeleted">
                <b-button
                  :href="link"
                  v-if="!errored"
                  target="_blank"
                  class="submit-button-renewal-success-screen"
                  @click="sendPrescription()"
                >
                  Générer la prescription électronique
                </b-button>
              </template>
            </div>
            <b-button
              :href="backLink"
              variant="outline"
              class="bottom-back-button"
            >
              Retour à la fiche patient
            </b-button>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import CmTooltip from '@/components/UI/CmTooltip'

export default {
  components: {
    CmTooltip,
  },
  props: {
    prescriptionIds: {
      type: Array,
      required: true
    },
    pathologies: {
      type: Array,
      required: true
    },
    renewal: {
      type: Boolean,
      default: false,
    },
    backLink: {
      type: String,
      required: true
    },
    patientBadges: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    }
  },
  data() {
    return {
      errored: false,
      checked: false,
      link: '#',
    }
  },

  computed: {
    pathologiesCheckedNotDeleted() {
      return this.pathologies.filter((pathology) => !this.deletedPathologies().includes(pathology))
    },
    pathologiesCheckedAndDeleted() {
      return this.pathologies.filter((pathology) => this.deletedPathologies().includes(pathology))
    },
    onlyDeleted() {
      return this.pathologiesCheckedAndDeleted.length === this.pathologies.length
    }
  },

  created() {
    $("#ask-for-care-nav").addClass("hidden");
  },

  mounted() {
    $("html, body").addClass("disable-scroll");

    this.$axios.post('/api/v1/prescriptions/redirect', {
      prescription_ids: this.prescriptionIds
    }).then(response => {
      const { data } = response
      this.link = data.redirect_to
    }).catch(error => {
      this.errored = true
    })

  },

  destroyed() {
    $("html, body").removeClass("disable-scroll");
  },
  methods: {
    sendPrescription() {
      this.$store.commit("update_prescription_sent", true);
    },
    deletedPathologies() {
      let deleted = []
      for (const pathology of this.pathologies) {
        if (this.$store.state[`${pathology}Renewal`].deleted) {
          deleted.push(pathology)
        }
      }
      return deleted
    }
  },
};
</script>

<style lang="scss">
.success-screen {
  &.main {
    overflow-y: auto;
    background: rgba(33, 33, 33, 0.85);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1031; // The navbar is 1030
  }

  .back-button {
    background-color: #2c2f40;
    border-color: #2c2f40;
    color: #fafafa;

    &.bottom-page-button {
      color: #4a4f62;
      font-weight: 600;
      background-color: white;
      border-color: white;
    }
  }

  .submit-button-renewal-success-screen {
    background: #4a61e3;
    color: #fafafa;
    border-radius: 6px;
  }

  .bottom-back-button {
    left: -100px;
    position: relative;
  }

  .bottom-page-button {
    padding: 12px 16px;
  }

  .success-nav {
    color: white;
  }

  .success-header {
    .title {
      color: #4a61e3;
      font-weight: 600;
      font-size: 28px;
      width: initial;
    }

    .sub-title {
      color: #6b75b2;
      font-size: 18px;
      font-weight: 500;
      width: initial;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #4a61e3;
  }

  .custom-switch.b-custom-control-lg .custom-control-label {
    font-size: 1rem;
  }
}
</style>
