<template>
  <b-form-group
    class="cm-select"
    :state="shouldValidate"
    :invalid-feedback="invalidFeedback"
  >
    <label class="row">
      {{ label }}
      <i v-if="options.info" class="info-icon fas fa-info-circle" :title="options.info"></i>
      <sup v-if="options.required" class="required"> requis</sup>
    </label>
    <b-form-select
      class="row"
      :options="properties"
      :value="selectedValue"
      :state="shouldValidate"
      @input="onInput"
    >
    </b-form-select>
  </b-form-group>
</template>

<script>
import ValidationsMixin from "@/mixins/ValidationsMixin";

export default {
  mixins: [ValidationsMixin],
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: function() { return {} },
    },
    values: {
      type: Array,
      default: function () {
        return [];
      },
    },
    value: {
      default: () => null
    }
  },

  destroyed() {
    this.$emit('destroyed', { name: this.name })
  },

  data() {
    return {
      selectedValue: this.value || this.options.default
    }
  },

  computed: {
    properties() {
      return this.values.map((value) => {
        return { value: value.value, text: value.label, disabled: value.disabled }
      })
    },
  },

  methods: {
    onInput(data) {
      this.dirty = true
      this.$emit('input', { name: this.name, value: data })
    }
  }
}
</script>

<style lang="scss">
.cm-select {
  .info-icon {
    align-self: center;
    margin-left: 5px;
  }

  .custom-select {
    background: #ffffff;
    border-radius: 6px;
    border: solid 1px rgb(220, 220, 220);

    &:focus {
      background: #ffffff;
      border: solid 1px rgb(220, 220, 220);
    }

    &.is-invalid {
      color: #eb5757;
      border: 3px solid #eb5757;
    }
  }
}
</style>
