import {
  emptyOrValid,
  isNotEmptyString,
  onlyThreeDigitsOneDecimal,
  onlyTwoDigitsOneDecimal,
  isBoolean
} from '@/utils/validate.js'
import { selectFeedback, numberFeedback } from '@/utils/feedbacks.js'

export default {
  namespaced: true,
  state() {
    const defaults = {
      additional_info: '',
      ai_max: '',
      ai_min: '',
      ald_on_list: false,
      frequency: '',
      humidifier: false,
      iah: null,
      ia: null,
      iah_symptoms: [],
      iah_other_symptoms: '',
      p_auto_max: '',
      p_auto_min: '',
      p_fixed: '',
      pep_auto_max: '',
      pep_auto_min: '',
      pep: '',
      ppc_type: 'ppc',
      pressure_type: 'fixed',
      with_ald: false,

      symptoms_minimum: 0,
      age: 0,
      eordo_mandatory_fields: false
    }

    return {
      defaults,
      initial: Object.assign({}, defaults),
      data: Object.assign({}, defaults),
      activeFields: [],
      deleted: false,
      deletedAt: null,
      isValid: true,
      dataBadges: {},
      validations: {
        ai_max: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return emptyOrValid(val)
          }
        },
        ai_min: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return emptyOrValid(val)
          }
        },
        ald_on_list: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return !data.with_ald || isBoolean(val)
          }
        },
        frequency: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return emptyOrValid(val)
          }
        },
        humidifier: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return val.length > 0 ? isBoolean(val) : true
          }
        },
        iah: {
          isValid: true,
          feedback: (data) => {
            const correctDigit = onlyThreeDigitsOneDecimal(data.iah)
            const moreThan0 = data.iah > 0

            if(data.ia !== null && data.ia !== '') {
              return "IA et IAH ne peuvent être remplis simultanément."
            } else if (!moreThan0) {
              return 'Entrez un chiffre supérieur à 0.0'
            } else if(!correctDigit) {
              return 'Entrez un nombre inférieur à 1000'
            }
          },
          validate: (data, val) => {
            const correctDigit = onlyThreeDigitsOneDecimal(val)
            const moreThan0 = val > 0

            if (val === '' || val === null) {
              if (data.ia !== null && data.ia !== '') {
                return true
              } else {
                return !data.eordo_mandatory_fields
              }
            } else {
              if (data.ia === null || data.ia === '') {
                return correctDigit && moreThan0
              } else {
                return false
              }
            }
          },
          dependsOn: ['ia']
        },
        ia: {
          isValid: true,
          feedback: (data) => {
            const correctDigit = onlyThreeDigitsOneDecimal(data.ia)
            const moreThan0 = data.ia > 0

            if(data.iah !== null && data.iah !== '') {
              return "IA et IAH ne peuvent être remplis simultanément."
            } else if (!moreThan0) {
              return 'Entrez un chiffre supérieur à 0.0'
            } else if(!correctDigit) {
              return 'Entrez un nombre inférieur à 1000'
            }
          },
          validate: (data, val) => {
            const correctDigit = onlyThreeDigitsOneDecimal(val)
            const moreThan0 = val > 0

            if (val === '' || val === null) {
              if (data.iah !== null && data.iah !== '') {
                return true
              } else {
                return !data.eordo_mandatory_fields
              }
            } else {
              if (data.iah === null || data.iah === '') {
                return correctDigit && moreThan0
              } else {
                return false
              }
            }
          },
          dependsOn: ['iah']
        },
        iah_symptoms: {
         isValid: true,
         feedback: 'Merci de rentrer le bon nombre de symptômes',
         validate: (data, val) => {
            if (!data.eordo_mandatory_fields && val.length === 0) {
              return true
            } else {
              return data.symptoms_minimum <= val.length || val.indexOf('other') !== -1
            }
         }
        },
        p_auto_max: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return onlyTwoDigitsOneDecimal(val)
          }
        },
        p_auto_min: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return onlyTwoDigitsOneDecimal(val)
          }
        },
        p_fixed: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return onlyTwoDigitsOneDecimal(val)
          }
        },
        pep_auto_max: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return onlyTwoDigitsOneDecimal(val)
          }
        },
        pep_auto_min: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return onlyTwoDigitsOneDecimal(val)
          }
        },
        pep: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return onlyTwoDigitsOneDecimal(val)
          }
        },
        ppc_type: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return val.length > 0 ? isNotEmptyString(val) : true
          }
        },
        pressure_type: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return isNotEmptyString(val)
          }
        },
        with_ald: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return isBoolean(val)
          }
        },
      }
    }
  },

  getters: {
    formData(state) {
      const data = Object.assign({}, state.data)
      delete data.age
      delete data.symptoms_minimum
      delete data.eordo_mandatory_fields
      return data
    }
  },

  mutations: {
    update(state, { key, value, validate = true }) {
      state.data[key] = value

      if (validate && state.validations[key]) {
        // If validating this field should also fire validations on another field, run these validations also.
        const validations = [
          [state.validations[key], value], ...(
          (state.validations[key].dependsOn || []).map(dependency => [state.validations[dependency], state.data[dependency]])
          )
        ]

        validations.forEach(validation => {
          validation[0].isValid = validation[0].validate(state.data, validation[1])
        });
      }
    },

    delete(state) {
      state.deleted = true
      state.deletedAt = new Date().toLocaleString('fr-Fr').substr(0, 10)
    },

    setInitial(state, data) {
      const validationsKeys = Object.keys(state.validations)
      for (const key of validationsKeys) {
        state.validations[key].isValid = true
      }
      state.initial = Object.assign({}, data)
    },

    reset(state, { key }) {
      state.data[key] = state.initial[key]
    },

    setValidStateFor(state, { key, value }) {
      state.validations[key].isValid = value
    },

    setValidState(state, value) {
      state.isValid = value
    },

    updateActiveFields(state, { fieldNames, active }) {
      if (active) {
        state.activeFields.push(...fieldNames)
      } else {
        for (const fieldName of fieldNames) {
          const index = state.activeFields.indexOf(fieldName)

          if (index !== -1) {
            state.activeFields.splice(index, 1)
          }
        }
      }
    },

    setDataBadges(state, value) {
      state.dataBadges = value
    }
  },

  actions: {
    validate({ state, commit }) {
      let globalValidationStateHasChanged = false
      for (const key in state.validations) {
        // Skip fields that are not currently active
        if (state.activeFields.indexOf(key) === -1) continue;

        const newState = state.validations[key].validate(state.data, state.data[key])

        // No need to update if it did not change
        if (newState !== state.validations[key].isValid) {
          commit('setValidStateFor', { key, value: newState })
        }

        // If we are in the middle of a validation process and current state is already invalid,
        // then we shouldn't update it.
        if (globalValidationStateHasChanged && !state.isValid) continue;

        globalValidationStateHasChanged = true
        // No need to update if it did not change
        if (state.isValid !== state.validations[key].isValid) {
          commit('setValidState', state.validations[key].isValid)
        }
      }
    },

    loadFromTreatment({ state, commit }, treatment) {
      commit('setInitial', treatment)
      const keys = Object.keys(state.defaults);

      for (const key of keys) {
        const value = (treatment[key] === undefined || treatment[key] === null) ? state.defaults[key] : treatment[key]
        commit('update', { key, value, validate: false })
      }
    },

  }
}
