<template>
  <div class="vue-ask-for-care-patho" >
    <div class="divider col-sm-12">
      <div class="row col-sm-12 d-flex">
        <div class="col-sm-4">
          <b-button class="pathology-badge" v-b-toggle.ppc-content @click="toggleHead">
            <div class="d-flex">
              <i :class="['fas', '2x', collapseIcon]" id="toggle-ppc-icon"></i>
              <span class="text-is-semibold">{{ $t('askForCare.ppc.ppc') }}</span>
            </div>
          </b-button>
        </div>
        <div v-if="isDeleted" class="col-sm-8">
          <span class="text-is-semibold deleted-date">Demande d'arrêt du traitement le {{ $store.state.ppcRenewal.deletedAt }}</span>
        </div>
        <template v-if="(!expanded || !isEditable) && !isDeleted">
          <div class="col-sm-8 d-flex justify-content-between" >
            <section>
              <b-badge class="tag" v-for="item in dataBadges" :key="item">
                <span class="d-flex">
                  {{item}}
                </span>
              </b-badge>
            </section>
          </div>
        </template>
      </div>
    </div>

    <b-collapse id="ppc-content" :visible="isEditable" class="container">
      <template v-if="isEditable" v-for="group of fields">
        <cm-conditional-form-group
          :root="group.root"
          :conditions="group.group"
          :meta="group.meta"
          store-namespace="ppcRenewal"
        >
          <div class="col-sm-12">
            <div class="col-sm-12">
              <template v-for="rows of group.fields">
                <div
                  class="row"
                >
                  <template v-for="field of rows">
                    <template v-if="Array.isArray(field)">
                      <div class="col-sm-6">
                        <div
                          v-for="f of field"
                          :key="f.name"
                        >
                          <article>
                            <component
                              :is="f.type"
                              :values="f.values || []"
                              :value="data[f.name]"
                              :valid="validations[f.name] && validations[f.name].isValid"
                              :feedback="validations[f.name] && validations[f.name].feedback"
                              :feedback-data="data"
                              :name="f.name"
                              :label="f.label"
                              :options="f.options"
                              @input="onInput"
                            >
                            </component>
                          </article>
                        </div>
                      </div>
                    </template>

                    <template
                      v-else
                    >
                      <article
                        :key="field.name"
                        :class="rowClasses(field)"
                      >
                        <component
                          :is="field.type"
                          :values="field.values || []"
                          :value="data[field.name]"
                          :valid="validations[field.name] && validations[field.name].isValid"
                          :feedback="validations[field.name] && validations[field.name].feedback"
                          :name="field.name"
                          :label="field.label"
                          :options="field.options"
                          @input="onInput"
                        >
                        </component>
                      </article>
                    </template>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </cm-conditional-form-group>
      </template>
    </b-collapse>
  </div>
</template>
<script>
import IahInput from '@/components/prescriptions/IahInput'
import PpcSymptomCheckbox from '@/components/prescriptions/PpcSymptomCheckbox'
import CmApiSelect from '@/components/UI/CmApiSelect'
import CmConditionalFormGroup from '@/components/UI/CmConditionalFormGroup'
import CmAdditionalText from '@/components/UI/CmAdditionalText'
import CmCheckbox from '@/components/UI/CmCheckbox'
import CmDateInput from '@/components/UI/CmDateInput'
import CmNumberInput from '@/components/UI/CmNumberInput'
import CmTextInput from '@/components/UI/CmTextInput'
import CmRadioButton from '@/components/UI/CmRadioButton'
import CmSelect from '@/components/UI/CmSelect'
import CmTextarea from '@/components/UI/CmTextarea'
import CmUpload from '@/components/UI/CmUpload'


export default {
  components: {
    IahInput,
    PpcSymptomCheckbox,
    CmApiSelect,
    CmConditionalFormGroup,
    CmAdditionalText,
    CmCheckbox,
    CmDateInput,
    CmNumberInput,
    CmTextInput,
    CmRadioButton,
    CmSelect,
    CmTextarea,
    CmUpload
  },

  props: {
    prescription: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    patientId: {
      type: Number,
      required: true
    },
    validated: {
      type: Boolean,
      default: false,
      required: false
    },
    renewing: {
      type: Boolean,
      required: false,
      default: false
    },
    editable: {
      type: Boolean,
      required: false,
      default: true
    },
    patientAge: {
      type: Number,
      required: false,
      default: () => null
    }
  },

  data() {
    return {
      expanded: this.editable,
      hasPrescription: this.prescription.id !== undefined,
      fields: [],
      treatment: {}
    }
  },

  computed: {
    data() {
      return this.$store.state.ppcRenewal.data
    },
    validations() {
      return this.$store.state.ppcRenewal.validations
    },
    collapseIcon() {
      return this.expanded ? 'fa-angle-double-up' : 'fa-angle-double-down'
    },
    isEditable() {
      return this.editable && !this.isDeleted
    },
    isDeleted() {
      return this.$store.state.ppcRenewal.deleted
    },
    dataBadges() {
      let filterObject = {}
      let data = this.$store.state.ppcRenewal.data
      let validations = this.$store.state.ppcRenewal.validations

      if(data.with_ald !== '' && validations.with_ald && validations.with_ald.isValid) {
        filterObject['with_ald'] = 'ALD : '+ this.$t(`common.${data.with_ald}`);
      }
      if(data.with_ald === true && validations.with_ald && validations.with_ald.isValid) {
        filterObject['ald_on_list'] = 'Sur liste : '+ this.$t(`common.${data.ald_on_list}`);
      }
      if(data.iah_symptoms.length > 0 && validations.iah_symptoms && validations.iah_symptoms.isValid) {
        filterObject['iahSymptoms'] = `${data.iah_symptoms.length} symptôme(s)`;
      }

      if(data.iah !== null && validations.iah && validations.iah.isValid) {
        filterObject['iah'] = `IAH : ${data.iah}`;
      }
      if(data.ia !== null && validations.ia && validations.ia.isValid) {
        filterObject['ia'] = `IA : ${data.ia}`;
      }
      if(data.obs) {
        filterObject['obs'] = `Observance : ${data.obs}`;
      }
      if(data.iah_residual) {
        filterObject['iah_residual'] = `IAH Résiduel : ${data.iah_residual}`;
      }
      if(data.ppc_type !== '' && validations.ppc_type && validations.ppc_type.isValid) {
        filterObject['ppc_type'] = 'Type de PPC : ' + this.$t(`askForCare.ppc.${data.ppc_type}`);
      }
      if(data.pressure_type !== '' && validations.pressure_type && validations.pressure_type.isValid) {
        filterObject['pressure_type'] = 'Type de pression : '+ this.$t(`askForCare.ppc.${data.pressure_type}`);
      }
      if(data.p_fixed !== '' && data.ppc_type === 'ppc' && data.pressure_type === 'fixed' && validations.p_fixed && validations.p_fixed.isValid) {
        filterObject['p_fixed'] = `P fixe : ${data.p_fixed}`;
      }
      if(data.p_fixed !== '' && data.ppc_type === 'ppc_auto' && data.pressure_type === 'fixed' && validations.p_fixed && validations.p_fixed.isValid) {
        filterObject['pep'] = `P fixe : ${data.pep}`;
      }
      if(data.p_auto_min !== '' && data.ppc_type === 'ppc' && data.pressure_type === 'auto' && validations.p_auto_min && validations.p_auto_min.isValid) {
        filterObject['p_auto_min'] = `PEP min : ${data.p_auto_min}`;
      }
      if(data.p_auto_max !== '' && data.ppc_type === 'ppc' && data.pressure_type === 'auto' && validations.p_auto_max && validations.p_auto_max.isValid) {
        filterObject['p_auto_max'] = `PEP max : ${data.p_auto_max}`;
      }
      if(data.pep_auto_min !== '' && data.ppc_type === 'ppc_auto' && data.pressure_type === 'auto' && validations.pep_auto_min && validations.pep_auto_min.isValid) {
        filterObject['pep_auto_min'] = `PEP min : ${data.pep_auto_min}`;
      }
      if(data.pep_auto_max !== '' && data.ppc_type === 'ppc_auto' && data.pressure_type === 'auto' && validations.pep_auto_max && validations.pep_auto_max.isValid) {
        filterObject['pep_auto_max'] = `PEP max : ${data.pep_auto_max}`;
      }
      if(data.ai_min !== '' && data.ppc_type === 'ppc_auto' && validations.ai_min && validations.ai_min.isValid) {
        filterObject['ai_min'] = `AI min : ${data.ai_min}`;
      }
      if(data.ai_max !== '' && data.ppc_type === 'ppc_auto' && validations.ai_max && validations.ai_max.isValid) {
        filterObject['ai_max'] = `AI max : ${data.ai_max}`;
      }
      if(data.frequency !== '' && data.ppc_type === 'ppc_auto' && validations.frequency && validations.frequency.isValid) {
        filterObject['frequency'] = `Fréquence : ${data.frequency}`;
      }
      if(data.humidifier !== '' && validations.humidifier && validations.humidifier.isValid) {
        filterObject['humidifier'] = 'Humidificateur : '+ this.$t(`common.${data.humidifier}`);
      }

      return filterObject
    }
  },

  async mounted() {
    const url = this.hasPrescription ? `/api/v1/prescriptions/${this.prescription.id}/fields` : '/api/v1/prescriptions/fields'

    const response = await this.$axios.get(url, {
      params : {
        patient_id: this.patientId,
        renewing: this.renewing,
        only: ['ppc']
      }
    })

    const data = response.data

    this.treatment = data.ppc.treatment_data
    this.fields = data.ppc.fields

    if (this.renewing && !this.$store.state.ppcRenewal.deleted) {
      this.$emit('dirty')
    }

    this.$store.dispatch('ppcRenewal/loadFromTreatment', this.treatment)
    this.$store.commit('ppcRenewal/update', { key: 'age', value: this.patientAge })
  },

  watch: {
    editable() {
      return this.expanded = this.editable
    },
    validated() {
      if(this.validated) {
        this.$store.commit('ppcRenewal/setDataBadges', this.dataBadges)
      }
    },
  },

  methods: {
    toggleHead() {
      this.expanded = !this.expanded
    },

    onInput(data) {
      this.$store.commit('ppcRenewal/update', { key: data.name, value: data.value })
      this.$emit('dirty')
      this.$emit('input', { form: 'ppc', ...data })
    },

    rowClasses(field) {
      return [
        { [`offset-sm-${field.options.offset}`]: field.options.offset !== undefined }, `col-sm-${field.col}`
      ]
    }
  }
}
</script>
