<template>
  <div class="vue-ask-for-care-form">
    <section class="col-sm-12 form-header bloc flex-row mb-0">
      <div class="col-sm-4 ml-5">
        <p class="title text-is-semibold">Formulaire de prise en charge d'un patient par nos soins.</p>
        <p class="sub-title text-is-medium">Remplissez les informations suivantes, nous nous occupons du reste pour vous !</p>
      </div>
    </section>
    <div class="m-0 p-0 row col-sm-12">
      <section class="col-sm-3 align-items-start pl-0">
        <div class="bloc bloc-row">
          <section class="col-sm-4">
            <cm-switch-button v-model="eordoFields" name="eordoFields"></cm-switch-button>
          </section>

          <section class="col-sm-8">
            Afficher les champs requis pour la prescription
          </section>
        </div>

        <cm-follow-scroll :offset="10"  id='form-aside'>
          <cm-stepper class="pr-4 pl-4 bloc" v-model="progress">
            <cm-stepper-step label="Identité du patient"></cm-stepper-step>
              <cm-stepper-step label="Prescription">
                <b-form-group
                  id="general-pathology"
                  name="general-pathology-options"
                  class="stepper-checkbox"
                >
                  <div
                    v-for="pathology of pathologies"
                    :key="pathology.value"
                    class="cursor-pointer"
                    @click.stop="changePathologyState(pathology)"
                  >
                    <div
                      class="custom-controler-input custom-checkbox custom-control"
                      :class="{ 'is-selected': pathology.checked, 'is-disabled': pathology.disabled }"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input cursor-pointer"
                        :disabled="pathology.disabled"
                        :checked="pathology.checked"
                      />
                      <label class="cursor-pointer custom-control-label">
                        {{ pathology.text }}
                      </label>
                    </div>
                  </div>
                </b-form-group>
              </cm-stepper-step>

            <cm-stepper-step label="Récapitulatif"></cm-stepper-step>
          </cm-stepper>
        </cm-follow-scroll>
      </section>

      <section class="col-sm-9 form-content">
        <form enctype="multipart/form-data">
          <section class="bloc">
            <section class="row col-sm-12 main-title d-flex">
              <div class="col-sm-4">
                <h5 class="text-is-semibold"><span class="rounded-number">1</span>Identité du patient</h5>
              </div>
              <div v-if="!mainVisible" class="col-sm-8 d-flex justify-content-between" >
                <section>
                  <b-badge class="tag" v-for="item in $store.getters.validMainData" :key="item">
                    <span class="d-flex">
                      {{item}}
                    </span>
                  </b-badge>
                </section>
                <div>
                  <button @click="setProgress(0)" type="button" class="update-btn btn text-is-medium">
                    Modifier
                  </button>
                </div>
              </div>
            </section>

            <b-collapse id="main-content" :visible='mainVisible' class="row container">
              <div class="row col-sm-12">
                <article class="col-sm-12 radio-buttons">
                  <label>Civilité <sup class="required" v-if="eordoFields"> requis</sup></label>
                  <div class="col-sm-12 row">
                    <cm-radio-button
                      v-for="(option, index) of genderOptions"
                      :key="index"
                      :active="isActive(option.value, gender)"
                      :optionValue="option.value"
                      :text="option.text"
                      v-model="gender"
                    ></cm-radio-button>
                  </div>
                </article>

                <article class="col-sm-4">
                  <b-form-group
                    :state="lastnameVal"
                    :invalid-feedback="lastNameFeedback">
                    <label>Nom<sup class="required"> requis</sup></label>
                    <b-form-input v-model="payload.lastname" type="text" @blur="setAttr($event, 'lastname')" ></b-form-input>
                  </b-form-group>
                </article>
                <article class="col-sm-4 ">
                  <b-form-group>
                    <label>Prénom<sup class="required" v-if="eordoFields"> requis</sup></label>
                    <b-form-input v-model="payload.firstname" type="text" @blur="setAttr($event, 'firstname')" ></b-form-input>
                  </b-form-group>
                </article>

                <article class="col-sm-4"></article>

                <article class="col-sm-4">
                  <b-form-group
                    :invalid-feedback="phoneFeedback"
                    :state="phoneVal">
                    <label>Téléphone <sup class="required"> requis</sup></label>
                    <b-form-input
                      v-model.trim="payload.phone"
                      id="`general-phone`"
                      type="text"
                      @blur="setAttr($event, 'phone')"
                      v-mask="'## ## ## ## ##'">

                      <!-- placeholder='Un nombre de 10 caractères' -->

                    </b-form-input>
                  </b-form-group>
                </article>
                <article class="col-sm-4">
                  <b-form-group
                    :state="birthdateVal"
                    :invalid-feedback="birthdateFeedback"
                  >
                    <label>Date de naissance<sup class="required" v-if="eordoFields"> requis</sup></label>
                    <b-form-input
                      v-model="birthDate"
                      id="`general-birthdate`"
                      type="date"
                      :max="dateInputLimit"
                    ></b-form-input>
                  </b-form-group>
                </article>
                <article class="col-sm-4">
                  <b-form-group>
                    <label>Numéro de sécurité sociale</label>
                    <b-form-input
                      v-model="securityNumber"
                      id="`general-securitynumber`"
                      type="text" @blur="setAttr($event, 'securitynumber')"
                      >

                      <!-- placeholder="Un nombre de 15 caractères" -->
                    </b-form-input>
                  </b-form-group>

                </article>
                <article class="col-sm-4">
                  <b-form-group>
                    <label> Code postal <sup class="required"> requis</sup></label>
                    <b-form-input
                      id="general-zipcode"
                      v-model="zipcode"
                      aria-describedby="zipcode-help"
                      @blur="setAttr($event, 'zipcode')"
                    >
                    </b-form-input>
                    <b-form-text
                      id="zipcode-help"
                      v-if="displayInvalidZipcode"
                    >
                      Ce code postal semble incorrect
                    </b-form-text>
                  </b-form-group>
                </article>
                <article class="col-sm-4">
                  <b-form-group>
                    <label>Adresse<sup class="required" v-if="eordoFields"> requis</sup></label>
                    <b-form-input v-model="payload.address" id="`general-address`" type="text" @blur="setAttr($event, 'address')"></b-form-input>
                  </b-form-group>
                </article>

                <article class="col-sm-4">
                  <b-form-group>
                    <label>Ville<sup class="required" v-if="eordoFields"> requis</sup></label>
                    <b-form-input v-model="payload.city" id="`general-city`" type="text" @blur="setAttr($event, 'city')"></b-form-input>
                  </b-form-group>
                </article>
                <article v-if="showTestEmail" style="display:none" class="christmas-tree col-sm-12" title="N'est présent qu'en environnement STG ou dev">
                  Email test
                  <b-form-input v-model="payload.email_test" id="`general-email-test`" type="email" @blur="setAttr($event, 'email_test')" placeholder='Un email pour tester la fonction'></b-form-input>
                </article>
              </div>
              <b-button
                class="ml-2 submit-button"
                @click="setProgress(1)"
                :disabled="!this.$store.getters.validMainForm"
              >Valider et définir la prescription</b-button>
            </b-collapse>
          </section>

          <section class="bloc" v-show="progress > 0">
            <div class="row col-sm-12 d-flex">
              <div class="col-sm-4">
                <h5 class="text-is-semibold"><span class="rounded-number">2</span>Prescription</h5>
              </div>
            </div>

            <div id="prescription-content" class="p-0 w-100" v-if="anyPathologySelected">
              <template v-for="pathology in pathologies">
                <article :key="pathology.value" v-if="pathology.visible">
                  <component
                    :is="`${pathology.value}-form`"
                    @form-destroy="onSectionDestroy(pathology.value)"
                  ></component>
                </article>
              </template>

              <article>
                <b-button
                  class="ml-4 submit-button"
                  @click="submitForm (url(),payload)"
                >
                  Valider votre demande de prise en charge
                </b-button>
              </article>
            </div>

            <div class="is-grey mt-4 divider col-sm-12" v-else>
              <i class="fas fa-long-arrow-alt-left"></i> Veuillez sélectionner un type de prescription ci-contre pour continuer le formulaire
            </div>

          </section>
        </form>
        <success-screen
          v-if="notConfirmed === false && this.$store.state.successScreenForm.prescriptionSent === false"
          :link="link"
          :pathologies="checkedPathologies"
          :patient-badges="$store.getters.validMainData"
        ></success-screen>
        <prescription-sent v-if="this.$store.state.successScreenForm.prescriptionSent === true" ></prescription-sent>
        <cm-confirm
          :show.sync="modalOpen"
          :title="titleForModal"
          :subtitle="subtitleForModal"
          :data="dataForModal"
          ok-button-label="Supprimer le traitement"
          cancel-button-label="Annuler"
          @confirm-ok="okHandlerForModal"
          @confirm-cancel="cancelHandlerForModal"
        ></cm-confirm>
      </section>
    </div>
  </div>
</template>

<script>
import O2Form from '@/components/askForCare/O2Form'
import PpcForm from '@/components/askForCare/PpcForm'
import VniForm from '@/components/askForCare/VniForm'
import PrescriptionSent from '@/components/askForCare/PrescriptionSent'
import SuccessScreen from '@/components/askForCare/SuccessScreen'
import CmSwitchButton from '@/components/UI/CmSwitchButton'
import CmFollowScroll from '@/components/UI/CmFollowScroll'
// FIXME: Use UI/CmRadioButton instead.
import CmRadioButton from '@/components/askForCare/CustomRadioButton'

import AskForCareMixin from '@/mixins/AskForCareMixin'
import DebounceMixin from '@/mixins/DebounceMixin'

import CmConfirm from '@/components/UI/Modal/CmConfirm'
import CmStepper from '@/components/UI/Stepper/CmStepper'
import CmStepperStep from '@/components/UI/Stepper/CmStepperStep'

export default {
  mixins: [
    AskForCareMixin,
    DebounceMixin,
  ],
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  components: {
    CmConfirm,
    CmFollowScroll,
    CmRadioButton,
    CmStepper,
    CmStepperStep,
    CmSwitchButton,
    SuccessScreen,
    PrescriptionSent,
    PpcForm,
    O2Form,
    VniForm,
  },

  data() {
    return {
      showTestEmail: process.env.NODE_ENV !== 'production',
      userSettings: [],
      setting: {},
      zipCodeMask: [/\d/, /[1-9]/, /\d/, /\d/, /\d/],
      titleForModal: null,
      dateInputLimit: (() => {
        const today = new Date()

        // NOTE: months start at 0 (January === 0), hence the + 1
        return `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`
      })(),
      subtitleForModal: null,
      dataForModal: '',
      okHandlerForModal: () => {},
      cancelHandlerForModal: () => {},
      notConfirmed: true,
      sended: false,
      link: '',
      checkedCivility: [],
      genderOptions: [
        { text: this.$t('askForCare.main.gender.man'), value: 1, selected: true },
        { text: this.$t('askForCare.main.gender.woman')  , value: 2, selected: false }
      ],
      old_pathologies_array : [],
      specialityOptions: [
        { text: 'Respiratoire', value: 'ard' }
      ],
      booleanOptions: [
        { text: this.$t('common.true'), value:true },
        { text: this.$t('common.false'), value:false }
      ],
      // checked is used for the checkboxes, while visible is used to show or hide form sections
      // needs to be separate as the checkboxes are applied before any checks
      pathologies: [
        {
          text: this.$t('askForCare.ppc.ppc'),
          value: 'ppc',
          checked: false,
          visible: false
        },
        {
          text: this.$t('askForCare.o2.o2'),
          value: 'o2',
          checked: false,
          visible: false
        },
        {
          text: this.$t('askForCare.vni.vni'),
          value: 'vni',
          checked: false,
          visible: false
        }
      ],
      incompatiblePathologies: {
        ppc: 'vni',
        vni: 'ppc'
      },
      // @todo - make it computed but must work around vue's bad reactivity with arrays
      checkedPathologies: [],
      payload: {
        address       : this.$store.state.mainCareForm.address,
        city          : this.$store.state.mainCareForm.city,
        date_of_birth : this.$store.state.mainCareForm.date_of_birth,
        firstname     : this.$store.state.mainCareForm.firstname,
        gender        : this.$store.state.mainCareForm.gender,
        lastname      : this.$store.state.mainCareForm.lastname,
        phone         : this.$store.state.mainCareForm.phone,
        securitynumber: this.$store.state.mainCareForm.securitynumber,
        email_test    : this.$store.state.mainCareForm.email_test,
      },
      zipcode : this.$store.state.mainCareForm.zipcode,
      displayInvalidZipcode: false
    }
  },
  watch: {
    zipcode() {
      this.debounce(() => {
        const onlyFiveDigits = /^\d{5}$/;
        this.displayInvalidZipcode = this.zipcode.length > 0 ? !onlyFiveDigits.test(this.zipcode) : false;
      })
    },
  },

  async created() {
    const userSettingsUrl   = `/api/v1/users/${this.userId}/user_custom_setting_links`
    const customSettingsUrl = '/api/v1/custom_settings'

    const params = {
      label: ['eordo_mandatory_fields']
    }

    try {
      const { data } = await this.$axios.get(userSettingsUrl, { params })
      this.userSettings = data.settings
      this.$store.commit('update_main_eordoFields', this.userSettings.length > 0)
    } catch(error) {
      switch (error.response.status) {
        case 404:
          this.$toasted.error("L'utilisateur n'a pas été trouvé.", { duration: 5000 })
          break;
        default:
          console.error(error)
          this.$toasted.error("Un problème est survenue lors de la récupération de ce paramètre.", { duration: 5000 })
          break;
      }
    }

    try {
      const { data } = await this.$axios.get(customSettingsUrl, { params })
      this.setting = data.settings[0]
    } catch(error) {
      console.error(error)
      this.$toasted.error("Un problème est survenue lors de la récupération de des paramètres.", { duration: 5000 })
    }


  },

  computed: {
    mainVisible() {
      return this.progress === 0
    },

    prescriptionVisible() {
      return this.progress === 1
    },

    anyPathologySelected() {
      return this.checkedPathologies.length !== 0
    },
    gender: {
      get() {
        return this.$store.state.mainCareForm.gender
      },
      set(value) {
        this.$store.commit('update_main_gender', value)
      }
    },
    progress: {
      get() {
        return this.$store.state.mainCareForm.progress
      },
      set(value) {
        this.$store.commit('update_main_progress', value)
      }
    },
    securityNumber: {
      get() {
        let val = this.$store.state.mainCareForm.securitynumber
        return val.replaceAll(' ','').length === 0 ? '' : this.strSecurityNumber(val)
      },
      set(value){
        this.$store.commit('this.$store.state.mainCareForm.securitynumber', value)
      }
    },
    birthDate: {
      get() {
        return this.$store.state.mainCareForm.date_of_birth
      },
      set(value){
        this.$store.commit('update_main_date_of_birth', value)
      }
    },
    eordoFields: {
      get() {
        return this.$store.state.mainCareForm.settings.eordo_mandatory_fields
      },
      set(value) {
        if (value) {
          this.$axios.post(`/api/v1/users/${this.userId}/user_custom_setting_links`, {
            user_id: this.userId,
            custom_setting_id: this.setting.id
          })
        } else {
          this.$axios.delete(`/api/v1/users/${this.userId}/user_custom_setting_links/${this.setting.id}`)
        }

        this.$store.commit('update_main_eordoFields', value)
      }
    },

    // Validation
    lastnameVal(){
      return this.payload.lastname.length > 0 || (!this.$store.state.mainCareForm.ajaxSent && this.payload.lastname === '')
    },
    phoneVal(){
      return this.payload.phone.replaceAll(' ', '').length === 10 || (!this.$store.state.mainCareForm.ajaxSent && this.payload.phone === '')
    },

    birthdateVal() {
      if(!this.shouldValidateBirthDate()) return true;

      return (!this.$store.state.mainCareForm.ajaxSent && this.payload.date_of_birth === '') || new Date(this.payload.date_of_birth).setHours(0, 0, 0) <= new Date().setHours(0, 0, 0)
    },

    // Invalid Feedback
    birthdateFeedback() {
      return 'Merci de rentrer une date passée'
    },

    phoneFeedback() {
      return 'Merci de rentrer un numéro de téléphone à 10 chiffres'
    },
    lastNameFeedback() {
      return 'Entrez un nom'
    },
    validForms() {
      let validness = [this.$store.getters.validMainForm]

      if (this.checkedPathologies.includes('ppc')) {
        validness.push(this.$store.getters.validPpcForm)
      }
      if (this.checkedPathologies.includes('o2')) {
        validness.push(this.$store.getters.validO2Form)
      }
      if (this.checkedPathologies.includes('vni')) {
        validness.push(this.$store.getters.validVniForm)
      }

      return this.checkedPathologies.length > 0 && (validness.every((el) => el === true));
    }
  },
  methods: {
    shouldValidateBirthDate() {
      return this.eordoFields && !!this.$store.state.ppcCareForm.iah
    },

    strSecurityNumber: function(value){
      return `${value.substring(0,1)} ${value.substring(1,3)} ${value.substring(3,5)} ${value.substring(5,7)} ${value.substring(7,10)} ${value.substring(10,13)} ${value.substring(13,15)}`
    },
    modalTitle(pathology) {
      this.subtitleForModal = 'La modification du traitement entraînera la suppression des données renseignées dans le formulaire.'
      return `Un traitement par ${pathology.toUpperCase()} est déjà sélectionné pour ce patient.`
    },

    modalTitleSingle(pathology) {
      return `Confirmer la suppression de ce traitement ${pathology.toUpperCase()}`
    },

    onCheckboxInput(pathology) {
      if(pathology.disabled) return;

      pathology.checked = !pathology.checked
      this.changePathologyState(pathology)
    },

    onSectionDestroy(pathologyName) {
      const pathology = this.pathologies.find(pathology => pathology.value === pathologyName)
      this.changePathologyState(pathology)
    },

    changePathologyState(pathology) {
      // if it was deselected
      if (this.checkedPathologies.includes(pathology.value)) {
        const store = this.$store.state[`${pathology.value}CareForm`]

        if (store.dirty) {
          this.dataForModal = { pathology }
          this.titleForModal = this.modalTitleSingle(pathology.value)
          this.okHandlerForModal = (data) => {
            this.deselectPathology(data.pathology)
          }
          this.cancelHandlerForModal = async (data) => {
            this.selectPathology(data.pathology)
          }
          this.toggleModal(store.dirty)
        } else {
          this.deselectPathology(pathology)
        }
      // if it was selected
      } else {
        // Do nothing weird if it's O2
        if (pathology.value === 'o2') return this.selectPathology(pathology);

        // if vni or ppc is already selected
        if (this.checkedPathologies.includes('vni') || this.checkedPathologies.includes('ppc')) {
          const incompatiblePathology = this.pathologies.find(_pathology => _pathology.value === this.incompatiblePathologies[pathology.value])

          // We have to check the state of the OTHER pathology since that's the one which is already checked
          const store = this.$store.state[`${incompatiblePathology.value}CareForm`]

          if (store.dirty) {
            this.dataForModal = { incompatiblePathology, pathology }
            this.titleForModal = this.modalTitle(incompatiblePathology.value)
            this.okHandlerForModal = (data) => {
              this.deselectPathology(data.incompatiblePathology)
              this.selectPathology(data.pathology)
            }
            this.cancelHandlerForModal = async (data) => {
              this.deselectPathology(data.pathology)
              this.selectPathology(data.incompatiblePathology)
            }
            this.toggleModal(true)
          } else {
            // remove incompatible pathology from the new array and set the result
            this.deselectPathology(incompatiblePathology)
            this.selectPathology(pathology)
          }

        } else {
          return this.selectPathology(pathology)
        }
      }
    },

    deselectPathology(pathology) {
      this.togglePathology(pathology, false)
    },

    selectPathology(pathology) {
      this.togglePathology(pathology, true)
    },

    togglePathology(pathology, status) {
      pathology.checked = status
      pathology.visible = status
      const index = this.pathologies.findIndex(_pathology => _pathology.value === pathology.value)
      this.$set(this.pathologies, index, pathology)
      this.checkedPathologies = this.pathologies.filter(pathology => pathology.checked).map(pathology => pathology.value)
    },

    url(){
      // futur endpoint /api/v1/respiratory/askforcare
      return '/respiratory/askforcare'
    },
    send(){
      this.sended = true
      this.$toasted.success("La demande de création d'une prescription est effectuée", { duration: 5000 })
    },

    getMainData(){

      return {
        address         : this.$store.state.mainCareForm.address,
        city            : this.$store.state.mainCareForm.city,
        date_of_birth   : this.$store.state.mainCareForm.date_of_birth,
        firstname       : this.$store.state.mainCareForm.firstname,
        gender          : this.$store.state.mainCareForm.gender,
        lastname        : this.$store.state.mainCareForm.lastname,
        phone           : this.$store.state.mainCareForm.phone,
        securitynumber  : this.$store.state.mainCareForm.securitynumber,
        zipcode         : this.$store.state.mainCareForm.zipcode,
        email_test      : this.$store.state.mainCareForm.email_test,
        pathology       : this.checkedPathologies,
        custom_settings : this.$store.getters.currentSettings
      }
    },
    getPpcData(){
      if(this.checkedPathologies.includes('ppc')) {
        return {
          additional_info_ppc : this.$store.state.ppcCareForm.additional_info_ppc,
          ai_max_ppc          : this.$store.state.ppcCareForm.ai_max_ppc,
          ai_min_ppc          : this.$store.state.ppcCareForm.ai_min_ppc,
          ald_on_list_ppc     : this.$store.state.ppcCareForm.with_ald_ppc === true ? this.$store.state.ppcCareForm.ald_on_list_ppc : null,
          attached_exams      : this.$store.state.ppcCareForm.attachedExams,
          frequency_ppc       : this.$store.state.ppcCareForm.frequency_ppc,
          humidifier_ppc      : this.$store.state.ppcCareForm.humidifier_ppc,
          iah                 : this.$store.state.ppcCareForm.iah,
          iah_symptoms        : this.$store.state.ppcCareForm.iah_symptoms,
          iah_other_symptoms  : this.$store.state.ppcCareForm.iah_other_symptoms,
          ia                  : this.$store.state.ppcCareForm.ia,
          p_auto_max          : this.$store.state.ppcCareForm.p_auto_max,
          p_auto_min          : this.$store.state.ppcCareForm.p_auto_min,
          p_fixed             : this.$store.state.ppcCareForm.p_fixed,
          pep_auto_max_ppc    : this.$store.state.ppcCareForm.pep_auto_max_ppc,
          pep_auto_min_ppc    : this.$store.state.ppcCareForm.pep_auto_min_ppc,
          pep_ppc             : this.$store.state.ppcCareForm.pep_ppc,
          ppc_type            : this.$store.state.ppcCareForm.ppc_type,
          pressure_type       : this.$store.state.ppcCareForm.pressure_type,
          with_ald_ppc        : this.$store.state.ppcCareForm.with_ald_ppc
        }
      }
      else{
        return {}
      }
    },
    getO2Data(){
      if(this.checkedPathologies.includes('o2')) {
        const durationO2 = this.$store.state.o2CareForm.prescription_duration_o2;

        return {
          additional_info_o2           : this.$store.state.o2CareForm.additional_info_o2,
          ald_on_list_o2               : this.$store.state.o2CareForm.with_ald_o2 === true ? this.$store.state.o2CareForm.ald_on_list_o2 : null,
          arterial_blood_gas_date_test : this.$store.state.o2CareForm.arterial_blood_gas_date_test,
          arterial_blood_gas_pa_co2    : this.$store.state.o2CareForm.arterial_blood_gas_pa_co2,
          arterial_blood_gas_pa_o2     : this.$store.state.o2CareForm.arterial_blood_gas_pa_o2,
          arterial_blood_gas_ph        : this.$store.state.o2CareForm.arterial_blood_gas_ph,
          arterial_blood_gas_sa_o2     : this.$store.state.o2CareForm.arterial_blood_gas_sa_o2,
          arterial_blood_gas_test      : this.$store.state.o2CareForm.arterial_blood_gas_test,
          care_type                    : this.$store.state.o2CareForm.care_type,
          consumables                  : this.$store.state.o2CareForm.consumables,
          devices_packages             : this.$store.state.o2CareForm.devices_packages,
          equipments                   : this.$store.state.o2CareForm.equipments,
          flow_day                     : this.$store.state.o2CareForm.flow_day,
          flow_discontinuous           : this.$store.state.o2CareForm.flow_discontinuous,
          flow_effort                  : this.$store.state.o2CareForm.flow_effort,
          flow_night                   : this.$store.state.o2CareForm.flow_night,
          humidifier_o2                : null,
          o2_type                      : this.$store.state.o2CareForm.o2_type,
          once_renewal                 : this.$store.state.o2CareForm.once_renewal,
          preferred_device             : this.$store.state.o2CareForm.preferred_device,
          prescription_duration_o2     : this.$store.state.o2CareForm.duration_unit === 'months' ? durationO2 * 4 : durationO2,
          prescription_start_date_o2   : this.$store.state.o2CareForm.prescription_start_date_o2,
          time_of_use_o2               : this.$store.state.o2CareForm.time_of_use_o2,
          walker                       : this.$store.state.o2CareForm.walker,
          with_ald_o2                  : this.$store.state.o2CareForm.with_ald_o2
        }
      }
      else{
        return {}
      }
    },
    getVniData(){
      if(this.checkedPathologies.includes('vni')) {
        return {
          additional_info_vni : this.$store.state.vniCareForm.additional_info_vni,
          ai_max_vni          : this.$store.state.vniCareForm.ai_max_vni,
          ai_min_vni          : this.$store.state.vniCareForm.ai_min_vni,
          ald_on_list_vni     : this.$store.state.vniCareForm.with_ald_vni === true ? this.$store.state.vniCareForm.ald_on_list_vni : null,
          frequency_vni       : this.$store.state.vniCareForm.frequency_vni,
          humidifier_vni      : this.$store.state.vniCareForm.humidifier_vni,
          interface           : this.$store.state.vniCareForm.interface,
          mask_type           : this.$store.state.vniCareForm.mask_type,
          mode_vni            : this.$store.state.vniCareForm.mode_vni,
          pep_auto_max_vni    : this.$store.state.vniCareForm.pep_auto_max_vni,
          pep_auto_min_vni    : this.$store.state.vniCareForm.pep_auto_min_vni,
          pep_vni             : this.$store.state.vniCareForm.pep_vni,
          pip                 : this.$store.state.vniCareForm.pip,
          pip_max             : this.$store.state.vniCareForm.pip_max,
          pip_min             : this.$store.state.vniCareForm.pip_min,
          slope               : this.$store.state.vniCareForm.slope,
          ti_fixed            : this.$store.state.vniCareForm.ti_fixed,
          ti_max              : this.$store.state.vniCareForm.ti_max,
          ti_min              : this.$store.state.vniCareForm.ti_min,
          time_of_use_vni     : this.$store.state.vniCareForm.time_of_use_vni,
          trigger_exp         : this.$store.state.vniCareForm.trigger_exp,
          trigger_insp        : this.$store.state.vniCareForm.trigger_insp,
          volume              : this.$store.state.vniCareForm.volume,
          with_ald_vni        : this.$store.state.vniCareForm.with_ald_vni
        }
      }
      else{
        return {}
      }
    },
    uploadFile(url, files, object) {
      let ids = new Array
      let formData = new FormData()
      for (const file of files){
        formData.append('files[]', file)
      }

      this.$axios.post(url, formData)
        .then( response => {
          object.attached_exams = response.data.file_ids
          return this.sendForm(this.url(), object)
        })
        .catch( error => {
          console.error('Error', error)
        })
    },
    sendForm(url, data) {
      this.$axios.post(url, data)
          .then((response) => {
            this.setProgress(2)
            this.notConfirmed = false
            this.link = response.data.link
          })
          .catch((error) => {
            this.$toasted.error("Certains champs sont invalides.", { duration: 5000 })
          })
          .finally(() => {
            this.$store.commit('update', { key: 'ajaxSent', value: true })
            this.$store.commit('update', { key: 'sentPathologies', value: this.checkedPathologies })
          })
    },
    submitForm(url, data){
      let formData = new FormData()
      let sendData = Object.assign({}, ...[
          this.getMainData(),
          this.getPpcData(),
          this.getO2Data(),
          this.getVniData()
        ])
      let ppcFile = this.$store.state.ppcCareForm.attachedExams

      if (ppcFile.length > 0) {
        this.uploadFile('/api/v1/askforcare/files', ppcFile, sendData)
      }
      else{
        this.sendForm(url, sendData)
      }
    },
    setAttr(e, attr){
      return this.$store.commit(`update_main_${attr}`, e.target.value)
    },
    setProgress(toNumber){
      this.progress = toNumber
    },
    isActive(value, stateValue) {
      return (value === stateValue)
    }
  }
}
</script>

<style lang="scss">
/* LOAD FONT */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.is-grey {
  color: #9fa5cd;
}

.stepper-checkbox {
  .custom-checkbox {
    width: 100%;
    padding: 11px 145px 11px 8px;

    border-radius: 4px;
    border: solid 1px #cdcdcd;

    margin: 8px 0;

    &.is-selected {
      background-color: #ebedf9;
    }

    label {
      color: #9d9d9d;
      font-size: 12px;
      margin-left: 24px;
      line-height: 24px;

      &:before,
      &:after {
        cursor: pointer;
      }
    }
  }

}

.update-btn {
  color: #4a4f62;
  padding: 8px 12px;
  border-radius: 6px;
  background: #e4e6ed;
}

.update-btn {
  color: #4a4f62;
  padding: 8px 12px;
  border-radius: 6px;
  background: #e4e6ed;
}

.text-is-medium {
  font-weight: 500;
}

.rounded-number {
  display: inline-block;
  width: 34px;
  height: 34px;
  color: #4a61e3;
  text-align: center;
  border: 2px solid #4a61e3;
  border-radius: 100%;
  line-height: initial;
  margin-right: 14px;
  padding: 3px;
  font-size: 18px;
}

.vue-ask-for-care-form {
  background-color: #f9f7f5;

  .form-group:not(.is-invalid) input, textarea, select {
    background: #ffffff;
    border-radius: 6px;
    border: solid 1px rgb(220, 220, 220);
  }

  .custom-select {
    background: #fff url('../../assets/images/icons/Icons_Dropdown.svg') no-repeat right 0.75rem center/24px 24px !important;
    height: calc(2em + 0.75rem + 6px);
  }

  .input-group-text {
    padding: 0px;
    border: 0px;
    background-color: white;
  }

  .form-control {
    &.like-label {
      padding-top: 0px;
      padding-bottom: 0px;
      background-color: white;
      color: black;

      /* Border around Autre text field  PPC component*/
      padding-left: 5px;
      margin-left: 2%;
      border-radius: 6px;
      border: solid 1px gainsboro
    }
    &.no-logotype {
      background-image: none;
    }
  }

  #iah-tooltip {
    /* Because style is on element ... */
    transform: translate3d(-50px, 130px, 0px) !important;

    .tooltip-inner {
      max-width: 250px
    }

    &.no-arrow {
      .arrow, .arrow::after,
      .arrow::before {
        content: none
      }
    }
  }
  .is-tooltip {
    /* modal screen is 1031 and navbar 1030 */
    z-index: 1029;

    &.tooltip {
      opacity: 100 !important;
      left: 30% !important;
      top: -30% !important; // Top and left are set on element
    }

    .tooltip-inner {
      color: black;
      text-align: justify;
      background-color: #fff8e6;
      border-radius: 12px;
      border: 1px solid #ffb800;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    }

    .arrow {
      position: absolute;
      width: 36px;
      height: 36px;
      left: -18px; // half of this.width
      top: 29px !important; // half of his parent height - height/2; important because bootstrap adds top on element
    }

    .arrow::after,
    .arrow::before {
      content: '';
      position: absolute;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
    }

    .arrow::before {
      border-width: 13px;
      border-color: transparent;
      border-right-color: transparent;
      border-right-color: #ffb800;
      left: 1%;
    }

    .arrow::after {
      top: .2rem;
      border-color: transparent #fff8e6 transparent transparent;
      border-width: 10px;
      left: 18%;
    }
  }


  label {
    font-weight: 500;
  }

  #form-aside label {
    margin-bottom: 0;
  }

  article {
    margin-top: 15px;
  }

  .divider {
    border-top: 1px solid #e2e4f1;
    padding-top: 30px;
    margin-bottom: 15px;
  }

  .started-content {
    align-content: start;
  }
  .bloc {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 12px;
    border: solid 1px #e2e4f1;
    margin-top: 24px;
    margin-bottom: 16px;
    ;
    padding: 24px 0;
    background-color: #ffffff;

    &.bloc-row{
      flex-direction: row;
      background-color: transparent;
    }
  }

  .form-content {
    padding-right: 0px;
  }

  .title {
    color: #4a61e3;
    font-size: 30px;
    width: 437px;

    height: 58.8px;
    margin: 0 0 24px;
    font-family: Inter;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .danger-title {
    color: #eb5757;
    font-size: 30px;
  }

  .sub-title {
    color: #6b75b2;
    font-size: 18px;
    width: 354px;
    margin-bottom: 0px;
  }
}

.christmas-tree {
  background-color: grey;
  border: 1px solid black;
  color: wheat
}

.form-header {
  height: 180px;
  padding: 0 49.8px 0 64px;
  border-radius: 12px;
  background-color: #ebedf9;
  background-image: url('../../assets/images/Photo.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.success-header {
  padding: 0 49.8px 0 64px;
  border-radius: 12px;
  background-color: #ebedf9;
  background-image: url('../../assets/images/illustration-validation.svg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.danger-header {
  padding: 0 49.8px 0 64px;
  border-radius: 12px;
  background-color: #ebedf9;
  background-image: url('../../assets/images/illustration-delete.svg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.elivie-logo-white {
  background-image: url('../../assets/images/LOGO_ELIVIEW_RVB_3.png');
  padding: 0 49.8px 0 64px;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-position: bottom;
}

.elivie-logo {
  background-image: url('../../assets/images/LOGO_ELIVIEW_RVB.png');
  padding: 0 49.8px 0 64px;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-position: bottom;
}

sup.required {
  color: red
}

.is-invalid {
  input {
    color: #eb5757;
    border: 3px solid #eb5757;
  }

  // CSS rules for Bootstrap feedback
  .invalid-feedback {
    color: #eb5757 !important;
    font-weight: bold !important;
  }
}

// Radio button become button
div[role=radiogroup] {
  :hover {
    background-color: transparent !important;
    color: black;
  }

  label {
    &.active {
      background-color: #4a61e3 !important;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-select {
  background: #fff url('../../assets/images/icons/Icons_Dropdown.svg') no-repeat right 0.75rem center/24px 24px !important;
  height: calc(2em + 0.75rem + 6px);
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.pathology-badge {
  border-color: #e4e6ed;
  border-radius: 6px;
  background-color: #e4e6ed;
  color: black;
  padding: 8px 10px;

  i {
    line-height: 1.5;
  }

  span {
    margin-left: 15px;
    font-size: 18px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.tag {
  border-radius: 4px;
  background-color: #ebedf9;
  color: #4a61e3;
  object-fit: contain;
  margin: 5px;
  padding: 7px 12px;

  span {
    padding: 5px;
  }
}

.vue-ask-for-care-patho {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  border: 1px solid #e4e6ed;
  border-radius: 50%;
  padding: 10px;
  background: #e4e6ed;
  width: 36px;
  height: 36px;
}

.hidden {
  visibility: hidden;
}

.submit-button {
  background: #4a61e3;
  color: #fafafa;
  border-radius: 6px;
  margin-top: 20px;
}

.danger-button {
  background: #eb5757;
  color: #fafafa;
  border-radius: 6px;
  border-color: #eb5757;
  margin-top: 20px;
}

.transparent-button {
  background: transparent;
  color: #4a4f62;
  border: none;
  margin-top: 20px;
}

.back-button {
  border-radius: 6px;
  margin-top: 20px;
}

.page-nav {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1030; // The navbar is 1030
}

.radio-buttons {
  .custom-radio {
    &.inactive {
      color: #9d9d9d;
      border: solid 1px #cdcdcd;
      background-color: white;
    }

    color: #4a61e3;
    border-color: #4a61e3;
    border: 1px solid;
    border-radius: 4px;
    margin: 0 15px 9px 0;
    padding: 11px 16px;
    background-color: #ebedf9;

    input {
      position: initial;
    }
  }
}

.input-placeholders-right {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  align-content: center;
  justify-content: center;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
  border-radius: 6px;
  border: solid 1px;
  padding: 0.75rem;
  height: initial;
}

.placeholder {
  align-self: center;
  position: absolute;
  right: 15px;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.42px;
  color: #777777;
  z-index: 3;
}
</style>
