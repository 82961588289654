import Vue from 'vue'
import i18n from '@/plugins/i18n'

export default {
  state() {
    return {
      // input
      address       : '',
      city          : '',
      date_of_birth : '',
      firstname     : '',
      gender        : '',
      lastname      : '',
      phone         : '',
      securitynumber: '',
      zipcode       : '',

      // tools key
      dirty         : false,
      old           : null,

      // Shared element between all componnents
      speciality    : 'ard',
      email_test     : '',
      expandedPpc   : true,
      expandedO2    : true,
      expandedVni   : true,
      ajaxSent      : false,
      progress      : 0,
      settings      : {
        eordo_mandatory_fields : true,
      },
      sentPathologies : []
    }
  },
  getters: {
    age(state) {
      if(state.birthDate === '' ) {
        return null
      }
        let today = new Date();
        let birthDate = new Date(state.date_of_birth);
        let age = today.getFullYear() - birthDate.getFullYear();
        let month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    },
    validMainData(state) {
      let filterObject = {}

      if(state.lastname !== '') {
        filterObject['civility'] = [i18n.tc(`askForCare.main.gender.${state.gender === '' ? 'not_set' : state.gender}`), state.lastname.toUpperCase(), state.firstname].join(' ')
      }
      if(state.phone !== '') {
        filterObject['phone'] = [i18n.tc('askForCare.main.phone'), state.phone].join(' ')
      }
      if(state.date_of_birth !== '' && new Date(state.date_of_birth).setHours(0, 0, 0) <= new Date().setHours(0, 0, 0)) {
        filterObject['date_of_birth'] = [i18n.tc('askForCare.main.date_of_birth'), new Date(state.date_of_birth).toLocaleDateString('fr')].join(' ')
      }
      if(state.securitynumber !== '') {
        filterObject['securitynumber'] = [i18n.tc('askForCare.main.securitynumber'), state.securitynumber].join(' ')
      }
      if(state.zipcode) {
        filterObject['zipcode'] = [i18n.tc('askForCare.main.zipcode'), state.zipcode].join(' ')
      }
      if(state.address !== '' ) {
        filterObject['address'] = [i18n.tc('askForCare.main.address'), state.address].join(' ')
      }
      if(state.city) {
        filterObject['city'] = [i18n.tc('askForCare.main.city'), state.city].join(' ')
      }

      return filterObject
    },
    validMainForm(state) {
      if(state.settings.eordo_mandatory_fields) {
        const core  = state.lastname.length > 0 && state.phone.length === 10 && state.zipcode.length === 5
        const eordo = (
          state.gender !== '' &&
          state.firstname.length > 0 &&
          state.date_of_birth.length > 0 &&
          state.address.length > 0 &&
          state.city.length > 0
        )
        return core && eordo
      }
      else {
        return state.lastname.length > 0 && state.phone.length === 10 && state.zipcode.length === 5
      }

    },
    currentSettings(state) {
      const currentSettings = []
      for (const setting in state.settings) {
        if(state.settings[setting]) {
          currentSettings.push(setting)
        }
      }
      return currentSettings
    }
  },
  mutations: {
    update(state, { key, value }) {
      Vue.set(state, key, value)
    },
    update_main_address (state, value) {
      state.address = value
    },
    update_main_city (state, value) {
      state.city = value
    },
    update_main_date_of_birth (state, value) {
      state.date_of_birth = value
    },
    update_main_firstname (state, value) {
      state.firstname = value
    },
    update_main_gender (state, value) {
      state.gender = value
    },
    update_main_lastname (state, value) {
      state.lastname = value
    },
    update_main_phone (state, value) {
      state.phone = value.replaceAll(' ', '')
    },
    update_main_securitynumber (state, value) {
      state.securitynumber = value.toUpperCase().replaceAll(' ', '')
    },
    update_main_zipcode (state, value) {
      state.zipcode = value
    },
    update_main_email_test (state, value) {
      state.email_test = value
    },
    update_main_expandedO2 (state, value) {
      state.expandedO2 = value
    },
    update_main_expandedVni (state, value) {
      state.expandedVni = value
    },
    update_main_expandedPpc (state, value) {
      state.expandedPpc = value
    },
    update_main_old (state, value) {
      state.old = value
    },
    update_main_progress (state, value) {
      state.progress = value
    },
    update_main_eordoFields (state, value) {
      state.settings.eordo_mandatory_fields = value
    },

  }
}
