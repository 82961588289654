<template>
  <span :class="[customClass, 'cm-tooltip-text']">{{ text }}</span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    customClass: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
}
</script>

<style lang="scss">
/* Tooltip text */
.cm-tooltip-text {
  width: max-content;
  background-color: white;
  font-size: 14px !important;
  color: red;
  text-align: center;
  padding: 10px 10px;
  border-radius: 6px;
  box-shadow: 0 5px 15px 0 darkgray;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: 0;
  left: 65%;

  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -15px;
    border-width: 15px;
    border-style: solid;
    border-color: transparent white transparent transparent;
  }
}
</style>
