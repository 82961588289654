<template>
  <span class="additional-text">
    <label v-for="value of values" :for="value.name">
      {{ value.label }} : {{ value.value }}
    </label>
  </span>
</template>

<script>
export default {
  props: {
    values: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
.additional-text {
  margin-left: -15px;
  padding: 10px 15px;
  border: 1px solid #ffb800;

  label {
    margin: 5px 10px;
  }
}
</style>
