export default {
  props: {
    valid: {
      type: Boolean,
      required: false,
      default: () => true
    },
    feedback: {
      type: [String, Function],
      required: false,
      default: () => "Le champ n'est pas valide."
    },
    feedbackData: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      dirty: true
    }
  },

  computed: {
    invalidFeedback() {
      return typeof this.feedback === 'function' ? this.feedback(this.feedbackData) : this.feedback
    },
    shouldValidate() {
      return !this.dirty ? null : this.valid
    }
  }
}
