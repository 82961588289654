import Vue from 'vue';
import Vuex from "vuex";

import mainCareForm from '@/store/mainCareForm'
import ppcCareForm from '@/store/ppcCareForm'
import o2CareForm from '@/store/o2CareForm'
import vniCareForm from '@/store/vniCareForm'
import successScreenForm from '@/store/successScreenForm'
import resetFieldsModal from '@/store/resetFieldsModal'
import o2Renewal from '@/store/renewalForm/o2Renewal'
import vniRenewal from '@/store/renewalForm/vniRenewal'
import ppcRenewal from '@/store/renewalForm/ppcRenewal'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    mainCareForm,
    ppcCareForm,
    o2CareForm,
    vniCareForm,
    successScreenForm,
    resetFieldsModal,
    o2Renewal,
    vniRenewal,
    ppcRenewal
  }
})
