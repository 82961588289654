import i18n from '@/plugins/i18n.js'

export default {
  state() {
    return {
      // vni
      additional_info_vni : '',
      ai_max_vni          : '',
      ai_min_vni          : '',
      ald_on_list_vni     : false,
      frequency_vni       : '',
      humidifier_vni      : false,
      interface           : '',
      mask_type           : '',
      mode_vni            : 'st',
      pep_auto_max_vni    : '',
      pep_auto_min_vni    : '',
      pep_vni             : '',
      pip                 : '',
      pip_max             : '',
      pip_min             : '',
      slope               : '',
      ti_fixed            : '',
      ti_max              : '',
      ti_min              : '',
      time_of_use_vni     : '< 12H',
      trigger_exp         : '',
      trigger_insp        : '',
      volume              : '',
      with_ald_vni        : true,

      // tools key
      dirty         : false,
    }
  },
  getters: {
    validVniData(state) {
			let filterObject = {}

			if(state.with_ald_vni !== '')                                   filterObject['with_ald_vni']     = 'ALD : ' + i18n.t(`common.${state.with_ald_vni}`)
      if(state.ald_on_list_vni !== '' && state.with_ald_vni === true) filterObject['ald_on_list_vni']  = 'Sur liste : ' + i18n.t(`common.${state.ald_on_list_vni}`)
      if(state.interface !== '')                                      filterObject['interface']        = 'Interface : ' + i18n.t(`askForCare.vni.${state.interface}`)
      if(state.mask_type !== '' && state.interface === 'mask')         filterObject['mask_type']        = 'Type de masque : ' + i18n.t(`askForCare.vni.${state.mask_type}`)
      if(state.mode_vni !== '')                                       filterObject['mode_vni']         = 'Mode : ' + i18n.t(`askForCare.vni.${state.mode_vni || 'not_set'}`)
      if(state.volume !== '')                                         filterObject['volume']           = `Volume : ${state.volume} ml`
      if(state.pep_vni !== '')                                        filterObject['pep_vni']          = `PEP : ${state.pep_vni}`
      if(state.pep_auto_min_vni !== '')                               filterObject['pep_auto_min_vni'] = `PEP Auto min : ${state.pep_auto_min_vni}`
			if(state.pep_auto_max_vni !== '')                               filterObject['pep_auto_max_vni'] = `PEP Auto max : ${state.pep_auto_max_vni}`
      if(state.pip !== '')                                            filterObject['pip']              = `PIP : ${state.pip}`
      if(state.pip_min !== '')                                        filterObject['pip_min']          = `PIP min : ${state.pip_min}`
      if(state.pip_max !== '')                                        filterObject['pip_max']          = `PIP max : ${state.pip_max}`
      if(state.ai_min_vni !== '')                                     filterObject['ai_min_vni']       = `AI min : ${state.ai_min_vni}`
      if(state.ai_max_vni !== '')                                     filterObject['ai_max_vni']       = `AI max : ${state.ai_max_vni}`
      if(state.frequency_vni !== '')                                  filterObject['frequency_vni']    = `Fréquence : ${state.frequency_vni}`
			if(state.ti_fixed !== '')                                       filterObject['ti']               = `TI : ${state.ti_fixed}`
      if(state.ti_min !== '')                                         filterObject['ti_min']           = `TI min : ${state.ti_min}`
      if(state.ti_max !== '')                                         filterObject['ti_max']           = `TI max : ${state.ti_max}`
      if(state.trigger_insp !== '')                                   filterObject['trigger_insp']     = `Trigger insp : ${state.trigger_insp}`
      if(state.trigger_exp !== '')                                    filterObject['trigger_exp']      = `Trigger exp : ${state.trigger_exp}`
      if(state.slope !== '')                                          filterObject['slope']            = `Pente : ${state.slope}`
      if(state.time_of_use_vni !== '')                                filterObject['time_of_use_vni']  = `Temps d'utilisation : ${state.time_of_use_vni}`
      if(state.humidifier_vni !== '')                                 filterObject['humidifier_vni']   = 'Humidificateur : '+ i18n.t(`common.${state.humidifier_vni}`)

      return filterObject
    },
    validVniForm(state) {
      const naturalNumber = (value) => typeof value === 'number' && value > 0;

      return state.interface !== '' && naturalNumber(state.time_of_use_vni)
    }
  },
  mutations: {
    update_vni_additional_info_vni(state, value) {
      state.additional_info_vni = value
    },

    update_vni_ai_max_vni(state, value) {
      state.ai_max_vni = parseFloat(value) || ''
    },

    update_vni_ai_min_vni(state, value) {
      state.ai_min_vni = parseFloat(value) || ''
    },

    update_vni_ald_on_list_vni(state, value) {
      state.ald_on_list_vni = value
    },

    update_vni_frequency_vni(state, value) {
      state.frequency_vni = parseFloat(value) || ''
    },

    update_vni_humidifier_vni(state, value) {
      state.humidifier_vni = value
    },

    update_vni_interface(state, value) {
      state.interface = value
    },

    update_vni_mask_type(state, value) {
      state.mask_type = value
    },

    update_vni_mode_vni(state, value) {
      state.mode_vni = value
    },

    update_vni_pep_auto_max_vni(state, value) {
      state.pep_auto_max_vni = parseFloat(value) || ''
    },

    update_vni_pep_auto_min_vni(state, value) {
      state.pep_auto_min_vni = parseFloat(value) || ''
    },

    update_vni_pep_vni(state, value) {
      state.pep_vni = parseFloat(value) || ''
    },

    update_vni_pip_max(state, value) {
      state.pip_max = parseFloat(value) || ''
    },

    update_vni_pip_min(state, value) {
      state.pip_min = parseFloat(value) || ''
    },

    update_vni_pip(state, value) {
      state.pip = parseFloat(value) || ''
    },

    update_vni_slope(state, value) {
      state.slope = parseInt(value) || ''
    },

    update_vni_ti_max(state, value) {
      state.ti_max = parseFloat(value) || ''
    },

    update_vni_ti_min(state, value) {
      state.ti_min = parseFloat(value) || ''
    },

    update_vni_ti_fixed(state, value) {
      state.ti_fixed = parseFloat(value) || ''
    },

    update_vni_time_of_use_vni(state, value) {
      state.time_of_use_vni = value
    },

    update_vni_trigger_exp(state, value) {
      state.trigger_exp = parseInt(value) || ''
    },

    update_vni_trigger_insp(state, value) {
      state.trigger_insp = parseInt(value) || ''
    },

    update_vni_volume(state, value) {
      state.volume = parseInt(value) || ''
    },

    update_vni_with_ald_vni(state, value) {
      state.with_ald_vni = value
    },


    update_vni_dirty(state, value)               {state.dirty        = value }
  },
  actions: {
    resetAllVni({commit}) {
      commit('update_vni_additional_info_vni', '' )
      commit('update_vni_ai_max_vni' ,         '' )
      commit('update_vni_ai_min_vni' ,         '' )
      commit('update_vni_frequency_vni' ,      '' )
      commit('update_vni_humidifier_vni',      false )
      commit('update_vni_interface',           '' )
      commit('update_vni_mode_vni',            'st' )
      commit('update_vni_pep_auto_max_vni' ,   '' )
      commit('update_vni_pep_auto_min_vni' ,   '' )
      commit('update_vni_pep_vni' ,            '' )
      commit('update_vni_pip_max' ,            '' )
      commit('update_vni_pip_min' ,            '' )
      commit('update_vni_pip' ,                '' )
      commit('update_vni_slope' ,              '' )
      commit('update_vni_ti_fixed' ,           '' )
      commit('update_vni_ti_max' ,             '' )
      commit('update_vni_ti_min' ,             '' )
      commit('update_vni_time_of_use_vni',     '< 12H' )
      commit('update_vni_trigger_exp' ,        '' )
      commit('update_vni_trigger_insp' ,       '' )
      commit('update_vni_volume' ,             '' )
      commit('update_vni_with_ald_vni' ,       true )
      commit('update_vni_dirty' ,              false )

    }
  }
}
