<template>
  <div>
    <cm-checkbox
      :values="values"
      :value="value"
      :valid="valid"
      :feedback="feedback"
      :feedback-data="feedbackData"
      :name="name"
      :label="label"
      :options="options"
      @input="onInput"
    ></cm-checkbox>
  </div>
</template>

<script>
import ValidationsMixin from '@/mixins/ValidationsMixin'
import CmCheckbox from '@/components/UI/CmCheckbox'

export default {
  mixins: [ValidationsMixin],
  components: {
    CmCheckbox,
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: function () {
        return {};
      }
    },
    values: {
      type: Array,
      default: () => []
    },
    value: {
      default: () => null
    }
  },

  methods: {
    onInput(v) {
      this.$emit('input', v)
    }
  },

  beforeDestroy() {
    this.$store.commit('o2Renewal/update', { key: 'equipments', value: ['c'] })
  }
}
</script>
