import i18n from '@/plugins/i18n.js'

export default {
  state() {
    return {
      prescriptionSent  : false
    }
  },
  mutations: {
    update_prescription_sent(state, value)     {state.prescriptionSent     = value}
  },
}
