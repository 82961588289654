import i18n from '@/plugins/i18n.js'

export default {
  state() {
    return {
      // ppc
      additional_info_ppc: '',
      ai_max_ppc: '',
      ai_min_ppc: '',
      ald_on_list_ppc: false,
      attachedExams: [],
      frequency_ppc: '',
      humidifier_ppc: false,
      iah: '',
      iah_symptoms: [],
      iah_other_symptoms: '',
      ia: '',
      p_auto_max: '',
      p_auto_min: '',
      p_fixed: '',
      pep_auto_max_ppc: '',
      pep_auto_min_ppc: '',
      pep_ppc: '',
      ppc_type: 'ppc',
      pressure_type: 'fixed',
      with_ald_ppc: false,

      dirty: false,
    }
  },
  getters: {
    validPpcData(state) {
      let filterObject = {}

      if(state.with_ald_ppc !== '')                                    filterObject['with_ald_ppc']     = 'ALD : '+ i18n.t(`common.${state.with_ald_ppc}`);
      if(state.with_ald_ppc === true)                                  filterObject['ald_on_list_ppc']  = 'Sur liste : '+ i18n.t(`common.${state.ald_on_list_ppc}`);
      if(state.iah !== '')                                             filterObject['iah']              = `IAH : ${state.iah}`;
      if(state.iah_symptoms.length > 0)                                filterObject['iahSymptoms']      = `${state.iah_symptoms.length} symptôme(s)`;
      if(state.ia !== '')                                              filterObject['iah']              = `IA : ${state.ia}`;
      if(state.ppc_type !== '')                                        filterObject['ppc_type']         = 'Type de PPC : ' + i18n.t(`askForCare.ppc.${state.ppc_type}`);
      if(state.pressure_type !== '')                                   filterObject['pressure_type']    = 'Type de pression : '+ i18n.t(`askForCare.ppc.${state.pressure_type}`);
      if(state.p_fixed !== '')                                         filterObject['p_fixed']          = `P fixe : ${state.p_fixed}`;
      if(state.p_auto_min !== '')                                      filterObject['p_auto_min']       = `PEP min : ${state.p_auto_min}`;
      if(state.p_auto_max !== '')                                      filterObject['p_auto_max']       = `PEP max : ${state.p_auto_max}`;
      if(state.pep_auto_min_ppc !== '')                                filterObject['pep_auto_min_ppc'] = `PEP min : ${state.pep_auto_min_ppc}`;
      if(state.pep_auto_max_ppc !== '')                                filterObject['pep_auto_max_ppc'] = `PEP max : ${state.pep_auto_max_ppc}`;
      if(state.pep_ppc !== '')                                         filterObject['pep_ppc']          = `Valeur : ${state.pep_ppc}`;
      if(state.ai_min_ppc !== '')                                      filterObject['ai_min_ppc']       = `AI min : ${state.ai_min_ppc}`;
      if(state.ai_max_ppc !== '')                                      filterObject['ai_max_ppc']       = `AI max : ${state.ai_max_ppc}`;
      if(state.frequency_ppc !== '')                                   filterObject['frequency_ppc']    = `Fréquence : ${state.frequency_ppc}`;
      if(state.humidifier_ppc !== '')                                  filterObject['humidifier_ppc']   = 'Humidificateur : '+ i18n.t(`common.${state.humidifier_ppc}`);
      if(state.attachedExams.length > 0)                               filterObject['attachedExams']    = 'Polygraphie/Polysomnographie ajoutée';

      return filterObject
    },
    validPpcForm(state) {
      let validness = false
      const naturalNumber = (value) => typeof value === 'number' && value > 0;

      if (state.ppc_type === 'ppc') {
        if (state.pressure_type === 'fixed') {
          validness = naturalNumber(state.p_fixed)
        } else if (state.pressure_type === 'auto') {
          validness = [state.p_auto_min, state.p_auto_max].every(naturalNumber)
        }
      } else if (state.ppc_type === 'ppc_auto') {
        if (state.pressure_type === 'fixed') {
          validness = [
            state.pep_ppc
          ].every(naturalNumber)
        } else if (state.pressure_type === 'auto') {
          validness = [
            state.pep_auto_min_ppc,
            state.pep_auto_max_ppc
          ].every(naturalNumber)
        }
      }
      return naturalNumber(state.iah) && validness
    }
  },
  mutations: {
    update_ppc_additional_info_ppc(state, value) {state.additional_info_ppc = value},
    update_ppc_ai_max_ppc(state, value)          {state.ai_max_ppc          = parseFloat(value) || ''},
    update_ppc_ai_min_ppc(state, value)          {state.ai_min_ppc          = parseFloat(value) || ''},
    update_ppc_ald_on_list_ppc(state, value)     {state.ald_on_list_ppc     = value},
    update_ppc_attachedExams(state, value)       {state.attachedExams       = value.filter((e) => e ) },    // When a file is deleted the value is [null] . So should remove null value
    update_ppc_frequency_ppc(state, value)       {state.frequency_ppc       = parseFloat(value) || ''},
    update_ppc_humidifier_ppc(state, value)      {state.humidifier_ppc      = value},
    update_ppc_iah_symptoms(state, value)        {state.iah_symptoms        = value },
    update_ppc_iah_other_symptoms(state, value)  {state.iah_other_symptoms  = value },
    update_ppc_iah(state, value)                 {state.iah                 = value },
    update_ppc_ia(state, value)                  {state.ia                  = value },
    update_ppc_p_auto_max(state, value)          {state.p_auto_max          = parseFloat(value) || ''},
    update_ppc_p_auto_min(state, value)          {state.p_auto_min          = parseFloat(value) || ''},
    update_ppc_p_fixed(state, value)             {state.p_fixed             = parseFloat(value) || ''},
    update_ppc_pep_auto_max_ppc(state, value)    {state.pep_auto_max_ppc    = parseFloat(value) || ''},
    update_ppc_pep_auto_min_ppc(state, value)    {state.pep_auto_min_ppc    = parseFloat(value) || ''},
    update_ppc_pep_ppc(state, value)             {state.pep_ppc             = parseFloat(value) || ''},
    update_ppc_ppc_type(state, value)            {state.ppc_type            = value},
    update_ppc_pressure_type(state, value)       {state.pressure_type       = value},
    update_ppc_with_ald_ppc(state, value)        {state.with_ald_ppc        = value},
    update_ppc_dirty(state, value)               {state.dirty               = value }

  },
  actions: {
    resetAllPpc({commit}){
      commit('update_ppc_additional_info_ppc',  ''),
      commit('update_ppc_ai_max_ppc',           ''),
      commit('update_ppc_ai_min_ppc',           ''),
      commit('update_ppc_ald_on_list_ppc',      false),
      commit('update_ppc_attachedExams',        []),
      commit('update_ppc_frequency_ppc',        ''),
      commit('update_ppc_humidifier_ppc',       false),
      commit('update_ppc_iah',                  ''),
      commit('update_ppc_ia',                   ''),
      commit('update_ppc_p_auto_max',           ''),
      commit('update_ppc_p_auto_min',           ''),
      commit('update_ppc_p_fixed',              ''),
      commit('update_ppc_pep_auto_max_ppc',     ''),
      commit('update_ppc_pep_auto_min_ppc',     ''),
      commit('update_ppc_pep_ppc',              ''),
      commit('update_ppc_ppc_type',             'ppc'),
      commit('update_ppc_pressure_type',        'fixed'),
      commit('update_ppc_with_ald_ppc',         false),
      commit('update_ppc_dirty',                false)
    }
  }
}
