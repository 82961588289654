export default {
  data() {
    return {
      debounceTimeoutId: null
    }
  },
  methods: {
    debounce(callback, delay = 500) {
      clearTimeout(this.debounceTimeoutId)

      this.debounceTimeoutId = setTimeout(() => {
        callback()
        this.debounceTimeoutId = null
      }, delay)
    },
  }
}
