export default {
  methods: {
    oneDayMax(key, _hour = true){
      return 0 < key && key <= 24 ? true : false
    },

    oneHourMax(key, _minute = true){
      return 0 < key && key <= 60 ? true : false
    }
  }
}
