<template>
  <header v-on="$listeners" class="cm-stepper-step-header">
    <span class="cm-stepper-step-header-number">
      {{ CmStepper.getStepNumber(id) }}
    </span>

    <slot>
      <span class="cm-stepper-step-header-label">
        {{ label }}
      </span>
    </slot>
  </header>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },

  inject: ['CmStepper']

}
</script>

<style lang="scss">
.cm-stepper-step-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.cm-editable {
    cursor: pointer;
  }

  &.cm-forbidden {
    cursor: not-allowed;
  }

  .cm-stepper-step-header-number {
    display: inline-block;
    position: relative;
    z-index: 5;
    width: 20px;
    height: 20px;
    margin-right: 12px;
    padding: 3px;

    font-size: 11px;
    font-weight: 600;
    text-align: center;
    line-height: 0.9em;

    border-width: 2px;
    border-radius: 100%;
    border-style: solid;

    color: #828282;
    border-color: #828282;
  }

  .cm-stepper-step-header-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: #828282;
  }
}
</style>
