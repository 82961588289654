<template>
  <div class="cm-follow-scroll">
    <div ref="cm-fixed-scroller" :class="{ 'is-fixed': fixed }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offset: {
      type: Number,
      required: false,
      default: () => 0
    }
  },

  data() {
    return {
      fixed: false,
      triggerOffset: 0,
      originalWidth: 0
    }
  },

  created() {
    if (this.$slots.default.length > 1) {
      throw new Error('CmFollowScroll must have single child')
      return
    }

    window.addEventListener('scroll', this.handleScroll)
  },

  mounted() {
    this.triggerOffset = this.$el.getBoundingClientRect().top - this.offset + window.pageYOffset
    this.originalWidth = this.$refs['cm-fixed-scroller'].offsetWidth
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll(event) {
      if (!this.fixed && window.scrollY >= this.triggerOffset) {
        this.fixed = true
        this.applyStyle()
      } else if (this.fixed && window.scrollY <= this.triggerOffset) {
        this.fixed = false
        this.removeStyle()
      }
    },

    applyStyle() {
      const fixedScroller = this.$refs['cm-fixed-scroller']
      fixedScroller.style.width = `${this.originalWidth}px`
      fixedScroller.style.top = `${this.offset}px`
    },

    removeStyle() {
      const fixedScroller = this.$refs['cm-fixed-scroller']
      fixedScroller.style = {}
    }
  }
}
</script>

<style lang="scss">
.cm-follow-scroll {
  display: block;

  .is-fixed {
    position: fixed;
  }
}
</style>
