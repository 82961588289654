<template>
  <section :id="id" :class="stepClasses">
    <cm-step-header :class="headerClasses" @click="isEditable ? onStepClick() : null" :id="id" :label="label">
      <slot name="step-header" v-bind:label="label" v-bind:id="id">
      </slot>
    </cm-step-header>

    <div v-show="isActive" class="cm-stepper-step-content">
      <slot />
    </div>
  </section>
</template>

<script>
import CmStepHeader from '@/components/UI/Stepper/CmStepHeader'

export default {
  components: {
    CmStepHeader: CmStepHeader
  },

  inject: ['CmStepper'],

  props: {
    label: {
      type: String,
      required: true
    },

    id: {
      type: String,
      required: false,
      default: () => `cm-stepper-${Math.random().toString(36).slice(4)}`
    }
  },

  created() {
    this.$set(this.CmStepper.steps, this.id, {
      label: this.label,
      done: false
    })
  },

  computed: {
    stepData() {
      return this.CmStepper.steps[this.id]
    },

    isDone() {
      return this.stepData.done
    },

    isActive() {
      return this.CmStepper.isActive(this.id)
    },

    isEditable() {
      return this.isActive || this.isDone
    },

    stepClasses() {
      return [
        "cm-stepper-step",
        this.isDone && "cm-stepper-step-done",
        this.isActive && "cm-stepper-step-active"
      ]
    },

    headerClasses() {
      return [
        this.isEditable ? "cm-editable" : "cm-forbidden"
      ]
    }
  },

  methods: {
    onStepClick(event) {
      this.CmStepper.setActiveStep(this.id)
    }
  }
}
</script>

<style lang="scss">
.cm-stepper-step {
  .cm-stepper-step-content {
    margin-left: 35px;
  }
}
</style>
