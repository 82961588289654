<template>
  <div id="ppc-symptoms">
    <b-tooltip
      v-if="!hasValues && isPediatric && eordoMandatoryFieldsEnabled"
      id="ia-iah-tooltip"
      :show="true"
      target="ppc-symptoms"
      container="ppc-symptoms"
      placement="right"
      custom-class="text-is-medium is-tooltip"
      triggers="manual"
    >
      Vous devez au moins renseigner une valeur dans l'un des champs IA ou IAH.
    </b-tooltip>

    <cm-checkbox
      v-if="hasValues"
      :values="computedValues"
      :value="value"
      :valid="valid"
      :feedback="feedback"
      :name="name"
      :label="computedLabel"
      :options="options"
      @input="onInput"
    ></cm-checkbox>
  </div>
</template>

<script>
import ValidationsMixin from '@/mixins/ValidationsMixin'
import DebounceMixin from '@/mixins/DebounceMixin'
import CmCheckbox from '@/components/UI/CmCheckbox'


export default {
  mixins: [
    ValidationsMixin,
    DebounceMixin,
  ],
  components: {
    CmCheckbox
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: function() { return {} },
    },
    values: {
      type: Array,
      default: function () {
        return [];
      },
    },
    value: {
      default: () => null
    }
  },

  data() {
    return {
      mounted: false,
      url: this.options.url,
      watchedFields: this.options.watch_fields,
      watchedStore: this.options.watch_source,
      minimum: 0,
      subscribers: [],
      fetchedValues: []
    }
  },

  destroyed() {
    // Unsubscribe all subscribers.
    this.subscribers.forEach(subscriber => subscriber())
  },

  async mounted() {
    // subscribe returns a callback used to unsubscribe.
    // Store the references to unsubscribe when needed (see destroyed() hook)
    this.subscribers.push(
      this.$store.subscribe((mutation, _state) => {
        if (
          mutation.type === `${this.watchedStore}/update`
          && this.watchedFields.includes(mutation.payload.key)
        ) {
          this.debounce(this.callApi, 250)
        }
      })
    )

    await this.callApi()
    this.mounted = true
  },

  computed: {
    computedValues() {
      return this.values.concat(this.fetchedValues)
    },

    computedLabel() {
      return this.label.replace('$1', this.minimum)
    },

    hasValues() {
      return this.computedValues && this.computedValues.length > 0
    },

    isPediatric() {
      return this.$store.state.ppcRenewal.data.age < 16
    },

    eordoMandatoryFieldsEnabled() {
      return this.$store.state.ppcRenewal.data.eordo_mandatory_fields
    }
  },

  methods: {
    async callApi() {
      try {
        const { data } = await this.$axios.get(this.url, { params: this.params() })

        this.fetchedValues = data.values
        this.minimum = data.minimum
        this.$store.commit('ppcRenewal/update', { key: 'symptoms_minimum', value: this.minimum })

        // Reset selected symptoms when changing IAH value
        if (this.mounted) {
          this.$store.commit('ppcRenewal/update', { key: 'iah_symptoms', value: [], validate: false })
        }

      } catch(e) {
        switch(e.response.status) {
          case 400:
            // Ignore bad requests
            break;
          default:
            this.$toasted.error(e.response.message)
        }
      }
    },

    params() {
      let params = {}

      for (const field of this.watchedFields) {
        params[field] = this.$store.state[this.watchedStore].data[field]
      }

      return params
    },

    // Forward input event
    onInput(data) {
      this.$emit('input', data)
    }
  },
}
</script>

<style lang="scss">
#ppc-symptoms {
  // required for bootstrap tooltip to show up........
  min-height: 1px;

  #ia-iah-tooltip {
    transform: translate3d(-50px, 150px, 0px) !important;

    .tooltip-inner {
      max-width: 250px
    }
  }
}
</style>
