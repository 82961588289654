<template>
  <div class="vue-ask-for-care-patho" >
    <div class="divider col-sm-12">
      <div class="row col-sm-12 d-flex">
        <div class="col-sm-4">
          <b-button class="pathology-badge" v-b-toggle.vni-content @click="toggleHead">
            <div class="d-flex">
              <i class="fas fa-angle-double-up 2x" id="toggle-vni-icon"></i>
              <span class="text-is-semibold">{{$t('askForCare.vni.vni')}}</span>
            </div>
          </b-button>
        </div>
        <template v-if="expanded === false || expanded === 'false'">
          <div class="col-sm-8 d-flex justify-content-between" >
            <section>
              <b-badge class="tag" v-for="item in $store.getters.validVniData" :key="item">
                <span class="d-flex">
                  {{item}}
                </span>
              </b-badge>
            </section>
            <div>
              <i class="delete-button far fa-trash-alt 2x icon" @click="emitDestroyForm"></i>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="col-sm-8 d-flex justify-content-end">
            <i class="delete-button far fa-trash-alt 2x icon" @click="emitDestroyForm"></i>
          </div>
        </template>
      </div>
    </div>

    <b-collapse id="vni-content" visible class="row container">
      <article class="col-sm-12 radio-buttons">
        <label>Prescription en rapport avec une affection longue durée ?</label>
        <div class="row col-sm-12">
          <custom-radio-button
            v-for="(option, index) of booleanOptions"
            :key="index"
            :active="isActive(option.value, with_ald_vni)"
            :text="option.text"
            :option-value="option.value"
            v-model="with_ald_vni"
          ></custom-radio-button>
        </div>
      </article>

      <article class="col-sm-12 radio-buttons" v-if="with_ald_vni == true">
        <label>Sur liste ?</label>
        <div class="row col-sm-12">
          <custom-radio-button
            v-for="(option, index) of booleanOptions"
            :key="index"
            :active="isActive(option.value, ald_on_list_vni)"
            :text="option.text"
            :option-value="option.value"
            v-model="ald_on_list_vni"
          ></custom-radio-button>
        </div>
      </article>

      <article class="col-sm-12 radio-buttons row">
        <div class=" col-sm-6 row">
          <b-form-group
            :state="interfaceVal"
            :invalid-feedback="selectFeedback()"
          >
            <label class="col-sm-12">Interface <sup class="required">requis</sup></label>
            <div v-for="(option, index) of interfaceOptions" class="col-sm-12">
              <custom-radio-button
                :key="index"
                :active="isActive(option.value, interface)"
                :text="option.text"
                :option-value="option.value"
                v-model="interface"
                class="left"
              ></custom-radio-button>
            </div>
          </b-form-group>
        </div>

        <div class="col-sm-6 row started-content">
          <template v-if="interface === 'mask'">
            <label class="col-sm-12">Type de masque <sup class="required">requis</sup></label>
            <custom-radio-button
              v-for="(option, index) of maskTypeOptions"
              :key="index"
              :active="isActive(option.value, mask_type)"
              :text="option.text"
              :option-value="option.value"
              v-model="mask_type"
            ></custom-radio-button>
          </template>
        </div>
      </article>

      <section class="col-sm-12">
        <label>Réglages</label>
        <div class="row">
          <article class="col-sm-4 mt-0">
            <b-form-group>
              <b-form-select
                @change="otherSettingsChoice"
                :options="modeVniOptions"
                v-model="mode_vni" :id="`vni-mode`" >
              </b-form-select>
            </b-form-group>
          </article>

          <section class="col-sm-12">
            <div v-if="mode_vni === 'st'" class="row">
              <b-form-group
                class="col-sm-4"
                :state="pep_vniVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> PEP </label>
                <b-input-group>
                  <input
                    :id="`vni-st-pep`"
                    class="input-placeholders-right"
                    v-model="pep_vni"
                    type="text"
                  >
                  <span class="placeholder">cmH2O</span>
                </b-input-group>
              </b-form-group>

              <b-form-group
                class="col-sm-4"
                :state="pipVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> PIP </label>
                <b-input-group>
                  <input
                    :id="`vni-st-pip`"
                    class="input-placeholders-right"
                    v-model="pip"
                    type="text"
                  >
                  <span class="placeholder">cmH2O</span>
                </b-input-group>
              </b-form-group>

              <b-form-group
                class="col-sm-4"
                :state="frequency_vniVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> Fréquence </label>
                <b-form-input
                  :id="`vni-st-frequency`"
                  v-model="frequency_vni"
                  type="text"
                >
                </b-form-input>
              </b-form-group>

              <b-form-group
                class="col-sm-4"
                :state="ti_minVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> TI min </label>
                <b-form-input
                  :id="`vni-st-ti-min`"
                  v-model="ti_min"
                  type="text"
                >
                </b-form-input>
              </b-form-group>

              <b-form-group
                class="col-sm-4"
                :state="ti_maxVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> TI max </label>
                <b-form-input
                  :id="`vni-st-ti-max`"
                  v-model="ti_max"
                  type="text"
                >
                </b-form-input>
              </b-form-group>

              <b-form-group
                class="col-sm-4"
              >
                <label> Trigger inspiratoire </label>
                <b-form-input v-model="trigger_insp" :id="`vni-st-trigg-inspi`" type="text"></b-form-input>
              </b-form-group>

              <b-form-group
                class="col-sm-4"
              >
                <label> Trigger expiratoire </label>
                <b-form-input v-model="trigger_exp" :id="`vni-st-trigg-expi`" type="text"></b-form-input>
              </b-form-group>

              <b-form-group class="col-sm-4">
                <label> Pente </label>
                <b-form-input v-model="slope" :id="`vni-st-slope`" type="text"></b-form-input>
              </b-form-group>
            </div>

            <div v-if="mode_vni === 'avaps'" class="row">
              <b-form-group class="col-sm-4">
                <label> Volume </label>
                <b-input-group>
                  <b-form-input v-model="volume" :id="`vni-avaps-volume`" class="input-placeholders-right" type="text"></b-form-input>
                  <span class="placeholder">ml</span>
                </b-input-group>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="pep_vniVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> PEP </label>
                <b-input-group>
                  <input
                    id="vni-avaps-pep"
                    class="input-placeholders-right"
                    v-model="pep_vni"
                    type="text"
                  >
                  <span class="placeholder">cmH2O</span>
                </b-input-group>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="pip_minVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> PIP min </label>
                <b-input-group>
                  <input
                    id="vni-avaps-pip-min"
                    class="input-placeholders-right"
                    v-model="pip_min"
                    type="text"
                  >
                  <span class="placeholder">cmH2O</span>
                </b-input-group>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="pip_maxVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> PIP max </label>
                <b-input-group>
                  <input
                    id="vni-avaps-pip-max"
                    class="input-placeholders-right"
                    v-model="pip_max"
                    type="text"
                  >
                  <span class="placeholder">cmH2O</span>
                </b-input-group>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="frequency_vniVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> Fréquence </label>
                <b-form-input
                  :id="`vni-avaps-frequency`"
                  v-model="frequency_vni"
                  type="text"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="tiFixedVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> TI </label>
                <b-form-input
                  :id="`vni-avaps-ti-fixe`"
                  v-model="ti_fixed"
                  type="text"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
              >
                <label> Trigger inspiratoire </label>
                <b-form-input v-model="trigger_insp" :id="`vni-avaps-trigg-inspi`" type="text"></b-form-input>
              </b-form-group>
            </div>
            <div v-if="mode_vni === 'avaps_ae'" class="row">
              <b-form-group class="col-sm-4">
                <label> Volume </label>
                <b-input-group>
                  <b-form-input v-model="volume" :id="`vni-avaps-ae-volume`" class="input-placeholders-right" type="text"></b-form-input>
                  <span class="placeholder">ml</span>
                </b-input-group>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="pep_auto_min_vniVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> PEP min </label>
                <b-input-group>
                  <input
                    id="vni-avaps-ae-pep-min"
                    class="input-placeholders-right"
                    v-model="pep_auto_min_vni"
                    type="text"
                  >
                  <span class="placeholder">cmH2O</span>
                </b-input-group>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="pep_auto_max_vniVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> PEP max </label>
                <b-input-group>
                  <input
                    id="vni-avaps-ae-pep-max"
                    class="input-placeholders-right"
                    v-model="pep_auto_max_vni"
                    type="text"
                  >
                  <span class="placeholder">cmH2O</span>
                </b-input-group>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="pip_minVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> PIP min </label>
                <b-input-group>
                  <input
                    id="vni-avaps-ae-pip-min"
                    class="input-placeholders-right"
                    v-model="pip_min"
                    type="text"
                  >
                  <span class="placeholder">cmH2O</span>
                </b-input-group>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="pip_maxVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> PIP max </label>
                <b-input-group>
                  <input
                    id="vni-avaps-ae-pip-max"
                    class="input-placeholders-right"
                    v-model="pip_max"
                    type="text"
                  >
                  <span class="placeholder">cmH2O</span>
                </b-input-group>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="frequency_vniVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> Fréquence </label>
                <b-form-input
                  :id="`vni-avaps-ae-frequency`"
                  v-model="frequency_vni"
                  type="text"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="tiFixedVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> TI </label>
                <b-form-input
                  :id="`vni-avaps-ae-ti-fixe`"
                  v-model="ti_fixed"
                  type="text"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
              >
                <label> Trigger inspiratoire </label>
                <b-form-input v-model="trigger_insp" :id="`vni-avaps-ae-trigg-inspi`" type="text"></b-form-input>
              </b-form-group>
            </div>
            <div v-if="mode_vni === 'vac'" class="row">
              <b-form-group class="col-sm-4">
                <label> Volume </label>
                <b-input-group>
                  <b-form-input v-model="volume" :id="`vni-vac-volume`" class="input-placeholders-right" type="text"></b-form-input>
                  <span class="placeholder">ml</span>
                </b-input-group>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="pep_vniVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> PEP </label>
                <b-input-group>
                  <input
                    id="vni-vac-pep"
                    class="input-placeholders-right"
                    v-model="pep_vni"
                    type="text"
                  >
                  <span class="placeholder">cmH2O</span>
                </b-input-group>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="frequency_vniVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> Fréquence </label>
                <b-form-input
                  :id="`vni-vac-frequency`"
                  v-model="frequency_vni"
                  type="text"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="tiFixedVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> TI </label>
                <b-form-input
                  :id="`vni-vac-ti-fixe`"
                  v-model="ti_fixed"
                  type="text"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
              >
                <label> Trigger inspiratoire </label>
                <b-form-input v-model="trigger_insp" :id="`vni-vac-trigg-inspi`" type="text"></b-form-input>
              </b-form-group>
            </div>
            <div v-if="mode_vni === 'ai_no_vs'" class="row">
              <b-form-group
                class="col-sm-4"
                :state="pep_vniVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> PEP </label>
                <b-input-group>
                  <input
                    id="vni-ai-no-vs-pep"
                    class="input-placeholders-right"
                    v-model="pep_vni"
                    type="text"
                  >
                  <span class="placeholder">cmH2O</span>
                </b-input-group>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="ai_min_vniVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> AI min </label>
                <b-form-input
                  :id="`vni-ai-no-vs-ai-min`"
                  v-model="ai_min_vni"
                  type="text"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="frequency_vniVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> Fréquence </label>
                <b-form-input
                  :id="`vni-ai-no-vs-frequency`"
                  v-model="frequency_vni"
                  type="text"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="ti_minVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> TI min </label>
                <b-form-input
                  :id="`vni-ai-no-vs-ti-min`"
                  v-model="ti_min"
                  type="text"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="ti_maxVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> TI max </label>
                <b-form-input
                  :id="`vni-ai-no-vs-ti-max`"
                  v-model="ti_max"
                  type="text"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
              >
                <label> Trigger inspiratoire </label>
                <b-form-input v-model="trigger_insp" :id="`vni-ai-no-vs-trigg-inspi`" type="text"></b-form-input>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
              >
                <label> Trigger expiratoire </label>
                <b-form-input v-model="trigger_exp" :id="`vni-ai-no-vs-trigg-expi`" type="text"></b-form-input>
              </b-form-group>
              <b-form-group class="col-sm-4">
                <label> Pente </label>
                <b-form-input v-model="slope" :id="`vni-ai-no-vs-slope`" type="text"></b-form-input>
              </b-form-group>
            </div>
            <div v-if="mode_vni === 'ai_and_vs'" class="row">
              <b-form-group class="col-sm-4">
                <label> Volume </label>
                <b-input-group>
                  <b-form-input v-model="volume" :id="`vni-ai-and-vs-volume`" class="input-placeholders-right" type="text"></b-form-input>
                  <span class="placeholder">ml</span>
                </b-input-group>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="pep_vniVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> PEP </label>
                <b-input-group>
                  <input
                    id="vni-ai-and-vs-pep"
                    class="input-placeholders-right"
                    v-model="pep_vni"
                    type="text"
                  >
                  <span class="placeholder">cmH2O</span>
                </b-input-group>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="ai_min_vniVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> AI min </label>
                <b-form-input
                  :id="`vni-ai-and-vs-ai-min`"
                  v-model="ai_min_vni"
                  type="text"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="ai_max_vniVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> AI max </label>
                <b-form-input
                  :id="`vni-ai-and-vs-ai-max`"
                  v-model="ai_max_vni"
                  type="text"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="frequency_vniVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> Fréquence </label>
                <b-form-input
                  :id="`vni-ai-and-vs-frequency`"
                  v-model="frequency_vni"
                  type="text"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="ti_minVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> TI min </label>
                <b-form-input
                  :id="`vni-ai-and-vs-ti-min`"
                  v-model="ti_min"
                  type="text"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
                :state="ti_maxVal"
                :invalid-feedback="numberFeedback()"
              >
                <label> TI max </label>
                <b-form-input
                  :id="`vni-ai-and-vs-ti-max`"
                  v-model="ti_max"
                  type="text"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
              >
                <label> Trigger inspiratoire </label>
                <b-form-input v-model="trigger_insp" :id="`vni-ai-and-vs-trigg-inspi`" type="text"></b-form-input>
              </b-form-group>
              <b-form-group
                class="col-sm-4"
              >
                <label> Trigger expiratoire </label>
                <b-form-input v-model="trigger_exp" :id="`vni-ai-and-vs-trigg-expi`" type="text"></b-form-input>
              </b-form-group>
              <b-form-group class="col-sm-4">
                <label> Pente </label>
                <b-form-input v-model="slope" :id="`vni-ai-and-vs-slope`" type="text"></b-form-input>
              </b-form-group>
            </div>
          </section>
       </div>
      </section>

      <article class="col-sm-4 radio-buttons">
        <label> Temps d'utilisation <sup class="required">requis</sup></label>
        <div class="row col-sm-12">
          <custom-radio-button
            v-for="(option, index) of timeOfUseOptions"
            :key="index"
            :active="isActive(option.value, time_of_use_vni)"
            :text="option.text"
            :option-value="option.value"
            v-model="time_of_use_vni"
            class="col-sm-6"
          >
          </custom-radio-button>
        </div>
      </article>

      <article class="col-sm-12 radio-buttons">
        <label>Humidificateur</label>
        <div class="row col-sm-12">
          <custom-radio-button
            v-for="(option, index) of booleanOptions"
            :key="index"
            :active="isActive(option.value, humidifier_vni)"
            :text="option.text"
            :option-value="option.value"
            v-model="humidifier_vni"
          ></custom-radio-button>
        </div>
      </article>

      <article class="col-sm-12">
        <label for="additional_info_vni">Informations complémentaires</label>
        <b-form-textarea
          id="additional_info_vni"
          v-model="additional_info_vni"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </article>
    </b-collapse>
  </div>
</template>

<script>
import CustomRadioButton from '@/components/askForCare/CustomRadioButton'
import AskForCareMixin from '@/mixins/AskForCareMixin'
import StrValidation from '@/mixins/StrValidation'
import IntValidation from '@/mixins/IntValidation'


export default {
  components: {
    CustomRadioButton
  },
  mixins: [
    AskForCareMixin,
    StrValidation,
    IntValidation
  ],
  destroyed() {
    this.$store.dispatch('resetAllVni')
  },
  data() {
    return {
      timeOfUseOptions: [
        { text: "< 12h", value: "< 12H" },
        { text: "≥ 12h", value: "12H < > 16H" },
        { text: "≥ 16h", value: "> 16H" }
      ],
      booleanOptions: [
        { text: this.$t('common.true'), value: true},
        { text: this.$t('common.false'), value: false}
      ],
      interfaceOptions: [
        { text: this.$t('askForCare.vni.mask'),        value: 'mask'},
        { text: this.$t('askForCare.vni.tracheotomy'), value: 'tracheotomy'},

      ],
      modeVniOptions: [
        { text: 'Non défini',                        value: null },
        { text: this.$t('askForCare.vni.st'),        value: 'st' },
        { text: this.$t('askForCare.vni.avaps'),     value: 'avaps' },
        { text: this.$t('askForCare.vni.avaps_ae'),  value: 'avaps_ae' },
        { text: this.$t('askForCare.vni.vac'),       value: 'vac' },
        { text: this.$t('askForCare.vni.ai_no_vs'),  value: 'ai_no_vs' },
        { text: this.$t('askForCare.vni.ai_and_vs'), value: 'ai_and_vs' },
      ],
      maskTypeOptions:[
        { text: this.$t('askForCare.vni.nasal'),  value: 'nasal'},
        { text: this.$t('askForCare.vni.facial'), value: 'facial'}
      ],
      expanded: this.$store.state.mainCareForm.expandedVni
    }
  },
  computed: {
    // All Getters/Setters
    additional_info_vni: {
      get(){
        return this.$store.state.vniCareForm.additional_info_vni
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_additional_info_vni', value)
      }
    },
    ald_on_list_vni: {
      get(){
        return this.$store.state.vniCareForm.ald_on_list_vni
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_ald_on_list_vni', value)
      }
    },
    ai_max_vni: {
      get(){
        return this.$store.state.vniCareForm.ai_max_vni
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_ai_max_vni', this.textToFloat(value))
      }
    },
    ai_min_vni: {
      get(){
        return this.$store.state.vniCareForm.ai_min_vni
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_ai_min_vni', this.textToFloat(value))
      }
    },
    frequency_vni: {
      get(){
        return this.$store.state.vniCareForm.frequency_vni
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_frequency_vni', this.textToFloat(value))
      }
    },
    humidifier_vni: {
      get(){
        return this.$store.state.vniCareForm.humidifier_vni
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_humidifier_vni', value)
      }
    },
    interface: {
      get(){
        return this.$store.state.vniCareForm.interface
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_interface', value)
      }
    },
    mask_type: {
      get(){
        return this.$store.state.vniCareForm.mask_type
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_mask_type', value)
      }
    },
    mode_vni: {
      get(){
        return this.$store.state.vniCareForm.mode_vni
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_mode_vni', value)
      }
    },
    pep_auto_max_vni: {
      get(){
        return this.$store.state.vniCareForm.pep_auto_max_vni
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_pep_auto_max_vni', this.textToFloat(value))
      }
    },
    pep_auto_min_vni: {
      get(){
        return this.$store.state.vniCareForm.pep_auto_min_vni
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_pep_auto_min_vni', this.textToFloat(value))
      }
    },
    pep_vni: {
      get(){
        return this.$store.state.vniCareForm.pep_vni
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_pep_vni', this.textToFloat(value))
      }
    },
    pip: {
      get(){
        return this.$store.state.vniCareForm.pip
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_pip', this.textToFloat(value))
      }
    },
    pip_max: {
      get(){
        return this.$store.state.vniCareForm.pip_max
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_pip_max', this.textToFloat(value))
      }
    },
    pip_min: {
      get(){
        return this.$store.state.vniCareForm.pip_min
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_pip_min', this.textToFloat(value))
      }
    },
    slope: {
      get(){
        return this.$store.state.vniCareForm.slope
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_slope', value)
      }
    },
    ti_fixed: {
      get(){
        return this.$store.state.vniCareForm.ti_fixed
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_ti_fixed', this.textToFloat(value))
      }
    },
    ti_max: {
      get(){
        return this.$store.state.vniCareForm.ti_max
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_ti_max', this.textToFloat(value))
      }
    },
    ti_min: {
      get(){
        return this.$store.state.vniCareForm.ti_min
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_ti_min', this.textToFloat(value))
      }
    },
    time_of_use_vni: {
      get(){
        return this.$store.state.vniCareForm.time_of_use_vni
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_time_of_use_vni', value)
      }
    },
    trigger_exp: {
      get(){
        return this.$store.state.vniCareForm.trigger_exp
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_trigger_exp', value)
      }
    },
    trigger_insp: {
      get(){
        return this.$store.state.vniCareForm.trigger_insp
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_trigger_insp', value)
      }
    },
    volume: {
      get(){
        return this.$store.state.vniCareForm.volume
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_volume', value)
      }
    },
    with_ald_vni: {
      get(){
        return this.$store.state.vniCareForm.with_ald_vni
      },
      set(value){
        this.$store.commit('update_vni_dirty', true)
        this.$store.commit('update_vni_with_ald_vni', value)
      }
    },

    // Validation
    ai_max_vniVal(){
      return this.emptyOrValid(this.ai_max_vni)
    },
    ai_min_vniVal(){
      return this.emptyOrValid(this.ai_min_vni)
    },
    frequency_vniVal(){
      return this.emptyOrValid(this.frequency_vni)
    },
    interfaceVal(){
      let choice = []
      this.interfaceOptions.map(option => choice.push(option.value))
      return (!this.$store.state.mainCareForm.sentPathologies.includes('vni') && this.interface === '') || choice.includes(this.interface)
    },
    pep_auto_max_vniVal(){
      return this.emptyOrValid(this.pep_auto_max_vni)
    },
    pep_auto_min_vniVal(){
      return this.emptyOrValid(this.pep_auto_min_vni)
    },
    pep_vniVal(){
      return this.emptyOrValid(this.pep_vni)
    },
    pipVal(){
      return this.emptyOrValid(this.pip)
    },
    pip_maxVal(){
      return this.emptyOrValid(this.pip_max)
    },
    pip_minVal(){
      return this.emptyOrValid(this.pip_min)
    },
    slopeVal(){
      return this.slope === '' || this.onlyInt(this.slope)
    },
    tiFixedVal(){
      return this.emptyOrValid(this.ti_fixed)
    },
    ti_maxVal(){
      return this.emptyOrValid(this.ti_max)
    },
    ti_minVal(){
      return this.emptyOrValid(this.ti_min)
    },
    trigger_expVal(){
      return this.trigger_exp === '' || this.onlyLetter(this.trigger_exp)
    },
    trigger_inspVal(){
      return this.trigger_insp === '' || this.onlyLetter(this.trigger_insp)
    },
    volumeVal(){
      return this.volume === '' || this.onlyInt(this.volume)
    },
  },
  watch:{
   interface(){
      if(this.interface == 'mask'){
        this.mask_type = 'nasal'
        this.$store.commit('update_vni_mask_type', 'nasal')
      }
      else{
        this.mask_type = ''
        this.$store.commit('update_vni_mask_type', '')
      }
    },
    mode_vni(newVal, oldVal) {
    if(oldVal && !this.isVniModeClean())
      this.$toasted.info(`Les valeurs définies dans le réglage ${this.$t('askForCare.vni.'+oldVal)} ont été effacées`, {duration: 5000 })
    }
  },
  methods: {
    isActive(value, stateValue) {
      return (value === stateValue)
    },
    isVniModeClean() {
     return [this.ai_max_vni, this.ai_min_vni, this.frequency_vni, this.pep_auto_max_vni, this.pep_auto_min_vni, this.pep_vni, this.pip, this.pip_max, this.pip_min, this.slope, this.ti_fixed, this.ti_max, this.ti_min, this.trigger_exp, this.trigger_insp, this.volume].every( input => input === '')
    },
    toggleHead(){
      this.$store.commit('update_main_expandedVni', !this.expanded)

      this.expanded = !this.expanded
      if(this.expanded){
        $('#toggle-vni-icon').removeClass('fa-angle-double-down')
        $('#toggle-vni-icon').addClass('fa-angle-double-up')
      }
      else{
        $('#toggle-vni-icon').removeClass('fa-angle-double-up')
        $('#toggle-vni-icon').addClass('fa-angle-double-down')
      }
    },
    otherSettingsChoice(e) {
      this.ai_max_vni       = ''
      this.ai_min_vni       = ''
      this.frequency_vni    = ''
      this.pep_auto_max_vni = ''
      this.pep_auto_min_vni = ''
      this.pep_vni          = ''
      this.pip              = ''
      this.pip_max          = ''
      this.pip_min          = ''
      this.slope            = ''
      this.ti_fixed         = ''
      this.ti_max           = ''
      this.ti_min           = ''
      this.trigger_exp      = ''
      this.trigger_insp     = ''
      this.volume           = ''
    }
  }
}
</script>
