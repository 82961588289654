<template>
  <b-form-group
    class="cm-checkbox"
    :state="shouldValidate"
    :invalid-feedback="invalidFeedback"
  >
    <label v-if="label !== ''" class="row">
      {{ label }}
      <sup v-if="options.required" class="required"> requis</sup>
    </label>
    <b-form-checkbox-group
      class="row"
      :class="{ 'is-column': !inline }"
      :options="properties"
      :state="shouldValidate"
      :invalid-feedback="invalidFeedback"
      name="o2-short-equipement"
      :required="options.required"
      :checked="selectedValues"
      @input="onInput"
    ></b-form-checkbox-group>
  </b-form-group>
</template>

<script>
import ValidationsMixin from '@/mixins/ValidationsMixin'

export default {
  mixins: [ValidationsMixin],
  props: {
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: function () { return {} },
    },
    name: {
      type: String,
      required: true
    },
    values: {
      type: Array,
      default: function () {
        return [];
      },
    },
    value: {
      required: false,
      default: () => null
    }
  },

  destroyed() {
    this.$emit('destroyed', { name: this.name })
  },

  data() {
    return {
      inline: this.options.inline === undefined ? true : this.options.inline,
      selectedValues: (Array.isArray(this.value) ? this.value : [this.value]) || this.options.default || [],
      asArray: this.options.array === undefined ? true : this.options.array
    }
  },

  computed: {
    properties() {
      return this.values.map(value => {
        return { value: value.value, text: value.label, disabled: value.disabled }
      })
    }
  },

  methods: {
    onInput(data) {
      if(
        this.selectedValues.length === data.length &&
        this.selectedValues
          .filter(value => !data.includes(value))
          .concat(data.filter(value => !this.selectedValues.includes(value)))
          .length > 0
      ) return;

      this.dirty = true
      this.$emit('input', { name: this.name, value: this.formatValue(data) })
    },
    formatValue(data) {
      if (this.asArray) {
        return data
      } else {
        if (data.length === 1) {
          return data[0]
        }
        return false
      }
    }
  }
}
</script>


<style lang="scss">
.cm-checkbox {
  .is-column {
    flex-direction: column;
  }

  .custom-control-input.is-valid {
    ~ .custom-control-label {
      color: #262626;

      &::before {
        border-color: #262626;
      }
    }

    &:focus {
      ~ .custom-control-label {
        &::before {
          box-shadow: 0 0 0 0.2rem rgba(33, 30, 129, 0.25);
        }
      }
    }
  }
}
</style>
