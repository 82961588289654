<template>
  <aside class="cm-stepper">
    <nav class="cm-stepper-nav">
      <slot />
    </nav>
  </aside>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: false
    }
  },

  data() {
    return {
      CmStepper: {
        steps: {},
        getStepNumber: this.getStepNumber,
        getStepIndex: this.getStepIndex,
        setActiveStep: this.setActiveStep,
        isActive: this.isActive
      }
    }
  },

  provide() {
    return { CmStepper: this.CmStepper }
  },

  watch: {
    value: {
      // Sets all steps before the current index to done.
      handler(newValue, oldValue) {
        // Only recompute if the new value is higher than the old value
        // because a step can only be done if the user moved forward.
        if (newValue > oldValue) {
          for (const stepId in this.CmStepper.steps) {
            if (
              !this.CmStepper.steps[stepId].done
                && this.getStepIndex(stepId) < newValue
            ) {
              this.$set(this.CmStepper.steps[stepId], 'done', true)
            }
          }
        }
      }
    }
  },

  computed: {
    index: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    getStepIndex(id) {
      return Object.keys(this.CmStepper.steps).indexOf(id)
    },

    getStepNumber(id) {
      return this.getStepIndex(id) + 1
    },

    isActive(id) {
      return this.getStepIndex(id) === this.index
    },

    setActiveStep(id) {
      this.index = this.getStepIndex(id)
    }
  }
}
</script>

<style lang="scss">
.cm-stepper {
  .cm-stepper-nav {
    width: 100%;
  }

  .cm-stepper-step {
    position: relative;
    margin-bottom: 10px;
    z-index: 5;

    &:last-of-type:after {
      content: none;
    }

    &:after {
      position: absolute;
      display: block;
      content: ' ';
      background-color: #d9d9d9;
      width: 2px;
      left: 9px; // (.cm-stepper-step-header-number.width / 2) - (this.width / 2)
      top: 20px; // .cm-stepper-step-header-number.height
      bottom: -14px;
      z-index: 2;
    }

    &.cm-stepper-step-active {
      .cm-stepper-step-header-label {
        color: #4a61e3;
      }
    }

    &.cm-stepper-step-done {
      .cm-stepper-step-header-label {
        color: #000000;
      }

      &:after {
        background-color: #4a61e3;
      }
    }

    &.cm-stepper-step-active, &.cm-stepper-step-done {
      .cm-stepper-step-header-number {
        color: #4a61e3;
        border-color: #4a61e3;
      }
    }
  }
}

</style>
