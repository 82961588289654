<template>
  <b-form-group
    :state="shouldValidate"
    :invalid-feedback="invalidFeedback"
  >
    <label class="row" :for="id">
      {{ label }}
      <sup v-if="options.required" class="required"> requis</sup>
    </label>
    <b-input-group class="row">
      <b-form-input
        :id="id"
        lang="en"
        class="input-placeholders-right"
        type="text"
        :disabled="options.disabled"
        :required="options.required"
        :state="shouldValidate"
        :value="inputValue"
        @input="onInput"
      ></b-form-input>
      <span class="placeholder">{{ options.unit }}</span>
    </b-input-group>
  </b-form-group>
</template>

<script>
import ValidationsMixin from "@/mixins/ValidationsMixin";

export default {
  mixins: [ValidationsMixin],
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: function () {
        return {};
      }
    },
    values: {
      type: Array,
      default: () => []
    },
    value: {
      default: () => null
    }
  },

  destroyed() {
    this.$emit('destroyed', { name: this.name })
  },

  data() {
    return {
      id: Math.random().toString(36).slice(4),
      inputValue: this.value || this.options.default
    }
  },

  methods: {
    onInput(value) {
      this.dirty = true
      this.$emit('input', { name: this.name, value })
      this.$emit('dirty')
    }
  }
}
</script>
