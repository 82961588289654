<template>
  <span class="cm-radio-button">
    <div v-if="label" class="row label-height">
      <label :for="name">
        {{ label }}
        <sup v-if="options.required" class="required"> requis</sup>
      </label>
    </div>
    <div class="row" :class="{ 'is-column': !inline }">
      <template v-for="field of values">
        <label
          :class="[
            'cm-radio-button-choice',
            { disabled: field.disabled, active: field.value === selectedValue.value },
          ]"
          :value="field.value"
          :key="field.label"
          :valid="shouldValidate"
          :invalid-feedback="invalidFeedback"
          @input="onInput(field)"
        >
          <span class="radio__input">
            <input
              :disabled="field.disabled"
              type="radio"
              name="custom-radio-button-input"
              :checked="field.value === selectedValue.value"
            />
            <span class="radio__control"></span>
            <span class="radio__label">{{ field.label }}</span>
          </span>
        </label>
      </template>
    </div>
  </span>
</template>

<script>
import ValidationsMixin from "@/mixins/ValidationsMixin";

export default {
  mixins: [ValidationsMixin],
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    options: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    name: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
    value: {
      required: false,
      default: () => null,
    },
  },

  destroyed() {
    this.$emit("destroyed", { name: this.name });
  },

  data() {
    return {
      isArray: Array.isArray(this.value || this.options.default),
      disabled: this.options.disabled || false,
      inline: this.options.inline === undefined ? true : this.options.inline,
      selectedValue:
        (this.isArray ? this.value[0] : this.value) || this.options.default,
    };
  },

  watch: {
    value(newVal, _) {
      this.selectedValue = newVal
    }
  },

  methods: {
    onInput(field) {
      if (field.disabled) return;

      if (this.selectedValue === field.value) return;

      this.dirty = true;
      this.selectedValue = field.value;
      this.$emit("input", {
        name: this.name,
        value: this.isArray ? [this.selectedValue] : this.selectedValue,
      });
    },
  },
};
</script>

<style lang="scss">
.cm-radio-button {
  .label-height {
    min-height: 32px;
  }

  .is-column {
    flex-direction: column;
  }

  .cm-radio-button-choice {
    display: inline-block;
    color: #9d9d9d;
    border: solid 1px #cdcdcd;
    background-color: white;
    padding: 11px 20px 11px 15px;
    border-radius: 4px;
    height: 40px;
    margin: 0 15px 9px 0;

    &.disabled {
      background-color: #e6e6e6;
    }

    &.active {
      border-color: #ebedf9;
      background-color: #ebedf9;
      color: #4a61e3;

      .radio__control {
        background-color: white;
        border: solid 5px #4a61e3;
      }
    }

    .radio__input {
      input {
        display: none;

        &:checked + .radio__control {
          background-color: white;
          border: solid 5px #4a61e3;
        }
      }
    }

    .radio__control {
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: 5px;
      border-radius: 50%;
      border: 0.1em solid currentColor;
    }

    .radio__label {
      position: relative;
      line-height: 1;
      left: 0;
      bottom: 2px;
    }
  }
}
</style>
