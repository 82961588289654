<template>
  <span v-if="active">
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    root: {
      type: Boolean,
      required: false,
      default: () => false
    },

    meta: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },

    conditions: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },

    storeNamespace: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      watchedKeys: Object.keys(this.conditions),
      fields: this.meta.fields || []
    }
  },

  mounted() {
    if (this.active) {
      this.setActive()
    }
  },

  destroyed() {
    this.updateActiveFields(false)
  },

  watch: {
    active(newValue, _oldValue) {
      this.updateActiveFields(newValue)
    }
  },

  computed: {
    active() {
      return this.root || this.watchedKeys.every(key => {
        const comparedTo = this.$store.state[this.storeNamespace].data[key];

        if (Array.isArray(this.conditions[key])) {
          return this.conditions[key].every(condition => comparedTo.includes(condition))
        } else {
         if (this.conditions[key] && typeof this.conditions[key] === 'object') {
            const keys = Object.keys(this.conditions[key])

            return keys.every(condition => {
              const value = this.conditions[key][condition]

              switch(condition) {
                case 'include':
                  return comparedTo.indexOf(value) !== -1
                case 'exclude':
                  return comparedTo.indexOf(value) === -1
                case 'gte':
                  return comparedTo >= value
                case 'lte':
                  return comparedTo <= value
                default:
                  return false
              }
            })
          } else {
            return comparedTo === this.conditions[key]
          }
        }
      })
    }
  },

  methods: {
    setActive() {
      this.updateActiveFields(true)
    },

    updateActiveFields(active) {
      if (this.fields.length > 0) {
        this.$store.commit(`${this.storeNamespace}/updateActiveFields`, { fieldNames: this.fields,  active })
      }
    }
  }
}
</script>
