import {
  isBoolean,
  isInteger,
  onlyTwoDigitsOneDecimal,
  isDate,
  isNotEmptyString,
  isNotEmptyArray
} from '@/utils/validate.js'
import { selectFeedback, numberFeedback } from '@/utils/feedbacks.js'

export default {
  namespaced: true,
  state() {
    const defaults = {
      additional_info: '',
      ald_on_list: false,
      arterial_blood_gas_date_test: '',
      arterial_blood_gas_pa_co2: '',
      arterial_blood_gas_pa_o2: '',
      arterial_blood_gas_ph: '',
      arterial_blood_gas_sa_o2: '',
      arterial_blood_gas_test: 'first_rest_natural_breathing_test',
      care_type: 'transient_respiratory_failure',
      consumables: ['cannula'],
      equipments: ['c'],
      flow_day: '',
      avf_flow: '',
      flow_discontinuous: false,
      flow_effort: '',
      flow_night: '',
      humidifier: false,
      o2_type: 'short_term',
      once_renewal: true,
      preferred_device: '',
      duration: 3,
      start_date: new Date().toISOString().substr(0, 10),
      duration_unit: 'months',
      time_of_use: '',
      walker: false,
      with_ald: false
    }

    return {
      defaults,
      initial: Object.assign({}, defaults),
      data: Object.assign({}, defaults),
      activeFields: [],
      deleted: false,
      deletedAt: null,
      isValid: true,
      dataBadges: {},
      validations: {
        ald_on_list: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return data.with_ald ? isBoolean(val) : true
          }
        },
        arterial_blood_gas_date_test: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return isDate(val)
          }
        },
        arterial_blood_gas_ph: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return val !== '' ? isNotEmptyString(val) : true
          }
        },
        arterial_blood_gas_pa_co2: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return val !== '' ? isNotEmptyString(val) : true
          }
        },
        arterial_blood_gas_pa_o2: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return val !== '' ? isNotEmptyString(val) : true
          },
        },
        arterial_blood_gas_sa_o2: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return val !== '' ? isNotEmptyString(val) : true
          }
        },
        arterial_blood_gas_test: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return val !== '' ? isNotEmptyString(val) : true
          }
        },
        avf_flow: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            return onlyTwoDigitsOneDecimal(val)
          }
        },
        care_type: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return isNotEmptyString(val)
          }
        },
        consumables: {
          isValid: true,
          feedback: selectFeedback('un consommable'),
          validate: (data, val) => {
            return isNotEmptyArray(val)
          }
        },
        duration: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return isNotEmptyString(val)
          }
        },
        equipments: {
          isValid: true,
          feedback: selectFeedback('un équipement'),
          validate: (data, val) => {
            return isNotEmptyArray(val)
          }
        },
        flow_discontinuous: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return isBoolean(val)
          }
        },
        flow_day: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            if(!data.walker) {
              return onlyTwoDigitsOneDecimal(data.flow_night) || onlyTwoDigitsOneDecimal(data.flow_effort) || onlyTwoDigitsOneDecimal(val)
            } else {
              return onlyTwoDigitsOneDecimal(val)
            }
          }
        },
        flow_effort: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            if(!data.walker) {
              return onlyTwoDigitsOneDecimal(data.flow_night) || onlyTwoDigitsOneDecimal(data.flow_day) || onlyTwoDigitsOneDecimal(val)
            } else {
              return onlyTwoDigitsOneDecimal(val)
            }
          }
        },
        flow_night: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            if(!data.walker) {
              return onlyTwoDigitsOneDecimal(data.flow_effort) || onlyTwoDigitsOneDecimal(data.flow_day) || onlyTwoDigitsOneDecimal(val)
            } else {
              return onlyTwoDigitsOneDecimal(val)
            }
          }
        },
        o2_type: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return isNotEmptyString(val)
          }
        },
        once_renewal: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return isBoolean(val)
          }
        },
        preferred_device: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return val.length > 0 ? isNotEmptyString(val) : true
          }
        },
        start_date: {
          isValid: true,
          feedback: 'Entrez une date valide',
          validate: (data, val) => {
            return isDate(val)
          }
        },
        time_of_use: {
          isValid: true,
          feedback: numberFeedback(),
          validate: (data, val) => {
            const time = {
              short_term: 24,
              long_term: 24,
              avf: 60
            }[data.o2_type]

            return (isInteger(val) && val <= time && 0 < val)
          }
        },
        walker: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return isBoolean(val)
          }
        },
        with_ald: {
          isValid: true,
          feedback: selectFeedback(),
          validate: (data, val) => {
            return isBoolean(val)
          }
        },
      }
    }
  },

  getters: {
    formData(state) {
      return Object.assign({}, state.data)
    }
  },

  mutations: {
    update(state, { key, value, validate = true }) {
      state.data[key] = value

      if (validate && state.validations[key]) {
        // If validating this field should also fire validations on another field, run these validations also.
        const validations = [
          [state.validations[key], value], ...(
          (state.validations[key].dependsOn || []).map(dependency => [state.validations[dependency], state.data[dependency]])
          )
        ]

        validations.forEach(validation => {
          validation[0].isValid = validation[0].validate(state.data, validation[1])
        });
      }
    },

    delete(state) {
      state.deleted = true
      state.deletedAt = new Date().toLocaleString('fr-Fr').substr(0, 10)
    },

    setInitial(state, data) {
      const validationsKeys = Object.keys(state.validations)
      for (const key of validationsKeys) {
        state.validations[key].isValid = true
      }
      state.initial = Object.assign({}, data)
    },

    reset(state, { key }) {
      state.data[key] = state.initial[key]
    },

    setValidStateFor(state, { key, value }) {
      state.validations[key].isValid = value
    },

    setValidState(state, value) {
      state.isValid = value
    },

    updateActiveFields(state, { fieldNames, active }) {
      if (active) {
        state.activeFields.push(...fieldNames)
      } else {
        for (const fieldName of fieldNames) {
          const index = state.activeFields.indexOf(fieldName)

          if (index !== -1) {
            state.activeFields.splice(index, 1)
          }
        }
      }
    },

    setDataBadges(state, value) {
      state.dataBadges = value
    }
  },

  actions: {

    validate({ state, commit }) {

      let globalValidationStateHasChanged = false
      for (const key in state.validations) {
        // Skip fields that are not currently active
        if (state.activeFields.indexOf(key) === -1) continue;

        const newState = state.validations[key].validate(state.data, state.data[key])

        // No need to update if it did not change
        if (newState !== state.validations[key].isValid) {
          commit('setValidStateFor', { key, value: newState })
        }

        // No need to update if it did not change
        if (state.isValid !== newState) {
          // If we are in the middle of a validation process and current state is already invalid,
          // then we shouldn't update it.
          if (globalValidationStateHasChanged && !state.isValid) continue;

          commit('setValidState', newState)
          globalValidationStateHasChanged = true
        }
      }
    },

    loadFromTreatment({ state, commit }, treatment) {
      commit('setInitial', treatment)
      const keys = Object.keys(state.defaults);

      for (const key of keys) {
        const value = (treatment[key] === undefined || treatment[key] === null) ? state.defaults[key] : treatment[key]
        commit('update', { key, value, validate: false })
      }
    },

  }
}
