<template>
  <b-form-group
    :state="shouldValidate"
    :invalid-feedback="invalidFeedback"
  >
    <label class="row">
      {{ label }}
      <sup v-if="options.required" class="required"> requis</sup>
    </label>
    <b-form-textarea
      class="row no-logotype"
      rows="3"
      max-rows="6"
      :value="inputValue"
      :input="onInput"
      :state="shouldValidate"
      :invalid-feedback="invalidFeedback"
      @input="onInput"
    ></b-form-textarea>
  </b-form-group>
</template>

<script>
import ValidationsMixin from "@/mixins/ValidationsMixin";

export default {
  mixins: [ValidationsMixin],
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: '',
    },

    options: {
      type: Object,
      default: function() { return {} }
    },

    value: {
      default: () => null
    }
  },

  destroyed() {
    this.$emit('destroyed', { name: this.name })
  },

  data() {
    return {
      inputValue: this.value || this.options.default
    }
  },

  methods: {
    onInput(data) {
      this.dirty = true
      this.$emit('input', { name: this.name, value: data })
    }
  }
}
</script>
