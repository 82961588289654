<template>
  <div class="vue-ask-for-care-patho" >
    <div class="divider col-sm-12">
      <div class="row col-sm-12 d-flex">
        <div class="col-sm-4">
          <b-button class="pathology-badge" v-b-toggle.o2-content @click="toggleHead">
            <div class="d-flex">
              <i class="fas fa-angle-double-up 2x" id="toggle-o2-icon"></i>
              <span class="text-is-semibold">{{$t('askForCare.o2.o2')}}</span>
            </div>
          </b-button>
        </div>
        <template v-if="expanded === false || expanded === 'false'">
          <div class="col-sm-8 d-flex justify-content-between" >
            <section>
              <b-badge class="tag" v-for="item in $store.getters.validO2Data" :key="item">
                <span class="d-flex">
                  {{item}}
                </span>
              </b-badge>
            </section>
            <div>
              <i class="delete-button far fa-trash-alt 2x icon" @click="emitDestroyForm"></i>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="col-sm-8 d-flex justify-content-end">
            <i class="delete-button far fa-trash-alt 2x icon" @click="emitDestroyForm"></i>
          </div>
        </template>
      </div>
    </div>

    <b-collapse id="o2-content" visible class="row container">
      <article class="col-sm-12 radio-buttons">
        <label>Type de traitement</label>
        <div class="row col-sm-12">
          <custom-radio-button
            v-for="(option, index) of o2TypeOptions"
            :key="index"
            :active="isActive(option.value, o2_type)"
            :text="option.text"
            :option-value="option.value"
            v-model="o2_type"
          ></custom-radio-button>
        </div>
      </article>

      <div v-if="o2_type == 'short_term'" class="col-sm-12 row">
        <article class="col-sm-12 radio-buttons">
          <label>Prescription en rapport avec une affection longue durée ?</label>
          <div class="row col-sm-12">
            <custom-radio-button
              v-for="(option, index) of booleanOptions"
              :key="index"
              :active="isActive(option.value, with_ald_o2)"
              :optionValue="option.value"
              :text="option.text"
              v-model="with_ald_o2"
            ></custom-radio-button>
          </div>
        </article>

        <article class="col-sm-12">
          <b-form-group
          :invalid-feedback="prescription_start_date_o2Feedback"
          :state="prescription_start_date_o2Val">
            <label>
              Date de début de traitement <sup class="required"> requis</sup>
            </label>
            <b-form-input
              v-model="prescription_start_date_o2"
              id="`type-date`"
              type="date"
            ></b-form-input>
          </b-form-group>
        </article>

        <article class="col-sm-12 radio-buttons">
          <label>Type de prise en charge</label>
          <div class="row col-sm-12">
            <custom-radio-button
              v-for="(option, index) of careTypeOptions"
              :key="index"
              :active="isActive(option.value, care_type)"
              :optionValue="option.value"
              :text="option.text"
              v-model="care_type"
            ></custom-radio-button>
          </div>
        </article>

        <article class="col-sm-12">
          <b-form-group
            :invalid-feedback="selectFeedback('un équipement')"
            :state="equipmentsVal"
          >
            <label>
              Oxygénothérapie par : <sup class="required"> requis</sup>
            </label>
            <b-form-checkbox-group
              v-model="equipments"
              :options="equipmentsOptions"
              name="o2-short-equipements"
              required
            ></b-form-checkbox-group>
          </b-form-group>
        </article>

        <article class="col-sm-12">
          <b-form-group
          :state="consumablesVal"
          :invalid-feedback="selectFeedback('un consommable')">
          <label>Consommable : <sup class="required"> requis</sup></label>
            <b-form-checkbox-group
              v-model="consumables"
              name="consumables-options"
              :options="consumablesOptions"
            >
            </b-form-checkbox-group>
          </b-form-group>
        </article>

        <article class="col-sm-4">
          <b-form-group
            :state="time_of_use_o2Val"
            :invalid-feedback="time_of_use_o2Feedback">
            <label for="o2-short-time-of-use">Durée d'utilisation <sup class="required"> requis</sup></label>
            <b-input-group>
              <input v-model="time_of_use_o2" id="o2-short-time-of-use" class="input-placeholders-right" type="number" required min="1" max="24">
              <span class="placeholder">h/jour</span>
            </b-input-group>
          </b-form-group>
        </article>

        <article class="col-sm-4">
          <b-form-group
            :state="flow_dayVal"
            :invalid-feedback="numberFeedback()">
            <label for="o2-short-flow-day">Débit jour <sup class="required"> requis</sup></label>
            <b-input-group>
              <input
                id="o2-short-flow-day"
                class="input-placeholders-right"
                type="text"
                v-model="flow_day"
                required
              >
              <span class="placeholder">l/min</span>
            </b-input-group>
          </b-form-group>
        </article>

        <template v-if="care_type == 'transient_respiratory_failure'">
          <article class="col-sm-12 radio-buttons">
            <label class="row col-sm-12">Durée de l'ordonnance</label>
            <div class="col-sm-4 row">
              <b-form-select
                :options="durationUnitsOptions"
                v-model="durationUnit"
              >
              </b-form-select>
            </div>
            <article class="col-sm-12 row">
              <custom-radio-button
                v-for="(option, index) of shortPrescriptionDurationOptions"
                :key="index"
                :active="isActive(option.value, prescription_duration_o2)"
                :optionValue="option.value"
                :text="option.text"
                v-model="prescription_duration_o2"
              ></custom-radio-button>
            </article>
          </article>
        </template>

        <template v-if="care_type == 'dyspnea_palliative_care'">
          <article class="col-sm-12">
            <b-form-group>
              <label>Durée de l'ordonnance</label>
              <b-form-radio v-model="prescription_duration_o2" value=3>3 mois</b-form-radio>
              <b-form-checkbox v-model="once_renewal">
                Renouvelable 1 fois
              </b-form-checkbox>
            </b-form-group>
          </article>
        </template>
      </div>

      <div v-if="o2_type == 'long_term'" class="col-sm-12 row">
        <article class="col-sm-12 radio-buttons">
          <label>Prescription en rapport avec une affection longue durée ?</label>
          <div class="col-sm-12 row">
            <custom-radio-button
              v-for="(option, index) of booleanOptions"
              :key="index"
              :active="isActive(option.value, with_ald_o2)"
              :optionValue="option.value"
              :text="option.text"
              v-model="with_ald_o2"
            ></custom-radio-button>
          </div>
        </article>

        <article class="col-sm-12 radio-buttons" v-if="with_ald_o2 === true || with_ald_o2 === 'true'">
          <b-form-group
            :invalid-feedback="selectFeedback()"
            :state="ald_on_list_o2Val"
            >
            <label>Sur Liste ? <sup class="required"> requis</sup></label>
            <div class="col-sm-12 row">
            <custom-radio-button
              v-for="(option, index) of booleanOptions"
              :key="index"
              :active="isActive(option.value, ald_on_list_o2)"
              :optionValue="option.value"
              :text="option.text"
              v-model="ald_on_list_o2"
            ></custom-radio-button>
          </div>

          </b-form-group>
        </article>

        <article class="col-sm-12 radio-buttons">
          <b-form-group
            :state="walkerVal"
            :invalid-feedback="selectFeedback()"
          >
            <label>Utilisation <sup class="required"> requis</sup></label>
            <div class="row col-sm-12">
              <custom-radio-button
                v-for="(option, index) of walkerOptions"
                :key="index"
                :active="isActive(option.value, walker)"
                :optionValue="option.value"
                :text="option.text"
                v-model="walker"
              ></custom-radio-button>
            </div>
          </b-form-group>
        </article>

        <article class="col-sm-12 radio-buttons">
          <b-form-group
            :state="equipmentsVal"
            :invalid-feedback="selectFeedback('un équipement')"
          >
            <label>Oxygénothérapie par <sup class="required"> requis</sup></label>
            <div class="col-sm-12 row">
              <custom-radio-button
                v-for="(option, index) of equipmentsLTOptions"
                :key="index"
                :active="isActive(option.value, equipments)"
                :optionValue="option.value"
                :text="option.text"
                v-model="equipments"
              ></custom-radio-button>
            </div>
          </b-form-group>
        </article>

        <article class="col-sm-4">
          <b-form-group
            :invalid-feedback="time_of_use_o2Feedback"
            :state="time_of_use_o2Val">
            <label for="o2-short-time-of-use">Durée d'utilisation <sup class="required"> requis</sup></label>
            <b-input-group>
              <input v-model="time_of_use_o2" id="`o2-long-time-of-use`" class="input-placeholders-right" type="number" min="1" max='24'>
              <span class="placeholder">h/jour</span>
            </b-input-group>
          </b-form-group>
        </article>

        <section id="tooltip-1" class="col-sm-12 row">
          <template>
            <article class="col-sm-4">
              <b-form-group
                :state="flow_dayVal"
                :invalid-feedback="numberFeedback()"
              >
                <label for="o2-short-time-of-use">Débit jour</label>
                <b-input-group>
                  <input
                    id="`o2-long-flow-day`"
                    class="input-placeholders-right"
                    type="text"
                    v-model="flow_day"
                  >
                  <span class="placeholder">l/min</span>
                </b-input-group>
              </b-form-group>
            </article>

            <article class="col-sm-4">
              <b-form-group
                :state="flow_nightVal"
                :invalid-feedback="numberFeedback()"
              >
                <label for="o2-short-time-of-use">Débit nuit</label>
                <b-input-group>
                  <input
                    id="`o2-long-flow-night`"
                    class="input-placeholders-right"
                    type="text"
                    v-model="flow_night"
                  >
                  <span class="placeholder">l/min</span>
                </b-input-group>
              </b-form-group>
            </article>
          </template>

          <template v-if="walker === true && equipments[0] !== 'liquid'">
            <article class="col-sm-5" >
              <b-form-group>
                <label for="payload-long-flow">Débit d'effort <sup class="required"> requis</sup></label>
                <b-form-select
                  :options="discontinuousFlowOption"
                  v-model="flow_discontinuous"
                  id="`payload-long-flow`"  min="0">
                </b-form-select>
              </b-form-group>
            </article>
          </template>


          <article class="col-sm-5">
            <b-form-group
              :state="flow_effortVal"
              :invalid-feedback="numberFeedback()"
            >
              <label for="input-live" v-if="walker === true && equipments[0] === 'concentrator'">Valeur <sup class="required"> requis</sup></label>
              <label for="input-live" v-if="walker !== true || equipments[0] === 'liquid'">Valeur d'effort</label>
              <b-input-group>
                <input
                  id="type-o2-fix-input"
                  class="input-placeholders-right"
                  type="text"
                  v-model="flow_effort"
                >
                <span class="placeholder">{{ flowEffortPlaceholder }}</span>
              </b-input-group>
            </b-form-group>
          </article>
        </section>
        <template>
           <b-tooltip :show="tooltipShow" target="type-o2-fix-input" placement="right" custom-class="text-is-medium is-tooltip" container="tooltip-1" triggers="manual" v-if="showTooltip()">
            Vous devez au moins renseigner une valeur dans l'un des champs débit jour, nuit, effort.
          </b-tooltip>
        </template>

        <article class="col-sm-6" v-if="walker === true && equipments[0] === 'concentrator'">
          <b-form-group>
            <label for="payload-long-flow">Préférence matériel déambulation <i class="fas fa-info-circle" title="Remplir débit d'effort, source O2 et utilisation"></i></label>
            <b-form-select :options="preferredDeviceOptions" v-model="preferred_device" value= "''" id="`payload-long-preferred-device`" >Pas de préférence</b-form-select>
          </b-form-group>
        </article>

        <div class="row col-sm-12">
          <article class="col-sm-4">
            <b-form-group>
              <label>Date du test</label>
              <b-form-input v-model="arterial_blood_gas_date_test" id="`type-date`" type="date"></b-form-input>
            </b-form-group>
          </article>

          <article class="col-sm-4">
            <b-form-group>
              <label>Nature du test</label>
              <b-form-select
                v-model="arterial_blood_gas_test"
                id="`payload-long-arterial-test`"
                :options="abgOptions">
              </b-form-select>
            </b-form-group>
          </article>

          <article class="col-sm-1 pl-1 pr-1">
            <b-form-group>
              <label>pH</label>
              <b-form-input
                v-model="arterial_blood_gas_ph"
                id="`o2-long-abg-ph`"
                type="number">
              </b-form-input>
            </b-form-group>
          </article>

          <article class="col-sm-1 pl-1 pr-1">
            <b-form-group>
              <label>PaCO2</label>
              <b-form-input v-model="arterial_blood_gas_pa_co2" id="`o2-long-abg-pa-co2`" type="number" min="0" ></b-form-input>
            </b-form-group>
          </article>

          <article class="col-sm-1 pl-1 pr-1">
            <b-form-group>
              <label>paO2</label>
              <b-form-input v-model="arterial_blood_gas_pa_o2" id="`o2-long-abg-pao2`" type="number" min="0" ></b-form-input>
            </b-form-group>
          </article>

          <article class="col-sm-1 pl-1 pr-1">
            <b-form-group>
              <label>SaO2</label>
              <b-form-input v-model="arterial_blood_gas_sa_o2" id="`o2-long-abg-sao2`" type="number" min="0" ></b-form-input>
            </b-form-group>
          </article>
        </div>
      </div>

      <div v-if="o2_type == 'avf'" class="col-sm-12 row">
        <article class="col-sm-12 radio-buttons">
        <label>Prescription en rapport avec une affection longue durée ?</label>
        <div class="col-sm-12 row">
          <custom-radio-button
            v-for="(option, index) of booleanOptions"
            :key="index"
            :active="isActive(option.value, with_ald_o2)"
            :optionValue="option.value"
            :text="option.text"
            v-model="with_ald_o2"
          ></custom-radio-button>
        </div>
      </article>

        <article class="col-sm-5">
          <b-form-group
            :invalid-feedback="prescription_start_date_o2Feedback"
            :state="prescription_start_date_o2Val">
            <label>Date de début de traitement <sup class="required"> requis</sup></label>
            <b-form-input v-model="prescription_start_date_o2" id="`o2-avf-prescription-start-date`" type="date">
            </b-form-input>
          </b-form-group>
        </article>

        <article class="col-sm-5"></article>

        <article class="col-sm-6">
          <b-form-group
          :invalid-feedback="time_of_use_o2Feedback"
          :state="time_of_use_o2Val">
            <label>Temps d'utilisation en cas de crise <sup class="required"> requis</sup></label>
            <b-input-group>
              <input v-model="time_of_use_o2" id="`o2-avf-time-of-use`" class="input-placeholders-right" type="number" min="1" max="60">
              <span class="placeholder">min</span>
            </b-input-group>
          </b-form-group>
        </article>

        <article class="col-sm-5">
          <b-form-group
          :invalid-feedback="numberFeedback()"
          :state="flow_dayVal">
            <label>Débit <sup class="required"> requis</sup></label>
            <b-input-group>
              <b-form-input
                id="`o2-avf-flow-day`"
                class="input-placeholders-right"
                type="text"
                v-model="flow_day"
              >
              </b-form-input>
              <span class="placeholder">l/min</span>
            </b-input-group>
          </b-form-group>
        </article>

        <article class="col-sm-12 radio-buttons">
          <label>Durée de l'ordonnance (mois)</label>
          <div class="col-sm-12 row">
            <custom-radio-button
              v-for="(option, index) of avfPrescriptionDurationOptions"
              :key="index"
              :active="isActive(option.value, prescription_duration_o2)"
              :optionValue="option.value"
              :text="option.text"
              v-model="prescription_duration_o2"
            ></custom-radio-button>
          </div>
          <b-form-checkbox v-model="once_renewal">Renouvelable 1 fois</b-form-checkbox>
        </article>
      </div>

      <article class="col-sm-12">
        <label for="additional_info_o2">Informations complémentaires</label>
        <b-form-textarea
          id="additional_info_o2"
          v-model="additional_info_o2"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </article>
    </b-collapse>
  </div>
</template>
<script>
import CustomRadioButton from './/CustomRadioButton'
import AskForCareMixin from '@/mixins/AskForCareMixin'
import TimeValidation from '@/mixins/TimeValidation'
import DebounceMixin from '@/mixins/DebounceMixin'
import StrValidation from '@/mixins/StrValidation'
import IntValidation from '@/mixins/IntValidation'

export default {
  components: {
    CustomRadioButton
  },
  mixins: [
    AskForCareMixin,
    TimeValidation,
    DebounceMixin,
    StrValidation,
    IntValidation
  ],
  destroyed() {
    this.$store.dispatch('resetAllO2')
  },
  data() {
    return {
      o2TypeOptions: [
        { text: this.$t('askForCare.o2.short_term'), value: 'short_term'},
        { text: this.$t('askForCare.o2.long_term'),  value: 'long_term'},
        { text: this.$t('askForCare.o2.avf'),        value: 'avf'}
      ],
      shortPrescriptionDurationOptions: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 }
      ],
      careTypeOptions: [
        { text: this.$t('askForCare.o2.transient_respiratory_failure'), value: "transient_respiratory_failure" },
        { text: this.$t('askForCare.o2.dyspnea_palliative_care'),       value: "dyspnea_palliative_care" }
      ],
      equipmentsOptions: [
        { text: this.$t('askForCare.o2.c'), value: 'concentrator' },
        { text: this.$t('askForCare.o2.g'),  value: 'oxygen_tank' }
      ],
      walkerOptions: [
        { text: this.$t('askForCare.o2.walker.true'),  value: true },
        { text: this.$t('askForCare.o2.walker.false'), value: false }
      ],
      equipmentsLTOptions: [
        { text: this.$t('askForCare.o2.c'), value: ['concentrator'] },
        { text: this.$t('askForCare.o2.l'),       value: ['liquid'] }
      ],
      consumablesOptions: [
        { text: this.$t('askForCare.o2.cannula'), value: 'cannula' },
        { text:this.$t('askForCare.o2.mask'),     value: 'mask' }
      ],
      avfPrescriptionDurationOptions: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 }
      ],
      booleanOptions: [
        { text: this.$t('common.true'), value:true },
        { text: this.$t('common.false'), value:false }
      ],
      discontinuousFlowOption: [
        { text: this.$t('askForCare.o2.flow_discontinuous.false'), value: false },
        { text:this.$t('askForCare.o2.flow_discontinuous.true') ,  value: true }
      ],
      preferredDeviceOptions: [
        { text: 'Pas de préférence', value: '' }
      ],
      abgOptions: [
        { text: "",                                                          value: null },
        { text: this.$t('askForCare.o2.first_rest_natural_breathing_test'),  value: "first_rest_natural_breathing_test" },
        { text: this.$t('askForCare.o2.rest_assisted_breathing_test'),       value: "rest_assisted_breathing_test" },
        { text: this.$t('askForCare.o2.second_rest_natural_breathing_test'), value: "second_rest_natural_breathing_test" },
        { text: this.$t('askForCare.o2.endurance_natural_breathing_test'),   value: "endurance_natural_breathing_test" }
      ],
      durationUnitsOptions: [
        { text: this.$t('askForCare.o2.months'), value: "months" },
        { text: this.$t('askForCare.o2.weeks'),   value: "weeks" }
      ],
      expanded: this.$store.state.mainCareForm.expandedO2,
      tooltipShow: false
    }
  },
  computed:{
    flowEffortPlaceholder() {
      return this.flow_discontinuous === true ? 'bolus' : 'l/min'
    },
    // Getters/Setters
    arterial_blood_gas_date_test: {
      get() {
        return this.$store.state.o2CareForm.arterial_blood_gas_date_test
      },
      set(value) {
        this.$store.commit('update_o2_arterial_blood_gas_date_test', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    ald_on_list_o2: {
      get() {
        return this.$store.state.o2CareForm.ald_on_list_o2
      },
        set(value) {
          this.$store.commit('update_o2_dirty', true)
          this.$store.commit('update_o2_ald_on_list_o2', value)
      }
    },
    arterial_blood_gas_test: {
      get() {
        return this.$store.state.o2CareForm.arterial_blood_gas_test
      },
      set(value) {
        this.$store.commit('update_o2_arterial_blood_gas_test', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    arterial_blood_gas_ph: {
      get() {
        return this.$store.state.o2CareForm.arterial_blood_gas_ph
      },
      set(value) {
        this.$store.commit('update_o2_arterial_blood_gas_ph', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    arterial_blood_gas_pa_co2: {
      get() {
        return this.$store.state.o2CareForm.arterial_blood_gas_pa_co2
      },
      set(value) {
        this.$store.commit('update_o2_arterial_blood_gas_pa_co2', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    arterial_blood_gas_pa_o2: {
      get() {
        return this.$store.state.o2CareForm.arterial_blood_gas_pa_o2
      },
      set(value) {
        this.$store.commit('update_o2_arterial_blood_gas_pa_o2', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    arterial_blood_gas_sa_o2: {
      get() {
        return this.$store.state.o2CareForm.arterial_blood_gas_sa_o2
      },
      set(value) {
        this.$store.commit('update_o2_arterial_blood_gas_sa_o2', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    additional_info_o2: {
      get() {
        return this.$store.state.o2CareForm.additional_info_o2
      },
      set(value) {
        this.$store.commit('update_o2_additional_info_o2', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    care_type: {
      get() {
        return this.$store.state.o2CareForm.care_type
      },
      set(value) {
        this.$store.commit('update_o2_care_type', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    consumables: {
      get() {
        return this.$store.state.o2CareForm.consumables
      },
      set(value) {
        this.$store.commit('update_o2_consumables', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    devices_packages: {
      get() {
        return this.$store.state.o2CareForm.devices_packages
      },
      set(value) {
        this.$store.commit('update_o2_devices_packages', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    prescription_duration_o2: {
      get() {
        return this.$store.state.o2CareForm.prescription_duration_o2
      },
      set(value) {
        this.$store.commit('update_o2_prescription_duration_o2', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    equipments: {
      get() {
        return this.$store.state.o2CareForm.equipments
      },
      set(value) {
        this.$store.commit('update_o2_equipments', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    flow_day: {
      get() {
        return this.$store.state.o2CareForm.flow_day
      },
      set(value) {
        this.$store.commit('update_o2_flow_day', this.textToFloat(value))
          this.$store.commit('update_o2_dirty', true)
      }
    },
    flow_discontinuous: {
      get() {
        return this.$store.state.o2CareForm.flow_discontinuous
      },
      set(value) {
        this.$store.commit('update_o2_flow_discontinuous', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    durationUnit: {
      get() {
        return this.$store.state.o2CareForm.duration_unit
      },
      set(value) {
        this.$store.commit('update_o2_duration_unit', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    flow_effort: {
      get() {
        return this.$store.state.o2CareForm.flow_effort
      },
      set(value) {
        this.$store.commit('update_o2_flow_effort', this.textToFloat(value))
        this.$store.commit('update_o2_dirty', true)
      }
    },
    flow_night: {
      get() {
        return this.$store.state.o2CareForm.flow_night
      },
      set(value) {
        this.$store.commit('update_o2_flow_night', this.textToFloat(value))
        this.$store.commit('update_o2_dirty', true)
      }
    },
    humidifier_o2: {
      get() {
        return this.$store.state.o2CareForm.humidifier_o2
      },
      set(value) {
        this.$store.commit('update_o2_humidifier_o2', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    o2_type: {
      get() {
        return this.$store.state.o2CareForm.o2_type
      },
      set(value) {
        this.$store.commit('update_o2_o2_type', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    once_renewal: {
      get() {
        return this.$store.state.o2CareForm.once_renewal
      },
      set(value) {
        this.$store.commit('update_o2_once_renewal', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    preferred_device: {
      get() {
        return this.$store.state.o2CareForm.preferred_device
      },
      set(value) {
        this.$store.commit('update_o2_preferred_device', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    prescription_duration_o2: {
      get() {
        return this.$store.state.o2CareForm.prescription_duration_o2
      },
      set(value) {
        this.$store.commit('update_o2_prescription_duration_o2', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    prescription_start_date_o2: {
      get() {
        return this.$store.state.o2CareForm.prescription_start_date_o2
      },
      set(value) {
        this.$store.commit('update_o2_prescription_start_date_o2', value)
        this.$store.commit('update_o2_dirty', true)
      }
    },
    time_of_use_o2: {
      get() {
        return this.$store.state.o2CareForm.time_of_use_o2
      },
      set(value) {
        value = this.onlyInt(value) ? parseInt(value) : ''
        this.$store.commit('update_o2_time_of_use_o2', value )
        this.$store.commit('update_o2_dirty', true)
      }
    },
    walker: {
      get() {
        return this.$store.state.o2CareForm.walker
      },
      set(value) {
        this.$store.commit('update_o2_walker', value)
          this.$store.commit('update_o2_dirty', true)
      }
    },
    with_ald_o2: {
      get() {
        return this.$store.state.o2CareForm.with_ald_o2
      },
        set(value) {
          this.$store.commit('update_o2_dirty', true)
          this.$store.commit('update_o2_with_ald_o2', value)
      }
    },

    // Validation
     equipmentsVal(){
       if (this.o2_type === 'short_term') {
         return this.equipments.length !== 0
       } else {
         return this.equipments.length !== 0 || !this.$store.state.mainCareForm.sentPathologies.includes('o2')
       }
    },
    consumablesVal(){
      return this.consumables.length !== 0
    },
    time_of_use_o2Val(){
      let caseAvf = ( this.time_of_use_o2 !== '' && this.onlyInt(this.time_of_use_o2) && this.oneHourMax(this.time_of_use_o2) && this.time_of_use_o2 > 0) || (!this.$store.state.mainCareForm.sentPathologies.includes('o2') && this.time_of_use_o2 === '')
      let caseOther = ( this.time_of_use_o2 !== '' && this.onlyInt(this.time_of_use_o2) && this.oneDayMax(this.time_of_use_o2) && this.time_of_use_o2 > 0) || (!this.$store.state.mainCareForm.sentPathologies.includes('o2') && this.time_of_use_o2 === '')
      return this.o2_type == 'avf'  ? caseAvf : caseOther
    },
    flow_dayVal() {
      if (!this.$store.state.mainCareForm.sentPathologies.includes('o2')) {
        return this.emptyOrValid(this.flow_day)
      } else if (this.equipments[0] === 'liquid' || this.walker === false) {
        return (this.onlyTwoDigitsOneDecimal(this.flow_day)) || (this.flow_night !== '' || this.flow_effort !== '')
      } else if (this.o2_type === 'long_term') {
        return this.emptyOrValid(this.flow_day)
      } else {
        return this.onlyTwoDigitsOneDecimal(this.flow_day)
      }
    },
    flow_nightVal(){
       if ((this.equipments[0] === 'liquid' || this.walker === false) && this.$store.state.mainCareForm.sentPathologies.includes('o2')) {
        return (this.onlyTwoDigitsOneDecimal(this.flow_night)) || (this.flow_day !== '' || this.flow_effort !== '')
      } else {
        return this.emptyOrValid(this.flow_night)
      }
    },
    ald_on_list_o2Val(){
      return this.ald_on_list_o2 !== null || !this.$store.state.mainCareForm.sentPathologies.includes('o2')
    },
    walkerVal(){
      return this.walker !== '' || !this.$store.state.mainCareForm.sentPathologies.includes('o2')
    },
    flow_effortVal(){
      if (!this.$store.state.mainCareForm.sentPathologies.includes('o2')) {
        return this.emptyOrValid(this.flow_effort)
      } else if (this.equipments[0] === 'concentrator' && this.walker === true) {
        return this.onlyTwoDigitsOneDecimal(this.flow_effort)
      } else if (this.equipments[0] === 'liquid' || this.walker === false) {
        return (this.onlyTwoDigitsOneDecimal(this.flow_effort)) || (this.flow_day !== '' || this.flow_night !== '')
      } else {
        return this.emptyOrValid(this.flow_effort)
      }
    },
    prescription_start_date_o2Val(){
      return this.prescription_start_date_o2 !== ''
    },

    // Invalid Feedback
    time_of_use_o2Feedback(){
      return this.o2_type === 'avf' ? 'Entrez une valeur comprise entre 1 et 60' : 'Entrez une valeur comprise entre 1 et 24'
    },
    prescription_start_date_o2Feedback(){
      return 'Entrez une date valide'
    }
  },
  watch:{
    o2_type(newValue){
      this.with_ald_o2 = newValue === 'short_term' ?  false : true
      this.otherO2TypeChoice()
      this.$store.commit('update_o2_dirty', false)
    },
    care_type(){
      this.otherCareChoice()
    },
    flow_day(){
      this.tooltipShow = this.showTooltip()
      this.debounce(this.fetchDevices, 500)
    },
    flow_night(){
      this.tooltipShow = this.showTooltip()
      this.debounce(this.fetchDevices, 500)
    },
    flow_effort(){
      this.tooltipShow = this.showTooltip()
      this.debounce(this.fetchDevices, 500)
    },
    flow_discontinuous() {
      this.debounce(this.fetchDevices, 500)
    },
    equipments()  {
      this.debounce(this.fetchDevices, 500)
      if(this.equipments[0] === 'liquid'){
        this.tooltipShow = this.showTooltip()
        this.flow_discontinuous = false
      }
    },
    walker(){
      this.tooltipShow = this.showTooltip()
      if(this.walker == false){
        this.flow_discontinuous = false
        this.$store.commit('update_o2_flow_discontinuous', false )
      }
      this.debounce(this.fetchDevices, 500)
    }
  },
  methods: {
    showTooltip(){
      return (this.walker === false || this.equipments[0] === 'liquid') && (this.flow_effort === '' && this.flow_day === '' && this.flow_night === '')
    },
    isActive(value, stateValue) {
      return (value === stateValue)
    },
     toggleHead(){
      this.$store.commit('update_main_expandedO2', !this.expanded)
      this.expanded = !this.expanded
      if(this.expanded){
        $('#toggle-o2-icon').removeClass('fa-angle-double-down')
        $('#toggle-o2-icon').addClass('fa-angle-double-up')
      }
      else{
        $('#toggle-o2-icon').removeClass('fa-angle-double-up')
        $('#toggle-o2-icon').addClass('fa-angle-double-down')
      }
    },
    otherO2TypeChoice() {
      this.$store.commit('update_o2_o2_type', this.o2_type )
      if(this.o2_type === 'short_term'){
        this.cleanO2Long()
        this.cleanO2Avf()

        this.prescription_start_date_o2 = new Date().toISOString().substr(0, 10)
        this.care_type                  = 'transient_respiratory_failure'
        this.equipments                  = ['concentrator']
        this.consumables                 = ['cannula']
      }
      else if(this.o2_type === 'long_term'){
        this.cleanO2Short()
        this.cleanO2Avf()

        this.equipments               = ''
        this.arterial_blood_gas_test = 'first_rest_natural_breathing_test'
      }
      else {
        this.cleanO2Long()
        this.cleanO2Short()

        this.prescription_duration_o2 = 3
        this.once_renewal = true
        this.prescription_start_date_o2 = new Date().toISOString().substr(0, 10)
      }
    },
    otherCareChoice(e) {
      this.$store.commit('update_o2_care_type', this.care_type )
      if(this.care_type == 'transient_respiratory_failure'){
        this.prescription_duration_o2 = 3
        this.once_renewal = ''
      }
      else {
        this.prescription_duration_o2 = 3
        this.once_renewal = true

      }
    },
    fetchDevices() {
      if(this.walker !== '' && this.equipments[0] !== '' && this.flow_discontinuous !== '' && (this.flow_effort > 0 || this.flow_day > 0 || this.flow_night > 0)){
        this.$axios.get("/api/v1/packages", {
          params: {
            walker:             this.walker,
            flow_discontinuous: this.flow_discontinuous,
            equipments:         this.equipments[0] === 'concentrator' ? ['c'] : ['l'],
            flow_effort:        this.flow_effort,
            flow_day:           this.flow_day,
            flow_night:         this.flow_night
          }
        })
        .then(response => {
          const devices = response.data.device_packages.map(device => ({ text: device.device_label, value: device.device_label }))

          if (devices.length === 0) this.$toasted.error("Les caractéristiques demandées ne correspondent à aucun matériel", { duration: 5000 })

          this.preferredDeviceOptions = [{text: 'Pas de préférence', value: ''}, ...devices]
          this.devices_packages = response.data.device_packages
        })
        .catch((error)=> {
          this.$toasted.error(error.response.data, { duration: 5000 })
        })
      }
    },
    cleanO2Short(){
      this.prescription_start_date_o2 = ''
      this.care_type                  = ''
      this.equipments                  = ''
      this.consumables                 = ''
      this.time_of_use_o2             = ''
      this.flow_day                   = ''
      this.prescription_duration_o2   = ''
      this.once_renewal               = ''
    },
    cleanO2Long(){
      this.arterial_blood_gas_date_test = ''
      this.arterial_blood_gas_pa_co2    = ''
      this.arterial_blood_gas_pa_o2     = ''
      this.arterial_blood_gas_ph        = ''
      this.arterial_blood_gas_sa_o2     = ''
      this.arterial_blood_gas_test      = ''
      this.devices_packages             = ''
      this.equipments                    = ''
      this.flow_day                     = ''
      this.flow_discontinuous           = ''
      this.flow_effort                  = ''
      this.flow_night                   = ''
      this.preferred_device             = ''
      this.time_of_use_o2               = ''
      this.walker                       = ''
    },
    cleanO2Avf(){
      this.flow_day                   = ''
      this.prescription_duration_o2   = ''
      this.prescription_start_date_o2 = ''
      this.time_of_use_o2             = ''
    }
  }
}
</script>
