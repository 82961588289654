<template>
  <div class="vue-ask-for-care-patho">
    <div class="divider col-sm-12">
      <div class="row col-sm-12 d-flex">
        <div class="col-sm-4">
          <b-button class="pathology-badge" v-b-toggle.ppc-content @click="toggleHead">
            <div class="d-flex">
              <i class="fas fa-angle-double-up 2x" id="toggle-ppc-icon"></i>
              <span class="text-is-semibold">{{$t('askForCare.ppc.ppc')}}</span>
            </div>
          </b-button>
        </div>
        <template v-if="expanded === false || expanded === 'false'">
          <div class="col-sm-8 d-flex justify-content-between" >
            <section>
              <b-badge class="tag" v-for="item in this.$store.getters.validPpcData" :key="item">
                <span class="d-flex">
                  {{item}}
                </span>
              </b-badge>
            </section>
            <div>
              <i class="delete-button far fa-trash-alt 2x icon" @click="emitDestroyForm"></i>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="col-sm-8 d-flex justify-content-end">
            <i class="delete-button far fa-trash-alt 2x icon" @click="emitDestroyForm"></i>
          </div>
        </template>
      </div>
    </div>

    <b-collapse id="ppc-content" visible class="row container">
      <article class="col-sm-12 radio-buttons">
        <label>
          Prescription en rapport avec une affection longue durée ?
        </label>
        <div class="col-sm-12 row">
          <custom-radio-button
            v-for="(option, index) of booleanOptions"
            :key="index"
            :active="isActive(option.value, with_ald_ppc)"
            :text="option.text"
            :option-value="option.value"
            v-model="with_ald_ppc"
          ></custom-radio-button>
        </div>
      </article>

      <article class="col-sm-12 radio-buttons" v-if="with_ald_ppc === true">
        <label>
          Sur liste ? <sup class="required"> requis</sup>
        </label>
        <div class="col-sm-12 row">
          <custom-radio-button
            v-for="(option, index) of booleanOptions"
            :key="index"
            :active="isActive(option.value, ald_on_list_ppc)"
            :text="option.text"
            :option-value="option.value"
            v-model="ald_on_list_ppc"
          ></custom-radio-button>
        </div>
      </article>

      <article class="col-sm-6" id="ppc-iah-ia">
        <b-form-group
          id="ppc-iah"
          :state="iahVal"
          :invalid-feedback="iahFeedback"
          >
          <label> IAH Initial <span v-if="age < 16">(> à 10)</span><sup class="required" v-if="eordoFields && (age >= 16 || birthDate == '')"> requis</sup></label>
          <b-form-input
            type='text'
            v-model="iah"
            @blur="openBirthDateModal"
          >
          </b-form-input>
        </b-form-group>
        <b-tooltip
          :show="true"
          target="ppc-iah"
          container='ppc-iah'
          noninteractive
          placement="right"
          custom-class="text-is-medium is-tooltip no-arrow"
          v-if="birthDate !== '' && iah !== '' && iah < 15 && age >= 16 && iah >= 0.1"
          id="iah-tooltip">
          Êtes-vous sûr de vouloir prescrire pour une valeur inférieure à 15 ?
        </b-tooltip>
          <template v-if="this.age !== null && this.age < 16">
            <b-form-group
            :state="iaVal"
            :invalid-feedback="iaFeedback">
            <label> IA Initial (> à 5)</label>
              <b-form-input
                type='text'
                v-model="ia"
                id="ia"
              ></b-form-input>
            </b-form-group>

            <template>
              <b-tooltip :show="tooltipAtLeastShow" target="ia" placement="right" custom-class="text-is-medium is-tooltip" container="ppc-iah" triggers="manual" v-if="showAtLeastTooltip()">
                Vous devez au moins renseigner une valeur dans l'un des champs IA ou IAH.
              </b-tooltip>
            </template>
          </template>
      </article>

      <template>
        <article class="col-sm-6">
          <template v-if="(iah != '' && iah > 0 || ia != '' && ia > 0) && birthDate !== '' && birthDateValid">
            <b-form-group
              class="mb-0"
              :state="iahSymptomsVal"
              :invalid-feedback="iahSymptomsFeedback"
            >
              <label>Veuillez sélectionner au moins {{symptomsCount}} {{'symptôme' | pluralize(symptomsCount) }} et ou remplir le champ 'Autre'</label>
              <b-form-checkbox-group
                :options="iahSymptomsOptions"
                name="ppc-iah-symptoms"
                v-model="iahSymptoms"
                stacked
              >
              </b-form-checkbox-group>
            </b-form-group>
            <b-input-group>
              <b-input-group-prepend is-text>
                <b-form-checkbox class="mr-n2" v-model="iahSymptoms" value='other'>
                  <span class="sr-only"></span>
                </b-form-checkbox>
              </b-input-group-prepend>
              <b-form-input
                aria-label="Text input with checkbox"
                placeholder="Autre ?"
                class="like-label no-logotype"
                id="iah-other-symptoms"
                v-model="iahOtherSymptoms"
                :state="iahOtherSymptomsVal"
              ></b-form-input>
              <b-form-invalid-feedback id="iah-other-symptoms">
                {{iahOtherSymptomsFeedback}}
              </b-form-invalid-feedback>
            </b-input-group>
          </template>
        </article>
      </template>

      <cm-confirm :show.sync="symptomsModalOpen">
        <div class="bloc row col-sm-10 center danger-header">
          <div class="text-center">
            <p class="danger-title">Indiquez une date de naissance</p>
            <b-form-group
              :state="birthDateValid"
              :invalid-feedback="birthDateFeedback"
            >
              <label>Date de naissance</label>
              <b-form-input
                v-model="birthDate"
                id="`ppc-birthdate`"
                type="date"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-12 d-flex justify-content-center">
            <div class="buttons">
              <b-button class="danger-button" @click="closeAndConfirm">
                Valider
              </b-button>
            </div>
            <div class="buttons">
              <b-button
                class="transparent-button"
                @click="closeAndCancel"
              >
                Annuler
              </b-button>
            </div>
          </div>
        </div>
      </cm-confirm>

      <article class="col-sm-4">
        <b-form-group>
          <label>Type</label>
          <b-form-select
            @change="cleanUseless"
            :options="ppcTypeOptions"
            v-model="ppc_type" >
          </b-form-select>
        </b-form-group>
      </article>

      <article class="col-sm-4">
        <div class="radio-buttons">
          <label>Pression</label>
          <div class="d-flex">
            <custom-radio-button
              v-for="(option, index) of pressureTypeOptions"
              :key="index"
              :active="isActive(option.value, pressure_type)"
              :text="option.text"
              :option-value="option.value"
              v-model="pressure_type"
            ></custom-radio-button>
          </div>

          <template v-if="ppc_type === 'ppc'">
            <template v-if="pressure_type === 'fixed'">
              <b-form-group
                :state="p_fixedVal"
                :invalid-feedback="numberFeedback()"
              >
                <label for="input-live">
                  Valeur <sup class="required"> requis</sup>
                </label>
                <b-input-group>
                  <input
                    id="`ppc-p-fixed`"
                    class="input-placeholders-right"
                    type="text"
                    v-model="p_fixed"
                  >
                  <span class="placeholder">cmH2O</span>
                </b-input-group>
              </b-form-group>
            </template>

            <template v-if="pressure_type === 'auto'">
              <b-form-group
                :state="p_auto_minVal"
                :invalid-feedback="numberFeedback()"
              >
                <label for="ppc-auto-min-input">
                  PEP min <sup class="required"> requis</sup>
                </label>
                <b-input-group>
                  <input
                    id="`ppc-p-auto-min-input`"
                    class="input-placeholders-right"
                    type="text"
                    v-model="p_auto_min"
                  >
                  <span class="placeholder">cmH2O</span>
                </b-input-group>
              </b-form-group>

              <b-form-group
                :state="p_auto_maxVal"
                :invalid-feedback="numberFeedback()"
              >
                <label for="ppc-auto-max-input">
                  PEP max<sup class="required"> requis</sup>
                </label>
                <b-input-group>
                  <input
                    id="`ppc-p-auto-max-input`"
                    class="input-placeholders-right"
                    type="text"
                    v-model="p_auto_max"
                  >
                  <span class="placeholder">cmH2O</span>
                </b-input-group>
              </b-form-group>
            </template>
          </template>

          <template v-if="ppc_type === 'ppc_auto'">
            <template v-if="pressure_type === 'fixed'">
              <b-form-group
                :state="pep_ppcVal"
                :invalid-feedback="numberFeedback()"
              >
                <label for="input-live">
                  Valeur <sup class="required"> requis</sup>
                </label>
                <b-input-group>
                  <input
                    id="ppc-fix-input"
                    class="input-placeholders-right"
                    type="text"
                    v-model="pep_ppc"
                  >
                    <span class="placeholder">cmH2O</span>
                </b-input-group>
              </b-form-group>
            </template>

            <template v-if="pressure_type === 'auto'">
              <b-form-group
                :state="pep_auto_min_ppcVal"
                :invalid-feedback="numberFeedback()"
              >
                <label for="input-live">
                  PEP min <sup class="required"> requis</sup>
                </label>
                <b-input-group>
                  <input
                    id="ppc-auto-min-input"
                    class="input-placeholders-right"
                    type="text"
                    v-model="pep_auto_min_ppc"
                  >
                  <span class="placeholder">cmH2O</span>
                </b-input-group>
              </b-form-group>

              <b-form-group
                :state="pep_auto_max_ppcVal"
                :invalid-feedback="numberFeedback()"
              >
                <label for="input-live">
                  PEP max <sup class="required"> requis</sup>
                </label>
                <b-input-group>
                  <input
                    id="ppc-auto-max-input"
                    class="input-placeholders-right"
                    type="text"
                    v-model="pep_auto_max_ppc"
                  >
                  <span class="placeholder">cmH2O</span>
                </b-input-group>
              </b-form-group>
            </template>

            <b-form-group
              :state="ai_min_ppcVal"
              :invalid-feedback="numberFeedback()"
            >
              <label for="input-live">
                AI min
              </label>
              <b-form-input
                id="`ppc-ai-min`"
                type="text"
                v-model="ai_min_ppc"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :state="ai_max_ppcVal"
              :invalid-feedback="numberFeedback()"
            >
              <label for="input-live">
                AI max
              </label>
              <b-form-input
                id="`ppc-ai-max`"
                type="text"
                v-model="ai_max_ppc"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :state="frequency_ppcVal"
              :invalid-feedback="numberFeedback()"
            >
              <label for="input-live">
                Fréquence
              </label>
              <b-form-input
                id="`ppc-frequency`"
                type="text"
                v-model="frequency_ppc"
              ></b-form-input>
            </b-form-group>
          </template>
        </div>
      </article>

      <article class="col-sm-12 radio-buttons">
        <label>Humidificateur</label>
        <div class="col-sm-12 row">
          <custom-radio-button
            v-for="(option, index) of booleanOptions"
            :key="index"
            :active="isActive(option.value, humidifier_ppc)"
            :text="option.text"
            :option-value="option.value"
            v-model="humidifier_ppc"
          ></custom-radio-button>
        </div>
      </article>

      <article class="col-sm-8">
        <b-form-group>
          <label>Informations complémentaires</label>
          <b-form-textarea
            id="ppc-additional-info"
            v-model="additional_info_ppc"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </article>

      <article class="col-sm-8">
        <b-form-group
          :state="attachedExamsVal"
          :invalid-feedback="fileFeedback"
        >
          <b-form-file
              v-model="attachedExams"
              placeholder="Joindre une polygraphie/polysomnographie"
              drop-placeholder="Placer un fichier ici..."
              browse-text="Parcourir"
              id="ppc-attached-exam"
              ref="file"
              name="file0"
              accept=".pdf, .png, .jpg, .jpeg, .doc, .docx"
              class="is-truncated"
            ></b-form-file>
            <div class="mt-3 d-flex justify-content-between align-items-center" v-if="attachedExams">
              <span class="is-truncated">Fichier à envoyer : {{ attachedExams.name }}</span>
              <i @click="deleteAttachedFile" class="delete-button far fa-trash-alt 2x icon"></i>
            </div>
          </b-form-group>
      </article>
    </b-collapse>
  </div>
</template>

<script>
import CustomRadioButton from '@/components/askForCare/CustomRadioButton'
import CmConfirm from '@/components/UI/Modal/CmConfirm'
import AskForCareMixin from '@/mixins/AskForCareMixin'
import { isAcceptedFiletype } from '../../utils/validate'

export default {
  components: {
    CustomRadioButton,
    CmConfirm
  },
  mixins: [AskForCareMixin],
  destroyed() {
    this.$store.dispatch('resetAllPpc')
  },
  filters: {
    pluralize(value, count, pluralChar = 's', removeChar = 0 ) {
      let plural = count > 1 ? `${value.slice(0, value.length - removeChar)}${pluralChar}` : null
      return plural || value
    }
  },
  data() {
    return {
      pressureTypeOptions: [
        { text: this.$t('askForCare.ppc.fixed'), value: 'fixed' },
        { text: this.$t('askForCare.ppc.auto'),  value: 'auto' }
      ],
      ppcTypeOptions: [
        { text: this.$t('askForCare.ppc.ppc'),      value: 'ppc' },
        { text: this.$t('askForCare.ppc.ppc_auto'), value: 'ppc_auto' }
      ],
      booleanOptions: [
        { text: this.$t('common.true'),  value: true },
        { text: this.$t('common.false'), value: false }
      ],
      iahSymptomsOptions: [],
      symptomsModalOpen: false,
      symptomsCount : 1,
      expanded: this.$store.state.mainCareForm.expandedPpc,
      tooltipAtLeastShow: true,
      birthDateValid: true
    }
  },
  computed: {
    additional_info_ppc: {
      get() {
        return this.$store.state.ppcCareForm.additional_info_ppc
        },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_additional_info_ppc', value)
      }
    },
    ai_max_ppc: {
      get() {
        return this.$store.state.ppcCareForm.ai_max_ppc
        },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_ai_max_ppc', this.textToFloat(value))
      }
    },
    ai_min_ppc: {
      get() {
        return this.$store.state.ppcCareForm.ai_min_ppc
        },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_ai_min_ppc', this.textToFloat(value))
      }
    },
    ald_on_list_ppc: {
      get(){
        return this.$store.state.ppcCareForm.ald_on_list_ppc
      },
      set(value){
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_ald_on_list_ppc', value)
      }
    },
    attachedExams: {
      get() {
        // @todo - remove [0] when implementing multi files
        return this.$store.state.ppcCareForm.attachedExams[0]
      },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_attachedExams', [value])
      }
    },
    birthDate: {
      get(){
        return this.$store.state.mainCareForm.date_of_birth
      },
      set(value){
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update', { key: 'date_of_birth', value })
      }
    },
    eordoFields: {
      get() {
        return this.$store.state.mainCareForm.settings.eordo_mandatory_fields
      }
    },
    frequency_ppc: {
      get() {
        return this.$store.state.ppcCareForm.frequency_ppc
        },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_frequency_ppc', this.textToFloat(value))
      }
    },
    humidifier_ppc: {
      get() {
        return this.$store.state.ppcCareForm.humidifier_ppc
        },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_humidifier_ppc', value)
      }
    },
    iah: {
      get() {
        return this.$store.state.ppcCareForm.iah
        },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_iah', this.textToFloat(value))
      }
    },
    iahSymptoms: {
      get() {
        return this.$store.state.ppcCareForm.iah_symptoms
        },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_iah_symptoms', value)
      }
    },
    iahOtherSymptoms: {
      get() {
        return this.$store.state.ppcCareForm.iah_other_symptoms
        },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_iah_other_symptoms', value)
      }
    },
    ia: {
      get() {
        return this.$store.state.ppcCareForm.ia
        },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_ia', this.textToFloat(value))
      }
    },
    age() {
      return this.$store.getters.age
    },
    p_auto_max: {
      get() {
        return this.$store.state.ppcCareForm.p_auto_max
        },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_p_auto_max', this.textToFloat(value))
      }
    },
    p_auto_min: {
      get() {
        return this.$store.state.ppcCareForm.p_auto_min
        },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_p_auto_min', this.textToFloat(value))
      }
    },
    p_fixed: {
      get() {
        return this.$store.state.ppcCareForm.p_fixed
        },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_p_fixed', this.textToFloat(value))
      }
    },
    pep_auto_max_ppc: {
      get() {
        return this.$store.state.ppcCareForm.pep_auto_max_ppc
        },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_pep_auto_max_ppc', this.textToFloat(value))
      }
    },
    pep_auto_min_ppc: {
      get() {
        return this.$store.state.ppcCareForm.pep_auto_min_ppc
        },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_pep_auto_min_ppc', this.textToFloat(value))
      }
    },
    pep_ppc: {
      get() {
        return this.$store.state.ppcCareForm.pep_ppc
        },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_pep_ppc', this.textToFloat(value))
      }
    },
    ppc_type: {
      get() {
        return this.$store.state.ppcCareForm.ppc_type
        },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_ppc_type', value)
      }
    },
    pressure_type: {
      get() {
        return this.$store.state.ppcCareForm.pressure_type
      },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_pressure_type', value)
      }
    },
    progress() {
        return this.$store.state.mainCareForm.progress
    },
    with_ald_ppc: {
      get() {
        return this.$store.state.ppcCareForm.with_ald_ppc
        },
      set(value) {
        this.$store.commit('update_ppc_dirty', true)
        this.$store.commit('update_ppc_with_ald_ppc', value)
      }
    },

    // Validation
    attachedExamsVal(){
      let upload = this.$store.state.ppcCareForm.attachedExams
      return isAcceptedFiletype(upload) || upload.length == 0 || upload[0] == null
    },
    iahVal(){
      if (!this.$store.state.mainCareForm.sentPathologies.includes('ppc')) {
        return this.iah === '' || (this.onlyThreeDigitsOneDecimal(this.iah) && this.iah >= 0.1 && this.ia === '')
      } else if (this.ia === '') {
        if (this.eordoFields) {
          return this.onlyThreeDigitsOneDecimal(this.iah) && this.iah >= 0.1
        } else {
          return this.iah === '' || (this.onlyThreeDigitsOneDecimal(this.iah) && this.iah >= 0.1 && this.ia === '')
        }
      } else {
        return this.iah === ''
      }
    },
    iaVal(){
      if (!this.$store.state.mainCareForm.sentPathologies.includes('ppc')) {
        return this.ia === '' || (this.onlyThreeDigitsOneDecimal(this.ia) && this.ia >= 0.1 && this.iah === '')
      } else if (this.iah === '') {
        if (this.eordoFields) {
          return this.onlyThreeDigitsOneDecimal(this.ia) && this.ia >= 0.1
        } else {
          return this.ia === '' || (this.onlyThreeDigitsOneDecimal(this.ia) && this.ia >= 0.1 && this.iah === '')
        }
      } else {
        return this.ia === ''
      }
    },
    ai_max_ppcVal(){
      return this.emptyOrValid(this.ai_max_ppc)
    },
    ai_min_ppcVal(){
      return this.emptyOrValid(this.ai_min_ppc)
    },
    frequency_ppcVal(){
      return this.emptyOrValid(this.frequency_ppc)
    },
    p_auto_maxVal(){
      return ( this.p_auto_max !== '' && this.onlyTwoDigitsOneDecimal(this.p_auto_max) ) || (!this.$store.state.mainCareForm.sentPathologies.includes('ppc') && this.p_auto_max === '')
    },
    p_auto_minVal(){
      return ( this.p_auto_min !== '' && this.onlyTwoDigitsOneDecimal(this.p_auto_min) ) || (!this.$store.state.mainCareForm.sentPathologies.includes('ppc') && this.p_auto_min === '')
    },
    p_fixedVal(){
      return ( this.p_fixed !== '' && this.onlyTwoDigitsOneDecimal(this.p_fixed) ) || (!this.$store.state.mainCareForm.sentPathologies.includes('ppc') && this.p_fixed === '')
    },
    pep_auto_max_ppcVal(){
      return ( this.pep_auto_max_ppc !== '' && this.onlyTwoDigitsOneDecimal(this.pep_auto_max_ppc) ) || (!this.$store.state.mainCareForm.sentPathologies.includes('ppc') && this.pep_auto_max_ppc === '')
    },
    pep_auto_min_ppcVal(){
      return ( this.pep_auto_min_ppc !== '' && this.onlyTwoDigitsOneDecimal(this.pep_auto_min_ppc) ) || (!this.$store.state.mainCareForm.sentPathologies.includes('ppc') && this.pep_auto_min_ppc === '')
    },
    pep_ppcVal(){
      return ( this.pep_ppc !== '' && this.onlyTwoDigitsOneDecimal(this.pep_ppc) ) || (!this.$store.state.mainCareForm.sentPathologies.includes('ppc') && this.pep_ppc === '')
    },
    iahSymptomsVal() {
      return this.iahSymptoms.length >= this.symptomsCount || (this.iahSymptoms.length !== 0 && this.iahSymptoms.includes('other'))
    },
    iahOtherSymptomsVal() {
      return (this.iahOtherSymptoms !== '' && this.iahSymptoms.includes('other')) || (this.iahOtherSymptoms === '' && !this.iahSymptoms.includes('other'))
    },

    // Invalid Feedback
    birthDateFeedback() {
      return 'Merci de rentrer une date passée'
    },

    fileFeedback() {
      return "Ce type de document n'est pas supporté"
    },

    iahFeedback(){
      let message =  []

      this.iah !== '' && this.ia !== '' ? message.push('IAH et IA ne peuvent être remplis simultanément') : message
      this.iah !== '' && this.iah === 0 ? message.push('Entrez un chiffre supérieur ou égal à 0.1') : message
      message = message.length === 0 ? ['Entrez une valeur valide'] : message

      return message.join('. ')
    },
    iaFeedback(){
      let message = []

      this.iah !== '' && this.ia !== '' ? message.push('IAH et IA ne peuvent être remplis simultanément') : message
      this.ia !== '' && this.ia === 0     ? message.push('Entrez un chiffre supérieur ou égal à 0.1')      : message
      this.ia !== '' && this.age > 15   ? message.push("L'IA n'est pas disponible pour un adulte")       : message
      message.length === 0 ? message.push('Entrez une valeur valide') : message

      return message.join('. ')
    },
    iahSymptomsFeedback(){
      return `Selectionnez ${this.symptomsCount} valeur(s)`
    },
    iahOtherSymptomsFeedback(){
      if (this.iahSymptoms.includes('other') && ( this.iahOtherSymptoms === '' || this.iahOtherSymptoms === null ))
        { return "Entrez des informations de symptôme 'Autre'" }
      else if (( this.iahOtherSymptoms !== '' || this.iahOtherSymptoms !== null ) && !this.iahSymptoms.includes('other') )
      { return  "Cochez autre symptôme ou laissez ce champ vide"}
    },
  },
  watch: {
    pressure_type(val) {
        this.otherPChoice(val)
    },
    ia() {
      this.tooltipAtLeastShow = this.age <= 16 ? this.showAtLeastTooltip() : false
      return this.iahSymptomsOptions = this.iahSymptomsList()
    },
    iah() {
      this.tooltipAtLeastShow = this.age <= 16 ? this.showAtLeastTooltip() : false
      return this.iahSymptomsOptions = this.iahSymptomsList()
    },
    birthDate(){
      this.birthDateValid = new Date(this.birthDate).setHours(0, 0, 0) <= new Date().setHours(0, 0, 0)
      return this.iahSymptomsOptions = this.iahSymptomsList()
    }
  },
  methods: {
    closeAndCancel() {
      this.iah = ''
      this.symptomsModalOpen = false
    },
    closeAndConfirm() {
      this.birthDateValid = new Date(this.birthDate).setHours(0, 0, 0) <= new Date().setHours(0, 0, 0)
      if (this.birthDate !== '' && this.birthDateValid) {
        this.iahSymptomsOptions = this.iahSymptomsList()
        this.symptomsModalOpen = false
      }
    },
    openBirthDateModal() {
      if (this.iah !== '' && this.birthDate === '' || !this.birthDateValid) {
        this.symptomsModalOpen = true
      }
    },
    showAtLeastTooltip() {
      return this.iah === '' && this.ia === '' && this.progress > 0 && this.eordoFields
    },
    isChild() {
      return this.age !== null && this.age < 16
    },
    deleteAttachedFile() {
      this.$store.commit('update_ppc_attachedExams', [])
    },
    isActive(value, stateValue) {
      return (value === stateValue)
    },
    iahSymptomsList() {
      if (this.age >= 16 && this.iah  >= 30) {
        this.symptomsCount = 3
        return [
          { text: this.$t('askForCare.ppc.daytime_drowsiness'),   value: 'daytime_drowsiness' },
          { text: this.$t('askForCare.ppc.severe_snoring'),       value: 'severe_snoring' },
          { text: this.$t('askForCare.ppc.sleep_apnea'), value: 'sleep_apnea' },
          { text: this.$t('askForCare.ppc.daytime_fatigue'),      value: 'daytime_fatigue' },
          { text: this.$t('askForCare.ppc.nycturia'),             value: 'nycturia' },
          { text: this.$t('askForCare.ppc.morning_headaches'),    value: 'morning_headaches' }
        ]
      }
      else if (this.age >= 16 && this.iah  < 30) {
        this.symptomsCount = 1

        return [
          { text: this.$t('askForCare.ppc.daytime_drowsiness_hazards'), value: 'daytime_drowsiness_hazards' },
          { text: this.$t('askForCare.ppc.comorbidity'),               value: 'comorbidity' }
        ]
      }
      else if (this.age < 16) {
        this.symptomsCount = 1

        return [
          { text: this.$t('askForCare.ppc.snoring'),                      value: 'snoring' },
          { text: this.$t('askForCare.ppc.labored_breathing'),             value: 'labored_breathing' },
          { text: this.$t('askForCare.ppc.periodic_limb_movements'),           value: 'periodic_limb_movements' },
          { text: this.$t('askForCare.ppc.hyperhidrosis'),            value: 'hyperhidrosis' },
          { text: this.$t('askForCare.ppc.neck_hyperextension'),          value: 'neck_hyperextension' },
          { text: this.$t('askForCare.ppc.hyperactivity'), value: 'hyperactivity' },
          { text: this.$t('askForCare.ppc.attention_deficit'),        value: 'attention_deficit' },
          { text: this.$t('askForCare.ppc.secondary_enuresis'),           value: 'secondary_enuresis' },
          { text: this.$t('askForCare.ppc.morning_headaches'),            value: 'morning_headaches' },
          { text: this.$t('askForCare.ppc.weight_stagnation'),            value: 'weight_stagnation' }
        ]
      }
    },
    toggleHead(){
      this.$store.commit('update_main_expandedPpc', !this.expanded)
      this.expanded = !this.expanded

      if(this.expanded){
        $('#toggle-ppc-icon').removeClass('fa-angle-double-down')
        $('#toggle-ppc-icon').addClass('fa-angle-double-up')
      }
      else{
        $('#toggle-ppc-icon').removeClass('fa-angle-double-up')
        $('#toggle-ppc-icon').addClass('fa-angle-double-down')
      }
    },

    otherPChoice(e) {
      if(this.ppc_type === 'ppc'){
        this.cleanPPCAuto()

        if(e === 'auto' && !this.isPpcFixedClean()){
          this.p_fixed = ''
          this.$toasted.info("Valeurs définies dans l'option PPC fixe effacées", { duration: 5000 })
        }
        else if(e === 'fixed' && !this.isPpcAutoClean()){
          this.p_auto_min = ''
          this.p_auto_max = ''
          this.$toasted.info("Valeurs définies dans l'option PPC automatique effacées", { duration: 5000 })
        }
      }
      else{
        if(e === 'auto' && !this.isPpcAutoFixedClean){
          this.pep_ppc = ''
          this.$toasted.info("Valeurs définies dans l'option PPC fixe effacées", { duration: 5000 })
        }
        else if(e === 'fixed' && !this.isPpcAutoAutoClean()){
          this.pep_auto_min_ppc = ''
          this.pep_auto_max_ppc = ''
          this.$toasted.info("Valeurs définies dans l'option PPC automatique effacées", { duration: 5000 })
        }
      }
    },
    isPpcFixedClean() {
      return this.p_fixed === ''
    },
    isPpcAutoClean() {
      return this.p_auto_min === '' && this.p_auto_max === ''
    },
    isPpcAutoFixedClean() {
      return this.pep_ppc === ''
    },
    isPpcAutoAutoClean() {
      return this.pep_auto_min_ppc === '' && this.pep_auto_max_ppc === ''
    },
    cleanPPCAuto(){
      this.pep_ppc             = ''
      this.pep_auto_min_ppc    = ''
      this.pep_auto_max_ppc    = ''
      this.ai_min_ppc          = ''
      this.ai_max_ppc          = ''
      this.frequency_ppc       = ''
    },
    cleanPPCNoAuto(){
      this.p_fixed             = ''
      this.p_auto_min          = ''
      this.p_auto_max          = ''
    },
    cleanUseless(e){
      if(this.ppc_type === 'ppc_auto') {
        this.cleanPPCNoAuto()
      } else {
        this.cleanPPCAuto()
      }

      this.$store.commit('update_ppc_ppc_type', this.ppc_type)
    }
  }
}
</script>
