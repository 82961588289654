<template>
  <div class="prescription-sent main d-flex container-fluid">
    <section class="col-sm-10 container">
      <div class="bloc row col-sm-10 row center success-header" id="">
        <div class="col-sm-12 title text-center">
          <b><p>Merci, votre demande a bien été transmise à votre agence</p></b>
        </div>
        <div class="col-sm-12 sub-title text-center">
          <p>Votre prescription a été envoyée le {{ datetime }}</p>
        </div>
        <div class="col-sm-12 d-flex justify-content-center">
          <b-button :href='backLink' class="submit-button">
            {{backText}}
          </b-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    backLink: {
      type: String,
      default: "/",
    },
    backText: {
      type: String,
      default: "Retour à l'accueil Eliview",
    },
  },
  data() {
    return {
      datetime: new Date()
        .toLocaleString("fr-FR")
        .replace(/\:/, "h")
        .split(":")[0]
        .replace(/\//g, "."),
    };
  },
};
</script>

<style lang="scss">
.prescription-sent {
  &.main {
    background: rgba(33, 33, 33, 0.85);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1031; // The navbar is 1030
  }

  & > section {
    margin: auto;
  }

  .center {
    height: 15%;
    margin: auto;
  }

  .success-nav {
    color: white;
  }
}
</style>
