<template>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          {{ $t("resmedPdf.generate") }}
        </h3>
      </div>

      <div :class="{ error: !validPeriod }"> {{ $t("resmedPdf.caution") }}</div>

      <form @submit.prevent="submit">
        <div class="modal-body form-group">
          <div>
            <b-form-group>
              <label>Type de rapport</label>
              <b-form-select 
              :options="report_types"
              v-model="formData.report_type"></b-form-select>
            </b-form-group>
            
            <b-form-group label="Individual radios" v-slot="{ ariaDescribedby }">
              <b-form-radio-group 
                v-model="formData.period" 
                :aria-describedby="ariaDescribedby" 
                :options="periods"
                name="some-radios"></b-form-radio-group>
             

            </b-form-group>

            <b-form-group>
              <label>{{ $t("resmedPdf.startDate") }}</label>
              <b-form-input
                type="date"
                name="start_date"
                id="ps-start-date"
                v-model="formData.start_date"
              ></b-form-input>
            </b-form-group>
          </div>
          <div>
            <b-form-group>
              <label for="ps-end-date">{{ $t("resmedPdf.endDate") }}</label>
              <b-form-input
                type="date"
                name="end_date"
                id="ps-end-date"
                v-model="formData.end_date"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-6 col-sm-offset-2">
            <b-button type="submit" class="btn btn-primary" :disabled="!this.validPeriod">
              {{ $t("resmedPdf.generate") }}
            </b-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    patientCode: {
      type:     String,
      required: true,
    },
  },

  data() {
    return {
      formData: {
        patient_id:    this.patientCode,
        report_type:   "compliance-therapy",
        start_date:    null,
        end_date:      new Date().toISOString().slice(0, 10),
        period:        12
      },
      report_types : [
        {value: "compliance",         text: this.$t('resmedPdf.reportTypes.compliance')},
        {value: "therapy",            text: this.$t('resmedPdf.reportTypes.therapy')},
        {value: "compliance-therapy", text: this.$t('resmedPdf.reportTypes.complianceTherapy')},
        {value: "detailed",           text: this.$t('resmedPdf.reportTypes.detailed')}
      ],
      periods: [
        {value: 1,  text: "1 mois"},
        {value: 3, text: "3 mois"},
        {value: 6, text: "6 mois"}

      ]
    };
  },

  computed: {
    validPeriod() {
      if (this.formData.start_date && this.formData.end_date) {
        const difference = this.dateDifference(new Date(this.formData.start_date), new Date(this.formData.end_date));

        return difference >= 1 && difference <= 30;
      } else {
        return true;
      }
    },
  },

  methods: {
    dateDifference(firstDate, secondDate) {
      return Math.round((secondDate - firstDate)/(1000 * 60 * 60 * 24));
    },

    fetchStartDate(endDate) {
      ed = new Date(endDate)
      ed.setMont(ed.getMonth()-3)
      return ed
    },

    toHTMLDate(date) {
      const day = date.getDate().toString().padStart(2, "0");
      // months are 0-indexed
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    },

    async submit() {
      try {
        const response = await this.$axios({
          url: "/api/v1/files/resmed",
          method: "get",
          responseType: "blob",
          params: this.formData,
        });

        const data = response.data;

        let blob = new Blob([data], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }

        const contentDisposition = response.headers["content-disposition"];
        const match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
        const filename = match[1];

        const url = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(url);
        }, 100);
      } catch (e) {
        if (e.isAxiosError) {
          const error = JSON.parse(await e.response.data.text());
          this.$toasted.error(error.message, { duration: 3000 });
        } else {
          this.$toasted.error("Une erreur est survenue.", { duration: 3000 });
        }
      }
    },
  },
};
</script>

<style scoped>
  button.disabled {
    cursor: not-allowed;
  }

  .error {
    color: red;
  }
</style>
