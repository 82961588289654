<template>
  <label
    :class="['radio', { active: active }]"
    :value="optionValue"
    @input="$emit('input', optionValue)"
  >
    <span class="radio__input">
      <input type="radio" name="custom-radio-button-input" />
      <span class="radio__control"></span>
    </span>
    <span class="radio__label">{{ text }}</span>
  </label>
</template>
<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    optionValue: {
      default: "",
    },
  }
};
</script>

<style lang="scss">
.radio {
  color: #9d9d9d;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #cdcdcd;
  background-color: white;
  padding: 11px 20px 11px 15px;
  border-radius: 4px;
  height: 40px;
  margin: 0 15px 9px 0;

  &.active {
    border-color: #ebedf9;
    background-color: #ebedf9;
    color: #4a61e3;

    .radio__control {
      background-color: white;
      border: solid 5px #4a61e3;
    }
  }

  &.left {
    justify-content: left;
  }
  &.right {
    justify-content: right;
  }
}

.radio__input {
  input {
    display: none;

    &:checked+.radio__control {
      background-color: white;
      border: solid 5px #4a61e3;
    }
  }
}

.radio__control {
  display: block;
  width: 1em;
  height: 1em;
  margin-right: 5px;
  border-radius: 50%;
  border: 0.1em solid currentColor;
}

.radio__label {
  position: relative;
  line-height: 1;
  left: 5px;
}
</style>
